import React from 'react';

import { ErrorWrapper } from '@ams-package/utils';

import { DatePicker, ReactDatePickerProps } from './DatePicker';

export type DatePickerFormFieldProps = ReactDatePickerProps & {
  name: string;
  value?: string;
  invalid?: boolean;
  error?: string;
  onChange: (value: string) => void;
};

export const DatePickerFormField: React.FC<DatePickerFormFieldProps> = ({
  error,
  ...rest
}) => (
  <ErrorWrapper content={error}>
    <DatePicker {...rest} />
  </ErrorWrapper>
);
