import React, { ReactElement, useMemo } from 'react';
import { SelectComponentsConfig, GroupBase } from 'react-select';

import { IOptionItem } from '@xcritical/select';

import { SelectFormFieldType } from '../select';

import { RadioOption } from './components/RadioOption';
import { FilterSelect } from './FilterSelect';

type FilterSelectProps = SelectFormFieldType;

export const SingleFilterSelect = ({
  ...rest
}: FilterSelectProps): ReactElement => {
  const components = useMemo(
    () =>
      ({
        Option: RadioOption,
      } as SelectComponentsConfig<IOptionItem, false, GroupBase<IOptionItem>>),
    []
  );

  return <FilterSelect components={components} {...(rest as any)} />;
};
