import React, { memo, useMemo } from 'react';
import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';

import Drawer, { IDrawerProps } from '@xcritical/drawer';

import { Icon } from '@ams-package/icons';

import { Wrapper, TitleWrapper } from './styled';

export const SideModal: React.FC<IDrawerProps> = memo(
  ({ children, maxWidth = 500, title: TitleProps, ...rest }) => {
    const Title = useMemo(() => {
      if (!TitleProps) return undefined;

      return isString(TitleProps) || isNumber(TitleProps) ? (
        <TitleWrapper>{TitleProps}</TitleWrapper>
      ) : (
        TitleProps
      );
    }, [TitleProps]);

    return (
      <Drawer
        isRTL
        maxWidth={maxWidth}
        closeIconComponent={<Icon name="close" />}
        withCloseButton
        title={Title}
        {...rest}>
        <Wrapper>{children}</Wrapper>
      </Drawer>
    );
  }
);
