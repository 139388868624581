export const LSKeys = {
  token: 'User-Token',
  tokenHealth: 'Token-Expires',
};

export const LSHelper = {
  getPrimitive<T extends string = string>(key: string) {
    return localStorage.getItem(key) as T | null;
  },
  getObject<T extends object>(key: string): T | null {
    try {
      return JSON.parse(localStorage.getItem(key) || '');
    } catch (e) {
      console.warn(`Could not get ${key} from LS: ${e}`);
    }

    return null;
  },
  setItem<T = any>(key: string, data: T) {
    localStorage.setItem(key, JSON.stringify(data));
  },
  removeItem(key: string) {
    localStorage.removeItem(key);
  },
};

export const getToken = (): string => {
  const token = LSHelper.getPrimitive(LSKeys.token);

  return token?.replaceAll('"', '') ?? '';
};
