import React from 'react';

import { FilterButton, FilterButtonProps } from './FilterButton';
import { DateFilterButtonWrapper } from './styled';

export const DateFilterButton: React.FC<FilterButtonProps> = ({ ...rest }) => (
  <DateFilterButtonWrapper>
    <FilterButton {...rest} />
  </DateFilterButtonWrapper>
);
