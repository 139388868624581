import React, { ReactElement } from 'react';
import { SelectComponentsConfig, GroupBase } from 'react-select';

import { IOptionItem } from '@xcritical/select';

import { DictionarySelect, IDictionarySelect } from '@ams-package/select';
import { IPaymentMethodsModel } from '@ams-package/dictionaries';
import { defaultImages } from '@ams-package/icons';

import { MenuList } from './MenuList';
import { Option as CustomOption } from './Option';
import { SingleValue } from './SingleValue';
import { PaymentIcon } from './styled';

const components = {
  MenuList,
  Option: CustomOption,
  SingleValue,
} as SelectComponentsConfig<IOptionItem, false, GroupBase<IOptionItem>>;

export const SelectPaymentMethod = ({
  ...rest
}: Omit<IDictionarySelect, 'dictionaryName'> & {
  formName?: string;
  statusMessageKey?: string;
}): ReactElement => (
  <DictionarySelect
    appearance="payment-methods"
    dictionaryName="paymentMethods"
    withSelectedIcon={false}
    components={components}
    optionsMapper={(payment: IPaymentMethodsModel) => ({
      prefix: <PaymentIcon src={defaultImages[payment.code]} />,
      label: payment.title,
      value: payment.id,
      postfix: payment.number,
    })}
    {...(rest as any)}
  />
);
