import { checkboxThemeNamespace } from '@xcritical/checkbox';
import { buttonThemeNamespace } from '@xcritical/button';
import { inputThemeNamespace } from '@xcritical/input';
import { popoverThemeNamespace } from '@xcritical/popover';
import { badgeThemeNamespace } from '@xcritical/badge';
import { gridThemeNamespace } from '@xcritical/grid';
import { modalThemeNamespace } from '@xcritical/modal';
import { drawerThemeNamespace } from '@xcritical/drawer';
import { selectThemeNamespace } from '@xcritical/select';
import { notificationThemeNamespace } from '@xcritical/notification';

import { paginationThemeNamespace } from '@ams-package/pagination';

import { checkboxTheme } from './checkbox';
import { inputTheme } from './input';
import { popoverTheme } from './popover';
import { badgeTheme } from './badge';
import { buttonTheme } from './button';
import { gridTheme } from './grid';
import { paginationTheme } from './pagination';
import { modalTheme } from './modal';
import { drawerTheme } from './drawer';
import { selectTheme } from './select';
import { notificationTheme } from './notification';

export const theme = {
  [checkboxThemeNamespace]: checkboxTheme,
  [gridThemeNamespace]: gridTheme,
  [paginationThemeNamespace]: paginationTheme,
  [inputThemeNamespace]: inputTheme,
  [popoverThemeNamespace]: popoverTheme,
  [badgeThemeNamespace]: badgeTheme,
  [buttonThemeNamespace]: buttonTheme,
  [modalThemeNamespace]: modalTheme,
  [drawerThemeNamespace]: drawerTheme,
  [selectThemeNamespace]: selectTheme,
  [notificationThemeNamespace]: notificationTheme,
};
