import React from 'react';

import { BaseModal, BaseModalType } from '../base-modal';
import { useModal } from '../hooks';
import { Buttons, ButtonsType } from '../buttons';

import {
  ButtonsWrapper,
  DescriptionWrapper,
  MessageWrapper,
  TittleWrapper,
} from './styled';

export type StatusModalType = Omit<BaseModalType, 'title' | 'children'>;

export const ConfirmModal: React.FC<StatusModalType> = ({
  name,
  ...params
}) => {
  const {
    payload: { message, buttons },
  } = useModal(name);

  return (
    <BaseModal name={name} title="" appearance="statusModal" {...params}>
      <MessageWrapper>
        {message?.title && <TittleWrapper>{message.title}</TittleWrapper>}
        {message?.description && (
          <DescriptionWrapper>{message.description}</DescriptionWrapper>
        )}
      </MessageWrapper>
      {buttons && (
        <ButtonsWrapper>
          {buttons?.map((button) => (
            <Buttons {...(button as ButtonsType)} />
          ))}
        </ButtonsWrapper>
      )}
    </BaseModal>
  );
};
