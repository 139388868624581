import React from 'react';
import { useDispatch } from 'react-redux';

import Button from '@xcritical/button';

import { paymentEditorActions } from '../../store';

import {
  ButtonsWrapper,
  DeleteWarning,
  ConfirmPaymentMethodWrapper,
} from './styled';

export const DeletePaymentMethod: React.FC = () => {
  const dispatch = useDispatch();
  const onDelete = () => {
    dispatch(paymentEditorActions.confirmRemoving());
  };
  const onCancel = () => {
    dispatch(paymentEditorActions.close());
  };

  return (
    <ConfirmPaymentMethodWrapper>
      <div>
        <h4>Are you sure you want to delete the payment method?</h4>
        <DeleteWarning>
          If you decide to continue, all entered data will be deleted
        </DeleteWarning>
      </div>

      <ButtonsWrapper>
        <Button shouldFitContent onClick={onDelete}>
          Delete
        </Button>
        <Button shouldFitContent appearance="lightSecondary" onClick={onCancel}>
          Cancel
        </Button>
      </ButtonsWrapper>
    </ConfirmPaymentMethodWrapper>
  );
};
