import styled from 'styled-components';

import { classNameWithPrefix } from '@crm-framework/utils';

const blockName = 'filter-select';

export const FilterWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'wrapper'),
})`
  display: grid;
  grid-template-columns: minmax(156px, 190px) auto;
`;

export const RadioIconWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'radio-icon-wrapper'),
})`
  margin: -2px;
  max-height: 20px;
`;
