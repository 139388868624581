import styled from 'styled-components';

import { classNameWithPrefix } from '@crm-framework/utils';

import { generateFontStyle } from '@ams-package/theme';

const blockName = 'button';

export const ContentWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'content-wrapper'),
})<{ isUnderlined?: boolean }>`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  ${generateFontStyle('body-1m')}
  text-decoration: ${({ isUnderlined }) =>
    isUnderlined ? 'underline' : 'none'};
`;
