import { getMonth, getYear } from 'date-fns';

import { ISelectOption } from '../interfaces';

export const createYearSelectOption = (year: number): ISelectOption => ({
  id: year,
  value: year,
  label: year.toString(),
});

export const getYearsList = (
  initialYear: number,
  maxDate: Date = new Date()
): ISelectOption[] => {
  const years: ISelectOption[] = [];

  for (let year = maxDate.getFullYear(); year >= initialYear; year--) {
    const createdYear = createYearSelectOption(year);
    years.push(createdYear);
  }

  return years;
};

export const findYearSelectOptionByDate =
  (values: ISelectOption[]) =>
  (date: Date = new Date()): ISelectOption => {
    const year = getYear(date);
    const existingYear = values.find((option) => option.value === year);

    return existingYear ?? createYearSelectOption(year);
  };

export const findMonthSelectOptionByDate =
  (values: ISelectOption[]) =>
  (date: Date = new Date()): ISelectOption | null =>
    values.find((option) => option.value === getMonth(date)) ?? null;
