import React, { Dispatch } from 'react';
import { useDispatch } from 'react-redux';

import { Button } from '@ams-package/button';

import { ButtonsTypes } from './consts';

export type ButtonsType = {
  type: ButtonsTypes;
  title?: string;
  callback: (dispatch: Dispatch<any>) => void;
};

export const Buttons: React.FC<ButtonsType> = ({ type, title, callback }) => {
  const dispatch = useDispatch();

  const createHandler = (buttonCallback) => () => {
    buttonCallback(dispatch);
  };

  switch (type) {
    case ButtonsTypes.primary:
      return (
        <Button shouldFitContent onClick={createHandler(callback)}>
          {title}
        </Button>
      );
    case ButtonsTypes.secondary:
      return (
        <Button
          shouldFitContent
          appearance="lightSecondary"
          onClick={createHandler(callback)}>
          {title}
        </Button>
      );
    default:
      return null;
  }
};
