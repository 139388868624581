import React, { MouseEventHandler } from 'react';

import { ButtonWithValue } from '@ams-package/button';

import { FilterButtonWrapper } from './styled';

export type FilterButtonProps = {
  value: string;
  isOpen: boolean;
  placeholder: string;
  prefix?: string;
  isClearable?: boolean;
  selected?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onClear: MouseEventHandler<SVGSVGElement>;
};

export const FilterButton: React.FC<FilterButtonProps> = ({
  onClear,
  selected,
  ...rest
}) => (
  <FilterButtonWrapper selected={!!selected}>
    <ButtonWithValue onClear={onClear} {...rest} />
  </FilterButtonWrapper>
);
