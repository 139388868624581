import { put, takeLatest } from 'redux-saga/effects';
import { call, delay, select } from 'typed-redux-saga';
import { PayloadAction } from '@reduxjs/toolkit';

import { GridSort } from '@xcritical/grid';

import { GRT } from '@crm-framework/types';

import { filtersActions, FilterType } from '@ams-package/filters';
import { filterValuesSelector } from '@ams-package/filters/selectors';
import {
  changeQueryObjects,
  getQueryObject,
  handleServerError,
} from '@ams-package/utils';
import { loadAndSaveDictionariesByName } from '@ams-package/dictionaries';

import { dashboardActions } from './store';
import {
  DashboardSortingType,
  IDashboardApi,
  IInitDashboardModel,
  IInitDashboardPayload,
} from './types';
import {
  mapDictionaryNamesFromFilters,
  parseDashboardGridControllersFromString,
  parseFilterValuesFromQuery,
  parseFilterValueToStringByType,
} from './utils';
import {
  dashboardBaseSelector,
  dashboardGridControllersSelector,
} from './selectors';

export function getWatchDashboard(api: IDashboardApi) {
  return function* () {
    yield takeLatest(dashboardActions.init.type, handleInit);
    yield takeLatest(dashboardActions.getItems.type, handleGetItems);
    yield takeLatest(dashboardActions.setPage.type, handleChangeUrl);
    yield takeLatest(dashboardActions.setPageSize.type, handleChangeUrl);
    yield takeLatest(filtersActions.changeFilter.type, handleChangeUrl);
    yield takeLatest(filtersActions.clearFilters.type, handleChangeUrl);
  };

  function* handleInit(
    action: PayloadAction<IInitDashboardPayload>
  ): Generator {
    try {
      yield put(dashboardActions.setIsReady(false));
      const { filters, withoutUrlChanging } = action.payload;

      if (filters?.length) {
        const dictionaryNames = mapDictionaryNamesFromFilters(filters);

        if (dictionaryNames.length) {
          yield* loadAndSaveDictionariesByName(dictionaryNames);
        }

        if (!withoutUrlChanging) {
          const {
            filters: filterValues,
            page,
            sorting,
            pageSize,
          } = (yield* call(
            api.apiInit || getUrlConfig,
            filters
          )) as IInitDashboardModel;
          yield put(filtersActions.setFilterValues(filterValues));
          yield put(dashboardActions.setPage(page));
          yield put(dashboardActions.changeSorting(sorting));
          yield put(dashboardActions.setPageSize(pageSize));
        }
      }

      yield put(dashboardActions.getItems());
      yield put(dashboardActions.setIsReady(true));
    } catch (error) {
      handleServerError({ error });
    }
  }

  function getUrlConfig(
    filters: FilterType[] | undefined
  ): IInitDashboardModel {
    const query = getQueryObject();
    const filterValues = filters && parseFilterValuesFromQuery(filters, query);
    const { page, pageSize, sorting } =
      parseDashboardGridControllersFromString(query);

    return {
      filters: filterValues,
      page,
      pageSize,
      sorting,
    };
  }

  function* handleGetItems(): Generator {
    yield delay(200);
    try {
      yield put(dashboardActions.setIsLoading(true));
      const filterValues = yield* select(filterValuesSelector);
      const dashboardGridControllers = yield* select(
        dashboardGridControllersSelector
      );

      const { data, totalCount } = (yield* call(
        api.apiGetGridData,
        filterValues,
        dashboardGridControllers
      )) as unknown as GRT<typeof api.apiGetGridData>;

      yield put(dashboardActions.setItems({ items: data, totalCount }));
      yield put(dashboardActions.setIsLoading(false));
    } catch (error) {
      handleServerError({ error });
    }
  }

  function* handleChangeUrl() {
    yield put(dashboardActions.getItems());
    const filterValues = yield* select(filterValuesSelector);
    const { filters, withoutUrlChanging } = yield* select(
      dashboardBaseSelector
    );
    const filterPlainObj = {};

    if (!withoutUrlChanging) {
      if (filterValues) {
        Object.entries(filterValues).forEach(([key, value]) => {
          const filterType = filters?.find((f) => f.name === key)?.type;

          if (filterType)
            filterPlainObj[key] = parseFilterValueToStringByType(
              filterType,
              value
            );
        });

        const gridModel = yield* select(dashboardGridControllersSelector);
        const { sortOrder, sortedBy } = (gridModel.sorting ||
          {}) as DashboardSortingType;

        yield* call(changeQueryObjects, {
          ...filterPlainObj,
          page: gridModel.page,
          pageSize: gridModel.pageSize,
          sorting: sortOrder
            ? `${sortOrder === GridSort.DESC ? '-' : ''}${sortedBy}`
            : null,
        });
      }
    }
  }
}
