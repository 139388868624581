import { SECONDARY_COLOR, POPOVER_TEXT_COLOR } from './colors';

export const popoverTheme = {
  appearance: {
    default: {
      content: {
        padding: '15px',
        boxShadow: '0 5px 20px 0 rgba(0, 0, 0, 0.2)',
        minWidth: '200px',
        border: `1px solid var(--secondary-color)`,
        borderRadius: '8px',
        backgroundColor: SECONDARY_COLOR,
        zIndex: 2,
        color: POPOVER_TEXT_COLOR,
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '130%',
        willChange: 'auto !important',
      },
      arrow: {
        background: SECONDARY_COLOR,
        border: {
          color: SECONDARY_COLOR,
        },
      },
    },
    form: {
      content: {
        maxWidth: '50%',
        padding: 0,
        margin: 0,
      },
      wrapper: {
        margin: 0,
        padding: 0,
        cursor: 'auto',
      },
    },
    menu: {
      content: {
        padding: 0,
        width: 125,
        minWidth: 125,
        maxWidth: 125,
        backgroundColor: 'var(--hover-secondary-color)',
      },
    },
    tooltip: {
      content: {
        maxWidth: 280,
        padding: 8,
        minWidth: 'auto',
      },
    },
  },
};
