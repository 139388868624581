export const getLinkByMobileOperatingSystem = (
  userAgent: string,
  location: Location
): string => {
  if (/android/i.test(userAgent)) {
    return location.href.replace(location.origin, 'affshore:/');
  }

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return location.href.replace(location.origin, 'mob.ams:/');
  }

  return location.href;
};
