import React from 'react';

export const HistoryIcon: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.9999 4.49994C9.18484 4.49994 8.44529 4.63045 7.77053 4.8321C7.4398 4.93094 7.09158 4.74296 6.99274 4.41223C6.8939 4.08151 7.08188 3.73328 7.41261 3.63444C8.18785 3.40276 9.0483 3.24994 9.9999 3.24994C14.3367 3.24994 17.8499 6.76309 17.8499 11.0999C17.8499 15.4368 14.3367 18.9499 9.9999 18.9499C5.66306 18.9499 2.1499 15.4368 2.1499 11.0999C2.1499 9.48859 2.63904 7.98533 3.47154 6.73658C3.66301 6.44938 4.05105 6.37177 4.33826 6.56324C4.62546 6.75471 4.70307 7.14275 4.5116 7.42996C3.81076 8.48121 3.3999 9.74463 3.3999 11.0999C3.3999 14.7464 6.35341 17.6999 9.9999 17.6999C13.6464 17.6999 16.5999 14.7464 16.5999 11.0999C16.5999 7.45345 13.6464 4.49994 9.9999 4.49994Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.37681 1.19528C9.63716 1.42192 9.6645 1.8167 9.43786 2.07705L7.02953 4.84372C6.80289 5.10408 6.40811 5.13141 6.14776 4.90478C5.8874 4.67814 5.86007 4.28336 6.0867 4.02301L8.49503 1.25634C8.72167 0.995984 9.11645 0.968648 9.37681 1.19528Z"
      fill="none"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.05334 4.06496C6.25685 3.78616 6.64785 3.72513 6.92665 3.92864L9.73498 5.97864C10.0138 6.18216 10.0748 6.57315 9.87129 6.85195C9.66778 7.13075 9.27678 7.19178 8.99798 6.98827L6.18965 4.93827C5.91085 4.73475 5.84982 4.34376 6.05334 4.06496Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.62256 7.99994C9.96774 7.99994 10.2476 8.27976 10.2476 8.62494V10.8522C10.2476 10.8709 10.2603 10.9448 10.3244 11.0445C10.3864 11.141 10.4614 11.2058 10.5137 11.2333L10.5153 11.2342L10.5153 11.2342L12.4148 12.2392C12.72 12.4006 12.8364 12.7788 12.675 13.0839C12.5136 13.389 12.1354 13.5055 11.8303 13.344L9.93236 12.3399C9.93206 12.3397 9.93176 12.3396 9.93145 12.3394C9.65332 12.193 9.42835 11.9621 9.27308 11.7207C9.11962 11.4821 8.99756 11.1758 8.99756 10.8522V8.62494C8.99756 8.27976 9.27738 7.99994 9.62256 7.99994Z"
      fill="currentColor"
    />
  </svg>
);
