import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GridSort } from '@xcritical/grid';

import { ReduxInject } from '@crm-framework/saga-injector';

import { Filters } from '@ams-package/filters';
import { Grid, IGridProps } from '@ams-package/grid';
import { useQuery } from '@ams-package/utils';
import { filterValuesSelector } from '@ams-package/filters/selectors';

import { DashboardWrapper, GridWrapper } from './styled';
import { DashboardSettingsType, IDashboardApi } from './types';
import { STORE_NAME, dashboardActions, dashboardReducer } from './store';
import {
  dashboardGridControllersSelector,
  dashboardIsClearButtonDisabledSelector,
  dashboardIsLoadingSelector,
  dashboardIsReadySelector,
  dashboardItemsSelector,
  dashboardTotalCountSelector,
} from './selectors';
import { getDashboardColumns } from './columns';
import { getFilters } from './filters';
import { getWatchDashboard } from './sagas';

export type DashboardProps = {
  settings: DashboardSettingsType[];
  withoutUrlChanging?: boolean;
  gridProps?: Partial<IGridProps>;
  onChangePage?: (page: number) => void;
  onPageSizeChange?: (page: number, pageSize: number) => void;
};

export interface IDashBoardWithApi extends DashboardProps {
  api: IDashboardApi;
}

export const DashboardBase: React.FC<DashboardProps> = ({
  settings,
  gridProps,
  onChangePage,
  onPageSizeChange,
  withoutUrlChanging = false,
}) => {
  const dispatch = useDispatch();
  const { clearQuery } = useQuery();
  const items = useSelector(dashboardItemsSelector);
  const totalItems = useSelector(dashboardTotalCountSelector);
  const filterValues = useSelector(filterValuesSelector);
  const {
    pageSize: currentPageSize,
    page: currentPage,
    sorting,
  } = useSelector(dashboardGridControllersSelector);
  const isClearButtonDisabled = useSelector(
    dashboardIsClearButtonDisabledSelector
  );
  const isReady = useSelector(dashboardIsReadySelector);
  const isLoading = useSelector(dashboardIsLoadingSelector);
  const filters = useMemo(() => getFilters(settings), [settings]);
  const getDashboardItems = useCallback(() => {
    dispatch(dashboardActions.getItems());
  }, []);

  const onChangeFilterValue = useCallback(() => {
    onChangePage?.(1);
    dispatch(dashboardActions.setPage(1));
  }, [onChangePage]);

  useEffect(() => {
    dispatch(
      dashboardActions.init({
        filters,
        withoutUrlChanging,
      })
    );
  }, [filters, withoutUrlChanging]);

  const onChangePageHandler = useCallback(
    (page: number) => {
      dispatch(dashboardActions.setPage(page));
      onChangePage?.(page);
      getDashboardItems();
    },
    [onChangePage, getDashboardItems]
  );

  const onPageSizeChangeHandler = useCallback(
    (page: number, pageSize: number) => {
      dispatch(dashboardActions.setPage(page));
      dispatch(dashboardActions.setPageSize(pageSize));
      onPageSizeChange?.(page, pageSize);
      getDashboardItems();
    },
    [onPageSizeChange, getDashboardItems]
  );

  const onSortHandler = useCallback(
    (column: string, direction: GridSort | null) => {
      dispatch(
        dashboardActions.changeSorting(
          direction
            ? {
                sortedBy: column,
                sortOrder: direction,
              }
            : null
        )
      );
      getDashboardItems();
    },
    [getDashboardItems]
  );

  const onClearAllClick = useCallback(() => {
    clearQuery();
    dispatch(dashboardActions.resetDashboardControllers());
    getDashboardItems();
  }, [clearQuery, getDashboardItems]);

  const totalPages = Math.ceil(totalItems / currentPageSize);
  const initialPage = currentPage > totalPages ? 1 : currentPage;

  const columns = useMemo(
    () => getDashboardColumns(settings, sorting, onSortHandler, filterValues),
    [settings, sorting, onSortHandler, items]
  );

  return (
    <DashboardWrapper>
      {!!filters.length && (
        <Filters
          disableClearButton={isClearButtonDisabled}
          filters={filters}
          onChange={onChangeFilterValue}
          onClear={onClearAllClick!}
        />
      )}

      <GridWrapper>
        <Grid
          onChangePageSize={onPageSizeChangeHandler}
          onChangePage={onChangePageHandler}
          columns={columns}
          initialPage={initialPage!}
          totalItems={totalItems}
          items={items}
          isLoading={!isReady || isLoading}
          pageSize={currentPageSize}
          {...(gridProps ?? {})}
        />
      </GridWrapper>
    </DashboardWrapper>
  );
};

export const Dashboard: React.FC<IDashBoardWithApi> = ({ api, ...props }) => {
  const saga = useMemo(() => getWatchDashboard(api), [api]);

  return (
    <ReduxInject
      keyName={STORE_NAME}
      saga={saga}
      allowSagaUnmount
      reducer={dashboardReducer}>
      <DashboardBase {...props} />
    </ReduxInject>
  );
};

export default Dashboard;
