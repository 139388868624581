import React, { useCallback, useEffect, useState } from 'react';

import { DatePickerRange, DatePickerRangeType } from '@ams-package/datepicker';
import { getDatePickerLabel } from '@ams-package/datepicker/utils';

import { DateFilterType } from '../types';
import { FilterController } from '../filter-controller';
import { DateFilterButton } from '../filter-button';
import { DatePickerWrapper } from '../styled';

export const DateFilter: React.FC<DateFilterType> = (filter) => {
  const [dateRange, setDateRange] = useState<DatePickerRangeType>([null, null]);
  const { onChange, placeholder, value, isClearable } = filter;

  const onChangeHandler = useCallback(
    (range) => {
      const [from, to] = range || [];

      setDateRange(range);

      if (from && to) onChange?.(range);
    },
    [onChange]
  );

  useEffect(() => {
    setDateRange(value as DatePickerRangeType);
  }, [value]);

  const onClearHandler = useCallback(() => {
    setDateRange([null, null]);
  }, []);

  const filterValue = (dateRange ?? []) as DatePickerRangeType;

  return (
    <FilterController
      value={getDatePickerLabel(filterValue)}
      filter={filter}
      isClearable={!!isClearable}
      onClear={onClearHandler}
      FilterButtonComponent={DateFilterButton}>
      <DatePickerWrapper>
        <DatePickerRange
          placeholder={placeholder ?? 'Date'}
          dateRange={filterValue}
          onChange={onChangeHandler}
          withSelects
        />
      </DatePickerWrapper>
    </FilterController>
  );
};
