import { css } from 'styled-components';

export const scrollbarStyles = css`
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  ::-moz-scrollbar {
    width: 4px;
    height: 4px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 30px;
    background-color: var(--dark-grey);
  }
  ::-moz-scrollbar-track {
    border-radius: 30px;
    background-color: var(--dark-grey);
  }

  ::-webkit-scrollbar-thumb {
    cursor: auto;
    border-radius: 30px;
    background-color: var(--hover-secondary-color);

    &:hover {
      background-color: var(--light-grey);
    }
  }
  ::-moz-scrollbar-thumb {
    cursor: auto;
    border-radius: 30px;
    background-color: var(--hover-secondary-color);

    &:hover {
      background-color: var(--light-grey);
    }
  }

  ::-webkit-scrollbar-corner {
    background-color: var(--secondary-scrollbar-track);
  }
  ::-moz-scrollbar-corner {
    background-color: var(--secondary-scrollbar-track);
  }
`;

export const secondaryScrollbarStyles = css`
  ${scrollbarStyles}

  ::-webkit-scrollbar-track {
    background-color: var(--secondary-scrollbar-track);
  }
  ::-moz-scrollbar-track {
    background-color: var(--secondary-scrollbar-track);
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--secondary-light-grey);

    &:hover {
      background-color: var(--light-grey);
    }
  }
  ::-moz-scrollbar-thumb {
    background-color: var(--secondary-light-grey);

    &:hover {
      background-color: var(--light-grey);
    }
  }
`;
