import React, { MouseEventHandler } from 'react';

import { ButtonWithValue } from '@ams-package/button';

import { DatePicker, ReactDatePickerProps } from './DatePicker';
import { getDatePickerLabel } from './utils';

export type DatePickerRangeType = [Date | null, Date | null];

export type CustomInputProps = {
  value: string;
  placeholder?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onClear: MouseEventHandler<SVGSVGElement>;
};

export type ReactDatePickerRangeProps = ReactDatePickerProps & {
  dateRange: DatePickerRangeType;
  CustomInput?: React.FC<CustomInputProps>;
};

export const DatePickerRange: React.FC<ReactDatePickerRangeProps> = ({
  dateRange,
  onChange,
  CustomInput,
  ...rest
}) => {
  const [startDate, endDate] = dateRange || [];

  const clearHandler = (e: React.SyntheticEvent) =>
    onChange([null, null] as any, e);

  const DatePickerInput = CustomInput ?? ButtonWithValue;

  return (
    <DatePicker
      selectsRange
      startDate={startDate}
      endDate={endDate}
      inline
      maxDate={new Date()}
      onChange={onChange}
      customInput={
        <DatePickerInput
          value={getDatePickerLabel(dateRange)}
          onClear={clearHandler}
        />
      }
      {...(rest as any)}
    />
  );
};
