import React from 'react';

import Input from '@xcritical/input';

import { ErrorWrapper } from '@ams-package/utils';

import { InputFormFieldProps } from './types';
import { ClearIcon } from './Icons';

export const InputFormField: React.FC<InputFormFieldProps> = ({
  error,
  ...props
}) => (
  <ErrorWrapper content={error}>
    <Input {...props} id={props.name} clearIcon={ClearIcon} />
  </ErrorWrapper>
);
