import React from 'react';
import { useDispatch } from 'react-redux';

import Button from '@xcritical/button';

import { StatusMessage, StatusModalTypes } from '@ams-package/status-message';

import { paymentEditorActions } from '../store';

import { PaymentMethodWrapper, StatusMessageWrapper } from './payment-method';

export const FailureState: React.FC = () => {
  const dispatch = useDispatch();
  const handlerTryAgain = () => {
    dispatch(paymentEditorActions.clearState());
  };

  return (
    <PaymentMethodWrapper>
      <StatusMessageWrapper>
        <StatusMessage
          type={StatusModalTypes.Failure}
          title="Something went wrong. Try again."
        />
      </StatusMessageWrapper>
      <Button shouldFitContent onClick={handlerTryAgain}>
        Try Again
      </Button>
    </PaymentMethodWrapper>
  );
};
