import styled from 'styled-components';

import { classNameWithPrefix } from '@crm-framework/utils';

const blockName = 'select';

export const SelectWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'wrapper'),
})`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const LabelWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'label-wrapper'),
})<{ disabled: boolean }>`
  display: flex;
  width: 100%;
  margin-bottom: 8px;
  color: ${({ disabled }) => (disabled ? '#727272' : '#e4e4e4')};
  font-size: var(--input-label-font-size);
  font-weight: 500;
`;
