import styled from 'styled-components';

import { classNameWithPrefix } from '@crm-framework/utils';

const blockName = 'dashboard';

export const DashboardWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'wrapper'),
})`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const GridWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'grid-wrapper'),
})`
  display: flex;
  margin-top: 16px;
  height: 100%;
`;
