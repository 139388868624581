import React, { ReactElement } from 'react';

import { Select, SelectFormFieldType } from '../select';

import { FilterWrapper } from './styled';

type FilterSelectProps = SelectFormFieldType;

export const FilterSelect = ({
  label,
  ...rest
}: FilterSelectProps): ReactElement => (
  <FilterWrapper>
    <Select
      appearance="filter-select"
      placeholder={label as string}
      withSelectedIcon={false}
      shouldFitContainer
      autoFocus={false}
      isClearable={false}
      {...(rest as any)}
    />
  </FilterWrapper>
);
