import styled from 'styled-components';

import { classNameWithPrefix } from '@crm-framework/utils';

import { getPaginationStyles } from './utils';

const blockName = 'pagination';

export const PaginationWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'wrapper'),
})`
  ${({ theme }) => getPaginationStyles(theme, ['wrapper'])};
`;

export const StyledTotals = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'totals'),
})`
  ${({ theme }) => getPaginationStyles(theme, ['totals'])};
`;

export const PageSizeWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'page-size-wrapper'),
})`
  ${({ theme }) => getPaginationStyles(theme, ['pageSizeWrapper'])};
`;

export const PageSizeLabel = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'page-size-label'),
})`
  ${({ theme }) => getPaginationStyles(theme, ['pageSizeLabel'])};
`;

export const PageWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'page-wrapper'),
})`
  ${({ theme }) => getPaginationStyles(theme, ['pageWrapper'])};
`;

export const PageLabel = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'page-label'),
})`
  ${({ theme }) => getPaginationStyles(theme, ['pageLabel'])};
`;

export const ControlsWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'controls-wrapper'),
})`
  ${({ theme }) => getPaginationStyles(theme, ['controlsWrapper'])};
`;
