export const MenuItemsPathNames = {
  dashboard: '/',
  offers: '/offers',
  statistics: '/statistics',
  promo: '/promo',
  payouts: '/payouts',
  accountSettings: '/account-settings',
};

export const AdditionalPathNames = {
  offer: '/offers/:id',
};

export const UnauthorizedPathNames = {
  login: '/login',
  registration: '/registration',
  resetPassword: '/reset-password',
  restorePassword: '/reset',
};

// TODO: remove after adding storybook
export const TestPathNames = {
  testModals: '/testModals',
  testButtons: '/testButtons',
  testDropdowns: '/testDropdowns',
  testSideModal: '/testSideModal',
  testGrid: '/testGrid',
  testFilters: '/testFilters',
};

export const PathNames = {
  ...MenuItemsPathNames,
  ...UnauthorizedPathNames,
  ...AdditionalPathNames,
  // TODO: remove after adding storybook
  ...TestPathNames,
};

export const protectiveOptions = [true, false];
