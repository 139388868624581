import { DrawerTheme } from '@xcritical/drawer';

export const drawerTheme: DrawerTheme = {
  appearance: {
    default: {
      transition: '250ms',
      headerWrapper: {
        padding: '24px 24px',
      },
      wrapper: {
        height: 'calc(100% - 40px)',
        overflow: 'hidden',
        borderRadius: 20,
        margin: 20,
        background: 'var(--modal-background-color)',
        zIndex: 12,
      },
      contentWrapper: {},
      blanketWrapper: {
        ':only-child': {
          background: 'var(--blanket-color)',
          zIndex: 10,
        },
      },
      body: {
        padding: '0px 24px 24px 24px',
        flexGrow: 1,
      },
      separator: {
        marginRight: '-10px',
        width: '20px',
      },
      closeIconWrapper: {
        padding: 0,
        background: 'transparent',
        width: '20px',
        height: '20px',
      },
    },
    login: {
      headerWrapper: {
        padding: '24px 24px 12px 24px',
        display: 'flex',
        justifyContent: 'space-between',
      },
      titleWrapper: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
      },
      closeIconWrapper: {
        display: 'none',
      },
    },
  },
};
