import React from 'react';

import { DictionarySelect } from '@ams-package/select';

import { FilterType, FilterTypes } from './types';
import { MultiSelectFilter } from './filters/MultiSelectFilter';
import { DateFilter } from './filters/DateFilter';
import { SingleSelectFilter } from './filters/SingleSelectFilter';

type SwitchFilterProps = FilterType & {
  value: any;
  onChange: (value: any) => void;
};

export const SwitchFilter: React.FC<SwitchFilterProps> = (props) => {
  const { type } = props;

  switch (type) {
    case FilterTypes.SingleSelect: {
      return <DictionarySelect as={SingleSelectFilter} {...props} />;
    }
    case FilterTypes.MultiSelect: {
      return <DictionarySelect as={MultiSelectFilter} {...props} />;
    }
    case FilterTypes.Date: {
      return <DateFilter {...props} />;
    }
    default: {
      console.error('Unknown filter type', type);

      return null;
    }
  }
};
