import { css } from 'styled-components';

export const Popper = (popperStyles) => css`
  .react-datepicker-popper {
    ${popperStyles}

    &[data-placement^="bottom"] {
      margin-top: 5px;

      .react-datepicker__triangle {
        margin-top: -8px;
      }
    }

    &[data-placement='bottom-end'],
    &[data-placement='top-end'] {
      .react-datepicker__triangle {
        left: auto;
        right: 50px;
      }
    }

    &[data-placement^='top'] {
      margin-bottom: 5px;

      .react-datepicker__triangle {
        width: 8px;
        height: 8px;
      }
    }

    &[data-placement^='right'] {
      margin-left: 8px;

      .react-datepicker__triangle {
        left: auto;
        right: 42px;
      }
    }

    &[data-placement^='left'] {
      margin-right: 8px;

      .react-datepicker__triangle {
        left: 42px;
        right: auto;
      }
    }
  }
`;
