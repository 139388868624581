import { IPaginationTheme } from '@ams-package/pagination';

export const paginationTheme: IPaginationTheme = {
  wrapper: {
    display: 'flex',
    width: '100%',
    padding: '12px 20px',
    height: '48px',
    alignItems: 'center',
    background: 'var(--grid-background)',
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    borderTop: '1px solid var(--secondary-border-color);',
  },
  totals: {
    display: 'flex',
    marginLeft: '50px',
    alignItems: 'center',
    marginRight: 'auto',
    fontSize: '14px',
    fontWeight: 400,
    color: 'var(--hover-secondary-color)',
  },
  pageSizeLabel: {
    fontSize: '14px',
    fontWeight: 400,
    color: 'var(--pagination-text-color)',
    marginLeft: 8,
  },
  controlsWrapper: {
    display: 'flex',
    marginLeft: 20,
    gap: 8,
  },
  pageSizeWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  pageLabel: {
    fontSize: '14px',
    fontWeight: 400,
    color: 'var(--pagination-text-color)',
    marginLeft: 8,
  },
  pageWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    padding: 0,
    width: 16,
    maxWidth: 16,
    boxShadowColor: 'transparent',
    backgroundColor: 'transparent',
    color: 'var(--pagination-text-color)',
    buttonContent: {
      display: 'flex',
      alignItems: 'center',
    },
    border: 'none',
    selected: {
      boxShadowColor: 'transparent',
      backgroundColor: 'transparent',
      border: 'none',
    },
    disabled: {
      backgroundColor: 'transparent',
      border: 'none',
    },
    hover: {
      boxShadowColor: 'transparent',
      backgroundColor: 'transparent',
      color: 'var(--secondary-text-color)',
      border: 'none',

      path: {
        stroke: 'var(--secondary-text-color)',
      },
    },
    active: {
      boxShadowColor: 'transparent',
      backgroundColor: 'transparent',
      border: 'none',
    },
  },
  select: {
    minWidth: '50px',
    width: 'fit-content',
    background: 'transparent',
    selected: {
      background: 'var(--secondary-color)',
      color: 'var(--pagination-text-color)',
    },
    active: {
      background: 'var(--secondary-color)',
      color: 'var(--pagination-text-color)',
    },
    disabled: {
      background: 'var(--secondary-color)',
      color: 'var(--pagination-text-color)',
      border: 'none',
      opacity: 0.5,
    },
    button: {
      padding: '0px 11px',
      border: 'none',
      height: '24px',
      cursor: 'pointer',
      borderRadius: 8,
      background: 'var(--secondary-color)',
      color: 'var(--pagination-text-color)',
      hover: {
        background: 'var(--secondary-color)',
        color: 'var(--secondary-text-color)',
        path: {
          stroke: 'var(--secondary-text-color)',
        },
        span: {
          color: 'var(--secondary-text-color)',
        },
      },
      active: {
        background: 'var(--secondary-color)',
        color: 'var(--secondary-text-color)',
      },
      selected: {
        background: 'var(--secondary-color)',
        color: 'var(--secondary-text-color)',
      },
    },
    dropdownIndicator: {
      padding: '0px',
    },
    indicatorSeparator: {
      display: 'none',
    },
    dropdown: {
      color: 'var(--pagination-text-color)',
      background: 'var(--secondary-color)',
      borderRadius: 8,
      selected: {
        background: 'var(--secondary-color)',
        color: 'var(--pagination-text-color)',
      },
      hover: {
        background: 'var(--secondary-color)',
        color: 'var(--pagination-text-color)',
      },
    },
    dropdownList: {
      background: 'var(--secondary-color)',
      borderRadius: 8,
      hover: {
        background: 'var(--secondary-color)',
      },
    },
    option: {
      cursor: 'pointer',
      fontSize: 12,
      lineHeight: '16px',
      display: 'flex',
      justifyContent: 'center',
      color: 'var(--pagination-text-color)',
      background: 'var(--secondary-color)',
      hover: {
        background: 'var(--secondary-color)',
        color: 'var(--secondary-text-color)',
      },
      active: {
        background: 'var(--secondary-color)',
        color: 'var(--secondary-text-color)',
      },
      focus: {
        background: 'var(--secondary-color)',
        color: 'var(--secondary-text-color)',
      },
      selected: {
        background: 'var(--secondary-color)',
        color: 'var(--secondary-text-color)',
        fontWeight: 400,
      },
      span: {
        ':hover': {
          background: 'var(--secondary-color)',
          color: 'var(--secondary-text-color)',
        },
      },
    },
    valueContainer: {
      marginRight: 8,
      padding: '0px',
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '16px',
      color: 'var(--pagination-text-color)',
    },
  },
};
