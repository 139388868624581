import styled from 'styled-components';

import { classNameWithPrefix } from '@crm-framework/utils';

const blockName = 'filters';

export const FiltersWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'wrapper'),
})<{ filterCounts: number }>`
  display: flex;
  gap: 16px;
  flex-direction: row;
  width: fit-content;

  /* TODO: return when it will be more than 4 filters */
  /* @media (max-width: 1440px) {
    flex-direction: ${({ filterCounts }) =>
    filterCounts > 4 ? 'column' : 'row'};
  } */
`;

export const FilterControlsWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'controls-wrapper'),
})<{ filterCounts: number }>`
  display: grid;
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const ClearButtonWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'clear-button-wrapper'),
})<{ filterCounts: number }>`
  display: flex;

  @media (max-width: 1440px) {
    margin-left: ${({ filterCounts }) => (filterCounts > 3 ? 'auto' : 0)};
  }
`;

export const DatePickerWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'date-picker-wrapper'),
})`
  margin-top: 8px;
`;
