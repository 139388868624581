import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReduxInject } from '@crm-framework/saga-injector';

import { SideModal } from '@ams-package/side-modal';

import {
  paymentEditorActions,
  paymentEditorReducer,
  STORE_NAME,
} from './store';
import { watchers } from './sagas';
import { drawerSelector } from './selectors';
import {
  PaymentMethodModalContent,
  ClosePaymentMethod,
  StatusMessagesType,
} from './components';
import { PaymentEditorMode } from './types';

const getTitleByMode = (mode, selectedId) => {
  switch (mode) {
    case PaymentEditorMode.AddOrEdit: {
      return `${selectedId ? 'Edit' : 'Add'} payment method`;
    }
    case PaymentEditorMode.Delete: {
      return 'Delete payment method';
    }
    default: {
      return '';
    }
  }
};

export type PaymentDrawerProps = {
  statusMessages?: StatusMessagesType;
};

export const PaymentDrawer: React.FC<PaymentDrawerProps> = ({
  statusMessages,
}) => {
  const { isOpen, mode, isChanged, selectedId } = useSelector(drawerSelector);
  const dispatch = useDispatch();
  const [isConfirmationMode, setIsConfirmationMode] = useState(false);

  const onCloseModal = useCallback(() => {
    if (!isChanged || isConfirmationMode) {
      setIsConfirmationMode(false);
      dispatch(paymentEditorActions.close());
    } else {
      setIsConfirmationMode(true);
    }
  }, [isChanged, isConfirmationMode]);

  const onCloseConfirm = useCallback(() => {
    setIsConfirmationMode(false);
  }, []);

  useEffect(() => {
    dispatch(paymentEditorActions.init());
  }, []);

  return (
    <ReduxInject
      keyName={STORE_NAME}
      saga={watchers}
      allowSagaUnmount
      reducer={paymentEditorReducer}>
      <SideModal
        isOpen={isOpen}
        title={getTitleByMode(mode, selectedId)}
        onClose={onCloseModal}>
        {isConfirmationMode ? (
          <ClosePaymentMethod onClose={onCloseConfirm} />
        ) : (
          <PaymentMethodModalContent statusMessages={statusMessages} />
        )}
      </SideModal>
    </ReduxInject>
  );
};
