import styled from 'styled-components';

export const AccountInfoIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: var(--sidebar-account-info-icon-size);
    height: var(--sidebar-account-info-icon-size);
  }
`;

export const ContactInfoButtonsBlock = styled.div<{
  numberOfButtons: number;
}>`
  display: grid;
  grid-template-columns: repeat(
    ${({ numberOfButtons }) => numberOfButtons},
    minmax(
      140px,
      ${({ numberOfButtons }) => (numberOfButtons === 1 ? '100%' : '50%')}
    )
  );
  align-items: center;
  gap: 10px;

  a,
  button {
    padding: var(--account-info-buttons-padding);
  }
`;
