import React from 'react';

export const CopyIcon = ({
  className,
  stroke = 'var(--hover-border-color)',
}: {
  className?: string;
  stroke?: string;
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 18 18"
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.05636 7.05652C1.57467 7.53822 1.2915 8.35331 1.2915 9.75V13.25C1.2915 14.6467 1.57467 15.4618 2.05636 15.9435C2.53805 16.4252 3.35315 16.7083 4.74984 16.7083H8.24984C9.64653 16.7083 10.4616 16.4252 10.9433 15.9435C11.425 15.4618 11.7082 14.6467 11.7082 13.25V9.75C11.7082 8.35331 11.425 7.53822 10.9433 7.05652C10.4616 6.57483 9.64653 6.29166 8.24984 6.29166H4.74984C3.35315 6.29166 2.53805 6.57483 2.05636 7.05652ZM1.17248 6.17264C2.00329 5.34183 3.22986 5.04166 4.74984 5.04166H8.24984C9.76981 5.04166 10.9964 5.34183 11.8272 6.17264C12.658 7.00345 12.9582 8.23002 12.9582 9.75V13.25C12.9582 14.77 12.658 15.9966 11.8272 16.8274C10.9964 17.6582 9.76981 17.9583 8.24984 17.9583H4.74984C3.22986 17.9583 2.00329 17.6582 1.17248 16.8274C0.341672 15.9966 0.0415039 14.77 0.0415039 13.25V9.75C0.0415039 8.23002 0.341672 7.00345 1.17248 6.17264Z"
      fill={stroke}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.05636 2.05652C6.57467 2.53822 6.2915 3.35331 6.2915 4.75V5.04167H8.24984C9.76981 5.04167 10.9964 5.34183 11.8272 6.17264C12.658 7.00345 12.9582 8.23002 12.9582 9.75V11.7083H13.2498C14.6465 11.7083 15.4616 11.4252 15.9433 10.9435C16.425 10.4618 16.7082 9.64669 16.7082 8.25V4.75C16.7082 3.35331 16.425 2.53822 15.9433 2.05652C15.4616 1.57483 14.6465 1.29167 13.2498 1.29167H9.74984C8.35315 1.29167 7.53806 1.57483 7.05636 2.05652ZM6.17248 1.17264C7.00329 0.341834 8.22986 0.041666 9.74984 0.041666H13.2498C14.7698 0.041666 15.9964 0.341834 16.8272 1.17264C17.658 2.00345 17.9582 3.23002 17.9582 4.75V8.25C17.9582 9.76998 17.658 10.9966 16.8272 11.8274C15.9964 12.6582 14.7698 12.9583 13.2498 12.9583H12.3332C11.988 12.9583 11.7082 12.6785 11.7082 12.3333V9.75C11.7082 8.35331 11.425 7.53822 10.9433 7.05652C10.4616 6.57483 9.64653 6.29167 8.24984 6.29167H5.6665C5.32133 6.29167 5.0415 6.01184 5.0415 5.66667V4.75C5.0415 3.23002 5.34167 2.00345 6.17248 1.17264Z"
      fill={stroke}
    />
  </svg>
);

export const CheckedCopyIcon = ({
  className,
  stroke = 'var(--black)',
}: {
  className?: string;
  stroke?: string;
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 19 19"
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.82407 1.87045C7.64715 1.04738 8.86231 0.75 10.3681 0.75H13.8356C15.3414 0.75 16.5566 1.04738 17.3797 1.87045C18.2027 2.69353 18.5001 3.9087 18.5001 5.41454V8.88198C18.5001 10.3878 18.2027 11.603 17.3797 12.4261C16.5566 13.2491 15.3414 13.5465 13.8356 13.5465H12.9275C12.5855 13.5465 12.3083 13.2693 12.3083 12.9273V10.368C12.3083 8.98433 12.0277 8.17682 11.5505 7.6996C11.0733 7.22239 10.2658 6.94186 8.8821 6.94186H6.3228C5.98083 6.94186 5.70361 6.66464 5.70361 6.32267V5.41454C5.70361 3.9087 6.00099 2.69353 6.82407 1.87045ZM7.69973 2.74612C7.22252 3.22333 6.94199 4.03084 6.94199 5.41454V5.70349H8.8821C10.3879 5.70349 11.6031 6.00086 12.4262 6.82394C13.2493 7.64702 13.5466 8.86219 13.5466 10.368V12.3081H13.8356C15.2193 12.3081 16.0268 12.0276 16.504 11.5504C16.9812 11.0732 17.2618 10.2657 17.2618 8.88198V5.41454C17.2618 4.03084 16.9812 3.22333 16.504 2.74612C16.0268 2.2689 15.2193 1.98837 13.8356 1.98837H10.3681C8.98445 1.98837 8.17694 2.2689 7.69973 2.74612Z"
      fill={stroke}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.87045 6.82396C2.69353 6.00089 3.9087 5.70351 5.41454 5.70351H8.88198C10.3878 5.70351 11.603 6.00089 12.4261 6.82396C13.2491 7.64704 13.5465 8.86221 13.5465 10.368V13.8355C13.5465 15.3413 13.2491 16.5565 12.4261 17.3796C11.603 18.2026 10.3878 18.5 8.88198 18.5H5.41454C3.9087 18.5 2.69353 18.2026 1.87045 17.3796C1.04738 16.5565 0.75 15.3413 0.75 13.8355V10.368C0.75 8.86221 1.04738 7.64704 1.87045 6.82396ZM2.74612 7.69963C2.2689 8.17684 1.98837 8.98435 1.98837 10.368V13.8355C1.98837 15.2192 2.2689 16.0267 2.74612 16.5039C3.22333 16.9811 4.03084 17.2616 5.41454 17.2616H8.88198C10.2657 17.2616 11.0732 16.9811 11.5504 16.5039C12.0276 16.0267 12.3081 15.2192 12.3081 13.8355V10.368C12.3081 8.98435 12.0276 8.17684 11.5504 7.69963C11.0732 7.22241 10.2657 6.94188 8.88198 6.94188H5.41454C4.03084 6.94188 3.22333 7.22241 2.74612 7.69963Z"
      fill={stroke}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.9965 10.0536C10.2386 10.2951 10.2391 10.6871 9.99763 10.9292L6.78611 14.149C6.67005 14.2654 6.51248 14.3308 6.34812 14.3309C6.18376 14.331 6.02611 14.2658 5.90989 14.1496L4.30001 12.5397C4.0582 12.2979 4.0582 11.9058 4.30001 11.664C4.54182 11.4222 4.93386 11.4222 5.17567 11.664L6.34716 12.8355L9.12084 10.0547C9.36234 9.81257 9.75438 9.81207 9.9965 10.0536Z"
      fill={stroke}
    />
  </svg>
);
