import React from 'react';

import { GridSort } from '@xcritical/grid';

interface ISortIconProps {
  className?: string;
  fill?: string;
  activeFill?: string;
  sortOrder?: GridSort | null;
}

export const SortIcon: React.VFC<ISortIconProps> = ({
  className,
  sortOrder,
  activeFill = 'var(--active-color)',
  fill = 'var(--hover-secondary-color)',
}) => (
  <svg
    className={className}
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.81357 1.23483C3.96002 1.08839 4.19745 1.08839 4.3439 1.23483L6.20391 3.09482C6.35036 3.24126 6.35036 3.4787 6.20392 3.62515C6.05747 3.7716 5.82004 3.7716 5.67359 3.62515L4.07874 2.03033L2.48392 3.62515C2.33747 3.7716 2.10003 3.7716 1.95358 3.62515C1.80714 3.4787 1.80714 3.24127 1.95358 3.09482L3.81357 1.23483Z"
      fill={sortOrder === GridSort.ASC ? activeFill : fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.07812 1.125C4.28523 1.125 4.45312 1.29289 4.45312 1.5V10.5C4.45312 10.7071 4.28523 10.875 4.07812 10.875C3.87102 10.875 3.70312 10.7071 3.70312 10.5V1.5C3.70312 1.29289 3.87102 1.125 4.07812 1.125Z"
      fill={sortOrder === GridSort.ASC ? activeFill : fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.22311 8.37497C7.36956 8.22853 7.607 8.22852 7.75344 8.37497L9.34829 9.96979L10.9431 8.37497C11.0896 8.22853 11.327 8.22853 11.4734 8.37497C11.6199 8.52142 11.6199 8.75886 11.4734 8.9053L9.61346 10.7653C9.46702 10.9117 9.22958 10.9117 9.08313 10.7653L7.22312 8.9053C7.07667 8.75886 7.07667 8.52142 7.22311 8.37497Z"
      fill={sortOrder === GridSort.DESC ? activeFill : fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.34766 1.125C9.55476 1.125 9.72266 1.29289 9.72266 1.5V10.5C9.72266 10.7071 9.55476 10.875 9.34766 10.875C9.14055 10.875 8.97266 10.7071 8.97266 10.5V1.5C8.97266 1.29289 9.14055 1.125 9.34766 1.125Z"
      fill={sortOrder === GridSort.DESC ? activeFill : fill}
    />
  </svg>
);
