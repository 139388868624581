import { call, put, select, takeEvery } from 'typed-redux-saga';
import { getObjectWithoutUndefinedPropsFrom, isObject } from 'utilitify';
import isEmpty from 'lodash/isEmpty';
import { PayloadAction } from '@reduxjs/toolkit';

import {
  formSelector,
  xcriticalFormDelete,
  xcriticalFormError,
  xcriticalFormInit,
  xcriticalFormReset,
  xcriticalFormShowErrors,
} from '@xcritical/forms';

import { IServerError } from '@crm-framework/utils';

import {
  IPayoutPaymentMethodConfigurationModel,
  IPayoutPaymentMethodFieldConfigurationModel,
  loadAndSaveDictionariesByName,
} from '@ams-package/dictionaries';
import { handleServerError, validateIsRequired } from '@ams-package/utils';

import { STORE_FORM_NAME, paymentEditorActions } from '../store';
import {
  formNameSelector,
  currentPaymentMethodSelector,
  selectedPaymentEditorSelector,
} from '../selectors';

import {
  addPaymentMethod,
  apiDeletePaymentMethod,
  apiGetPaymentMethod,
  editPaymentMethod,
} from './api';

export function* watchers() {
  yield* takeEvery(paymentEditorActions.init, handleInit);
  yield* takeEvery(paymentEditorActions.save, handleSave);
  yield* takeEvery(
    paymentEditorActions.selectPaymentMethod,
    handleSelectPaymentMethod
  );
  yield* takeEvery(paymentEditorActions.addOrEdit.type, handleEdit);
  yield* takeEvery(paymentEditorActions.close, handleClose);
  yield* takeEvery(paymentEditorActions.confirmRemoving, handleDelete);
}

export function* handleInit() {
  yield* loadAndSaveDictionariesByName(['payoutPaymentMethods']);
}

export function* handleSelectPaymentMethod({
  payload,
}: PayloadAction<IPayoutPaymentMethodConfigurationModel>) {
  if (payload) {
    yield put(xcriticalFormReset(STORE_FORM_NAME));

    const dictionaries = payload.fields
      .filter((field) => field.type === 'dictionary')
      .map((field) => field.name.toLowerCase());

    yield* loadAndSaveDictionariesByName(dictionaries);
  }
}

export function* handleEdit({
  payload,
}: PayloadAction<{ selectedId: number | undefined }>) {
  try {
    if (!payload.selectedId) return;

    const { payoutPaymentMethods } = yield* loadAndSaveDictionariesByName([
      'payoutPaymentMethods',
    ]);

    const paymentModel = yield* call(apiGetPaymentMethod, payload.selectedId);
    const paymentMethod = payoutPaymentMethods.find(
      (f) => f.id === paymentModel.name
    );
    yield* put(paymentEditorActions.selectPaymentMethod(paymentMethod!));
    const model = paymentModel.fields.reduce((acc, field) => {
      acc[field.name] = field.value;

      return acc;
    }, {});
    yield* put(xcriticalFormInit(STORE_FORM_NAME, model));
  } catch (error) {
    handleServerError({
      error,
      formName: STORE_FORM_NAME,
      statusModalName: 'global',
    });
  }
}

export function* handleSave() {
  const currentPaymentMethod = yield* select(currentPaymentMethodSelector);
  const formName = yield* select(formNameSelector);
  let selectedId = yield* select(selectedPaymentEditorSelector);
  const { model } = yield* select(formSelector, STORE_FORM_NAME);

  if (currentPaymentMethod) {
    const errors = validateModel(currentPaymentMethod, model);

    if (!isEmpty(getObjectWithoutUndefinedPropsFrom(errors))) {
      yield put(xcriticalFormShowErrors(STORE_FORM_NAME, true));
      yield put(xcriticalFormError(STORE_FORM_NAME, errors));

      return;
    }

    try {
      if (selectedId) {
        yield* call(editPaymentMethod, selectedId, model);
      } else {
        selectedId = yield* call(
          addPaymentMethod,
          currentPaymentMethod.id,
          model
        );
      }

      yield* loadAndSaveDictionariesByName(['paymentMethods'], undefined, [
        'paymentMethods',
      ]);
      yield* put(xcriticalFormDelete(STORE_FORM_NAME));
      yield* put(paymentEditorActions.onSuccess(selectedId, formName));
    } catch (error) {
      if (isObject(error) && (error as IServerError).status < 500) {
        yield* handleServerError({
          error,
          formName: STORE_FORM_NAME,
        });
      } else {
        yield* put(paymentEditorActions.onFailure());
      }
    }
  }
}

export function* handleClose() {
  yield* put(xcriticalFormDelete(STORE_FORM_NAME));
}

function validateModel(
  currentPaymentMethod: IPayoutPaymentMethodConfigurationModel,
  model: Record<string | number, any> | undefined
) {
  let errors = {};
  currentPaymentMethod.fields.forEach((field) => {
    errors[field.name] = validateField(model || {}, field);
  });
  errors = getObjectWithoutUndefinedPropsFrom(errors);

  return errors;
}

export const validateField = (
  model: object,
  field: IPayoutPaymentMethodFieldConfigurationModel
) => {
  if (field.required) {
    return validateIsRequired({ value: model[field.name] });
  }

  if (field.regex) {
    if (new RegExp(field.regex).test(model[field.name])) {
      return field.regexMessage;
    }
  }

  if (field.requiredIfNullOrEmpty && !model[field.name]) {
    if (!model[field.requiredIfNullOrEmpty]) {
      return field.requiredMessage;
    }
  }

  return undefined;
};

export function* handleDelete() {
  const selectedId = yield* select(selectedPaymentEditorSelector);
  const formName = yield* select(formNameSelector);

  try {
    yield* call(apiDeletePaymentMethod, selectedId);

    yield* loadAndSaveDictionariesByName(['paymentMethods'], undefined, [
      'paymentMethods',
    ]);
    yield* put(paymentEditorActions.onSuccess(selectedId, formName));
  } catch (error) {
    if (isObject(error) && (error as IServerError).status < 500) {
      yield* handleServerError({
        error,
        formName: STORE_FORM_NAME,
      });
    } else {
      yield* put(paymentEditorActions.onFailure());
    }
  }
}
