/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
import styled from 'styled-components';

import { getAppearanceTheme, IThemeNamespace } from '@xcritical/theme';
import {
  IPrefixProps,
  ISelectBaseTheme,
  IThemeProps,
  selectThemeNamespace,
} from '@xcritical/select';

import { classNameWithPrefix } from '@crm-framework/utils';

import defaultTheme from '@ams-package/theme';

export const getSelectStyles = (
  theme: IThemeNamespace<ISelectBaseTheme> = {},
  appearanceName: string,
  baseAppearance: string,
  propertyPath?: string | string[]
): ISelectBaseTheme | any => {
  const func = getAppearanceTheme(selectThemeNamespace, defaultTheme);

  return func(theme, appearanceName, propertyPath, baseAppearance);
};

const blockName = 'option';

export const OptionWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'wrapper'),
})<IThemeProps>`
  ${({ theme, appearance = 'default', baseAppearance = 'default' }) =>
    getSelectStyles(theme, appearance, baseAppearance, 'optionWrapper')}
`;

export const SelectedIconWrapper = styled.span.attrs({
  className: classNameWithPrefix(blockName, 'selected-icon-wrapper'),
})<IThemeProps>`
  direction: inherit;
  align-items: center;
  display: flex;
  flex-shrink: 0;
  ${({ theme, appearance = 'default', baseAppearance = 'default' }) =>
    getSelectStyles(theme, appearance, baseAppearance, 'selectedIconWrapper')}
`;

const LabelPrefixPostfixBase = styled.span.attrs({
  className: classNameWithPrefix(blockName, 'label-prefix-postfix'),
})<IPrefixProps>`
  direction: inherit;
  align-items: center;
  display: flex;
  flex-shrink: 0;
`;

export const LabelPrefix = styled(LabelPrefixPostfixBase).attrs({
  className: classNameWithPrefix(blockName, 'label-prefix'),
})`
  ${({ theme, appearance = 'default', baseAppearance = 'default' }) =>
    getSelectStyles(theme, appearance, baseAppearance, 'prefix')}
`;

export const LabelPostfix = styled(LabelPrefixPostfixBase).attrs({
  className: classNameWithPrefix(blockName, 'label-postfix'),
})`
  ${({ theme, appearance = 'default', baseAppearance = 'default' }) =>
    getSelectStyles(theme, appearance, baseAppearance, 'postfix')}
`;
