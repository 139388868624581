import React from 'react';

export const Payouts: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none">
    <path
      d="M14.7588 4.71429V8.14286H13.3306V4.71429C13.3306 4.47068 13.1021 4.35338 12.9497 4.35338C12.9021 4.35338 12.8545 4.36241 12.8069 4.38045L5.25655 7.0782C4.75192 7.25865 4.42819 7.70978 4.42819 8.22406V8.82857C3.56176 9.44211 3 10.4256 3 11.5353V8.22406C3 7.15038 3.69505 6.19399 4.75192 5.81504L12.3118 3.10827C12.5213 3.03609 12.7403 3 12.9497 3C13.9019 3 14.7588 3.73083 14.7588 4.71429Z"
      stroke="transparent"
    />
    <path
      d="M21.0902 14.2331V15.1354C21.0902 15.379 20.8903 15.5775 20.6237 15.5865H19.2336C18.729 15.5865 18.2719 15.2346 18.2338 14.7654C18.2053 14.4857 18.3195 14.2241 18.51 14.0436C18.6813 13.8722 18.9194 13.782 19.1765 13.782H20.6142C20.8903 13.791 21.0902 13.9895 21.0902 14.2331Z"
      stroke="transparent"
    />
    <path
      d="M19.1672 12.8346H20.1383C20.662 12.8346 21.0905 12.4285 21.0905 11.9323V11.5353C21.0905 9.66763 19.4814 8.14282 17.5104 8.14282H6.58001C5.7707 8.14282 5.02803 8.39545 4.42819 8.82854C3.56176 9.44207 3 10.4255 3 11.5353V17.6075C3 19.4752 4.6091 21 6.58001 21H17.5104C19.4814 21 21.0905 19.4752 21.0905 17.6075V17.4361C21.0905 16.9398 20.662 16.5338 20.1383 16.5338H19.31C18.3959 16.5338 17.52 16.0015 17.2819 15.1624C17.082 14.4767 17.32 13.818 17.7961 13.3759C18.1484 13.033 18.634 12.8346 19.1672 12.8346ZM13.9495 12.6541H7.28458C6.89421 12.6541 6.57048 12.3473 6.57048 11.9774C6.57048 11.6075 6.89421 11.3007 7.28458 11.3007H13.9495C14.3399 11.3007 14.6636 11.6075 14.6636 11.9774C14.6636 12.3473 14.3399 12.6541 13.9495 12.6541Z"
      stroke="transparent"
    />
  </svg>
);
