import React from 'react';

export const Offers: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0651 3.86603C16.0649 3.86583 16.0653 3.86624 16.0651 3.86603L20.6065 8.38745C22.7567 10.5492 22.7588 14.043 20.6051 16.1967L16.2151 20.5867L16.2137 20.5881C14.0519 22.7383 10.5581 22.7404 8.40443 20.5867L3.87443 16.0567C2.77545 14.9577 2.18616 13.4431 2.2555 11.8831L2.25562 11.8804L2.4957 6.87872C2.61503 4.50397 4.51323 2.60517 6.89986 2.49718C6.90025 2.49716 6.90065 2.49714 6.90104 2.49712L11.8956 2.25739C11.8962 2.25736 11.8967 2.25733 11.8973 2.2573C13.4515 2.17658 14.9673 2.76857 16.0651 3.86603ZM15.0044 4.92669C14.2027 4.12493 13.0988 3.69658 11.9739 3.75534L11.9707 3.7555L6.97072 3.9955L6.96847 3.99561C5.35592 4.06817 4.07487 5.34873 3.99385 6.95323C3.99384 6.95349 3.99386 6.95297 3.99385 6.95323L3.75402 11.9497C3.754 11.9501 3.75398 11.9505 3.75396 11.9509C3.70367 13.0904 4.13436 14.1953 4.93509 14.996L9.46509 19.526C11.0312 21.0921 13.5769 21.0944 15.1552 19.5253C15.1549 19.5255 15.1554 19.5251 15.1552 19.5253L19.5444 15.136C21.1103 13.5701 21.1128 11.0248 19.5442 9.44649C19.5438 9.44608 19.5434 9.44568 19.543 9.44527L15.0056 4.92786L15.0044 4.92669Z"
      stroke="transparent"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.73438 7.97632C8.76788 7.97632 7.98438 8.75982 7.98438 9.72632C7.98438 10.6928 8.76788 11.4763 9.73438 11.4763C10.7009 11.4763 11.4844 10.6928 11.4844 9.72632C11.4844 8.75982 10.7009 7.97632 9.73438 7.97632ZM6.48438 9.72632C6.48438 7.93139 7.93945 6.47632 9.73438 6.47632C11.5293 6.47632 12.9844 7.93139 12.9844 9.72632C12.9844 11.5212 11.5293 12.9763 9.73438 12.9763C7.93945 12.9763 6.48438 11.5212 6.48438 9.72632Z"
      stroke="transparent"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.7647 12.696C18.0576 12.9889 18.0576 13.4638 17.7647 13.7566L13.7647 17.7566C13.4718 18.0495 12.9969 18.0495 12.704 17.7566C12.4112 17.4638 12.4112 16.9889 12.704 16.696L16.704 12.696C16.9969 12.4031 17.4718 12.4031 17.7647 12.696Z"
      stroke="transparent"
    />
  </svg>
);
