import React, { memo, useCallback, useState, useEffect } from 'react';

import { TabsWrapper, HeadersWrapper, ContentWrapper } from './styles';
import { TabHeader } from './TabHeader';
import { ITabsProps } from './types';

export const Tabs: React.FC<ITabsProps> = memo(
  ({ tabPanels, defaultActiveIndex = 0, onChange, Wrapper, HeaderWrapper }) => {
    const [activeTabIndex, setActiveTabIndex] = useState(defaultActiveIndex);

    const onClickHandler = useCallback(
      (newActiveTabIndex: number) => {
        if (newActiveTabIndex === activeTabIndex) return;

        setActiveTabIndex(newActiveTabIndex);

        const newActiveTab = tabPanels[newActiveTabIndex];

        onChange?.(newActiveTab.key, newActiveTabIndex, newActiveTab);
      },
      [onChange, tabPanels, activeTabIndex]
    );

    useEffect(() => {
      if (defaultActiveIndex !== activeTabIndex)
        setActiveTabIndex(defaultActiveIndex || 0);
    }, [defaultActiveIndex]);

    const WrapperComponent = Wrapper ?? TabsWrapper;
    const HeadersWrapperComponent = HeaderWrapper ?? HeadersWrapper;

    return (
      <WrapperComponent className="tabs">
        <HeadersWrapperComponent className="tabs-headers">
          {tabPanels.map(({ header, key, disabled, HeaderItem }, tabIndex) => (
            <TabHeader
              key={key}
              HeaderItem={HeaderItem}
              tabIndex={tabIndex}
              isActive={activeTabIndex === tabIndex}
              disabled={disabled}
              onClick={onClickHandler}>
              {header}
            </TabHeader>
          ))}
        </HeadersWrapperComponent>
        <ContentWrapper className="tabs-content">
          {tabPanels[activeTabIndex].content}
        </ContentWrapper>
      </WrapperComponent>
    );
  }
);
