import React, { useCallback } from 'react';
import { components, MenuListProps } from 'react-select';
import { useDispatch } from 'react-redux';

import { useDictionary } from '@crm-framework/dictionaries';

import { Button } from '@ams-package/button';

import { paymentEditorActions } from '../../store';

import {
  ButtonWrapper,
  Divider,
  MenuListWrapper,
  ButtonContentWrapper,
} from './styled';

const SIZE_FOR_TWO_COLUMNS = 400;

export const MenuList = ({ children, ...props }: MenuListProps) => {
  const dispatch = useDispatch();
  const { formName, statusMessageKey } = props.selectProps as {
    formName?: string;
    statusMessageKey?: string;
  };
  const ref = React.useRef<HTMLDivElement | null>(null);
  const menuWidth = ref.current?.clientWidth ?? 0;
  const paymentMethods = useDictionary('paymentMethods');

  const onClick = useCallback(() => {
    dispatch(
      paymentEditorActions.addOrEdit({
        formName,
        statusMessageKey,
      })
    );
  }, [formName]);

  return (
    <components.MenuList {...(props as MenuListProps)}>
      <ButtonWrapper ref={ref}>
        <Button shouldFitContent onClick={onClick} title="Add payment method">
          <ButtonContentWrapper width={menuWidth}>
            Add payment method
          </ButtonContentWrapper>
        </Button>
      </ButtonWrapper>
      {paymentMethods.length ? (
        <>
          <Divider />
          <MenuListWrapper columns={menuWidth > SIZE_FOR_TWO_COLUMNS ? 2 : 1}>
            {children}
          </MenuListWrapper>
        </>
      ) : null}
    </components.MenuList>
  );
};
