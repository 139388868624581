import React from 'react';

export const InformationIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.0415039 9.00008C0.0415039 4.07157 4.07133 0.041748 8.99984 0.041748C13.9283 0.041748 17.9582 4.07157 17.9582 9.00008C17.9582 13.9286 13.9283 17.9584 8.99984 17.9584C4.07133 17.9584 0.0415039 13.9286 0.0415039 9.00008ZM8.99984 1.29175C4.76168 1.29175 1.2915 4.76193 1.2915 9.00008C1.2915 13.2382 4.76168 16.7084 8.99984 16.7084C13.238 16.7084 16.7082 13.2382 16.7082 9.00008C16.7082 4.76193 13.238 1.29175 8.99984 1.29175Z"
      stroke="transparent"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 5.04175C9.34518 5.04175 9.625 5.32157 9.625 5.66675V9.83341C9.625 10.1786 9.34518 10.4584 9 10.4584C8.65482 10.4584 8.375 10.1786 8.375 9.83341V5.66675C8.375 5.32157 8.65482 5.04175 9 5.04175Z"
      stroke="transparent"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.16211 12.3333C8.16211 11.8731 8.53521 11.5 8.99544 11.5H9.00293C9.46316 11.5 9.83626 11.8731 9.83626 12.3333C9.83626 12.7936 9.46316 13.1667 9.00293 13.1667H8.99544C8.53521 13.1667 8.16211 12.7936 8.16211 12.3333Z"
      stroke="transparent"
    />
  </svg>
);
