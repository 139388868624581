import React, { useCallback } from 'react';
import { components, OptionProps } from 'react-select';

import { IOptionItem } from '@xcritical/select';

import { Checkbox } from '@ams-package/checkbox';
import { ICheckboxProps } from '@ams-package/checkbox/types';

type RadioOptionType = OptionProps<IOptionItem> & {
  checkboxOptionProps?: ICheckboxProps;
};

export const CheckboxOption: React.FC<RadioOptionType> = ({
  checkboxOptionProps = {},
  ...optionProps
}) => {
  const {
    isSelected,
    selectOption,
    data: { label },
  } = optionProps;

  const onChangeHandler = useCallback(() => {
    selectOption(optionProps.data);
  }, [optionProps, selectOption]);

  return (
    <components.Option {...(optionProps as OptionProps)}>
      <Checkbox
        checked={isSelected}
        label={label}
        onChange={onChangeHandler}
        appearance={isSelected ? 'filter-checkbox-checked' : 'filter-checkbox'}
        {...checkboxOptionProps}
      />
    </components.Option>
  );
};
