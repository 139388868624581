import React, { useCallback } from 'react';

import { ISelectOption, MultiFilterSelect } from '@ams-package/select';

import { MultiSelectFilterType } from '../types';
import { FilterController } from '../filter-controller';

export const MultiSelectFilter: React.FC<MultiSelectFilterType> = (filter) => {
  const { onChange, placeholder, options, value, isClearable, ...rest } =
    filter;

  const onChangeHandler = useCallback(
    (selectedOptions: ISelectOption) => {
      const $value = value ?? [];

      const exist = ($value as ISelectOption[]).find(
        ({ value: _ }) => _ === selectedOptions.value
      );

      if (!exist) {
        onChange?.([...($value as ISelectOption[]), selectedOptions]);
      } else {
        onChange?.(
          ($value as ISelectOption[]).filter(
            (option) => option.value !== selectedOptions.value
          )
        );
      }
    },
    [onChange]
  );
  const filterValue =
    (value as ISelectOption[] | undefined)?.map((o) => o.label).join(', ') ||
    '';

  return (
    <FilterController
      value={filterValue}
      filter={filter}
      isClearable={!!isClearable}>
      <MultiFilterSelect
        value={value ?? []}
        label={placeholder}
        menuIsOpen
        onChange={onChangeHandler}
        options={options}
        {...rest}
      />
    </FilterController>
  );
};
