import wallet from './images/wallet.svg';
import logoutIcon from './images/logoutIcon.svg';
import telegram from './images/telegram.svg';
import skype from './images/skype.png';
import close from './images/close.svg';
import visa from './images/visa.svg';
import webMoney from './images/web-money.svg';
import qiwi from './images/qiwi.svg';
import iqOption from './images/iqOption.png';
import Skrill from './images/skrill.svg';
import Neteller from './images/neteller.svg';
import USDC from './images/usdc.svg';
import USDT from './images/usdt.svg';
import BTC from './images/btc.svg';
import WireTransfer from './images/wiretransfer.png';
import managerAvatar from './images/managerAvatar.png';
export * as svgComponents from './svg-components';

export const defaultImages = {
  wallet,
  logoutIcon,
  telegram,
  skype,
  close,
  visa,
  qiwi,
  webMoney,
  iqOption,
  managerAvatar,

  // payment method icons
  WireTransfer,
  Neteller,
  Skrill,
  USDC,
  USDT,
  BTC,
};
