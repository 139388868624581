import React from 'react';
import { components, OptionProps } from 'react-select';

import { IOptionItem } from '@xcritical/select';

import { MethodNumber } from './styled';

export const Option = (props: OptionProps<IOptionItem>) => {
  const {
    data: { postfix, prefix },
  } = props;

  return (
    <components.Option {...(props as OptionProps)}>
      {prefix}
      {postfix && <MethodNumber>{postfix}</MethodNumber>}
    </components.Option>
  );
};
