import { put } from 'redux-saga/effects';

import { xcriticalFormError } from '@xcritical/forms';
import { notify } from '@xcritical/notification';
import { xcriticalModalClose, xcriticalModalOpen } from '@xcritical/modal';

import { IServerError } from '@crm-framework/utils';

import { router, UnauthorizedPathNames } from '@ams-package/router';
import { StatusModalTypes } from '@ams-package/status-message';
import { ButtonsTypes } from '@ams-package/modal';

export const getErrorTextFromArray = (arr: string[]): string =>
  arr.reduce((errorText, item) => errorText + item, '') ?? '';

type HandleFormErrorsParamsType = {
  error: IServerError | Error | string;
  formName?: string;
  statusModalName?: string;
};

export function* handleServerError({
  error,
  formName,
  statusModalName,
}: HandleFormErrorsParamsType) {
  try {
    if (typeof error === 'string') {
      notify.error(error);

      return;
    }

    if ((error as IServerError).status === 400 && !formName) {
      const { text } = error as IServerError;

      if (text) {
        const errorText = JSON.parse(text);
        notify.error(errorText.error);

        return;
      }
    }

    if ((error as IServerError).status === 500) {
      notify.error('Server error');

      return;
    }

    if ((error as IServerError).status === 401) {
      yield handleUnauthorized();

      return;
    }

    if ((error as IServerError).status === 504 && statusModalName) {
      yield put(
        xcriticalModalOpen(statusModalName, {
          message: {
            type: StatusModalTypes.Failure,
            title: 'Connection error',
            description:
              'No internet connection. Check your connection and try again',
          },
          buttons: {
            type: ButtonsTypes.primary,
            title: 'Try again',
            callback: (dispatcher) => {
              dispatcher(xcriticalModalClose(statusModalName));
            },
          },
        })
      );

      return;
    }

    const errorMessage = JSON.parse(
      (error as Error).message ?? (error as IServerError).text
    );

    if (formName) {
      yield put(
        xcriticalFormError(
          formName,
          Object.keys(errorMessage).reduce(
            (errors, key) => ({
              ...errors,
              [key]: getErrorTextFromArray(errorMessage[key]),
            }),
            {}
          )
        )
      );

      return;
    }

    notify.error(
      'Something went wrong. Please contact with your administrator'
    );
  } catch (e) {
    console.error(e);
    notify.error(
      'Something went wrong. Please contact with your administrator'
    );
  }
}

function* handleUnauthorized() {
  try {
    const pathName = window.location.pathname;
    const isUnauthorizedPage = Object.values(UnauthorizedPathNames).includes(
      pathName
    );
    const returnUrl = encodeURIComponent(pathName + window.location.search);

    if (!isUnauthorizedPage) {
      yield router.navigate(`/login?returnUrl=${returnUrl}`);
    }
  } catch (e) {
    console.error(e);
  }
}
