import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import Button from '@xcritical/button';

import { StatusMessage, StatusModalTypes } from '@ams-package/status-message';

import { paymentEditorActions } from '../../store';

import {
  ButtonsWrapper,
  ConfirmPaymentMethodWrapper,
  StatusMessageWrapper,
} from './styled';

export const ClosePaymentMethod: React.FC<{ onClose: () => void }> = ({
  onClose,
}) => {
  const dispatch = useDispatch();

  const handleReturn = useCallback(() => {
    onClose();
  }, []);

  const handleCancel = useCallback(() => {
    onClose();
    dispatch(paymentEditorActions.close());
  }, []);

  return (
    <ConfirmPaymentMethodWrapper>
      <StatusMessageWrapper>
        <StatusMessage
          type={StatusModalTypes.Failure}
          title="You have unsaved changes"
          description="Are you sure you want to cancel all changes?"
        />
      </StatusMessageWrapper>

      <ButtonsWrapper>
        <Button shouldFitContent onClick={handleReturn}>
          Return
        </Button>
        <Button
          shouldFitContent
          appearance="lightSecondary"
          onClick={handleCancel}>
          Cancel
        </Button>
      </ButtonsWrapper>
    </ConfirmPaymentMethodWrapper>
  );
};
