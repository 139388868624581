import React, { MouseEventHandler } from 'react';

import { BaseButton } from '@ams-package/button';
import { ChevronDown, ChevronUp, ClearIcon } from '@ams-package/select';

import { ValueWrapper, Placeholder, IconWrapper } from './styled';

export type ButtonWithValueProps = {
  value: string;
  isOpen: boolean;
  prefix?: string;
  placeholder?: string;
  isClearable?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onClear: MouseEventHandler<SVGSVGElement>;
};

export const ButtonWithValue: React.FC<ButtonWithValueProps> = ({
  value,
  isOpen,
  onClear,
  prefix = '',
  placeholder = '',
  onClick,
  isClearable = true,
}) => {
  const onClearHandler = (e) => {
    e.stopPropagation();

    onClear(e);
  };

  return (
    <BaseButton
      baseAppearance="secondary"
      appearance="buttonWithValue"
      shouldFitContainer
      onClick={onClick}
      postfix={
        isClearable ? (
          <IconWrapper>
            {value && <ClearIcon onClick={onClearHandler} />}
            {!value && isOpen && <ChevronUp />}
            {!value && !isOpen && <ChevronDown />}
          </IconWrapper>
        ) : (
          <IconWrapper>
            {isOpen && <ChevronUp />}
            {!isOpen && <ChevronDown />}
          </IconWrapper>
        )
      }>
      {value ? (
        <ValueWrapper title={value}>
          {prefix ? `${prefix} ${value}` : value}
        </ValueWrapper>
      ) : (
        <Placeholder>{placeholder}</Placeholder>
      )}
    </BaseButton>
  );
};
