import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { FilterType } from '../types';
import { FilterButton, FilterButtonProps } from '../filter-button';
import { FilterDropdown } from '../filter-dropdown';

export type FilterControllerProps = {
  value: string;
  filter: FilterType;
  onClear?: () => void;
  isClearable?: boolean;
  FilterButtonComponent?: React.FC<FilterButtonProps>;
};

export const FilterController: React.FC<FilterControllerProps> = ({
  value,
  filter,
  isClearable,
  onClear,
  children,
  FilterButtonComponent,
}) => {
  const dispatch = useDispatch();
  const { onChange, name, placeholder, prefix } = filter;
  const [isOpen, setIsOpen] = useState(false);

  const onClearHandler = useCallback(() => {
    onChange?.(undefined as any);
    onClear?.();
  }, [onChange, name, dispatch, filter, onClear]);

  const showDropdownList = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const hideDropdownList = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const ButtonComponent = FilterButtonComponent ?? FilterButton;

  return (
    <FilterDropdown
      isOpen={isOpen}
      onClose={hideDropdownList}
      target={
        <ButtonComponent
          value={value}
          prefix={prefix ?? ''}
          isOpen={isOpen}
          selected={isOpen}
          placeholder={placeholder ?? 'Select...'}
          onClick={showDropdownList}
          onClear={onClearHandler}
          isClearable={!!isClearable}
        />
      }>
      {children}
    </FilterDropdown>
  );
};
