import { css } from 'styled-components';

import {
  body1MCssVariable,
  body1RCssVariable,
  body1SBCssVariable,
  body2MCssVariable,
  body2RCssVariable,
  body2SBCssVariable,
  body3RCssVariable,
  body3SBCssVariable,
  h1CssVariable,
  h2CssVariable,
  h3CssVariable,
  h4CssVariable,
} from './variables';

export const globalFontVariables = css`
  ${h1CssVariable}
  ${h2CssVariable}
  ${h3CssVariable}
  ${h4CssVariable}
  ${body1MCssVariable}
  ${body1RCssVariable}
  ${body2MCssVariable}
  ${body2RCssVariable}
  ${body1SBCssVariable}
  ${body2SBCssVariable}
  ${body3SBCssVariable}
  ${body3RCssVariable}
`;

export const h1Style = css`
  font-size: var(--h1-font-size);
  font-family: var(--h1-font-family);
  font-weight: var(--h1-font-weight);
  line-height: var(--h1-line-height);
`;

export const h2Style = css`
  font-size: var(--h2-font-size);
  font-family: var(--h2-font-family);
  font-weight: var(--h2-font-weight);
  line-height: var(--h2-line-height);
`;

export const h3Style = css`
  font-size: var(--h3-font-size);
  font-family: var(--h3-font-family);
  font-weight: var(--h3-font-weight);
  line-height: var(--h3-line-height);
`;

export const h4Style = css`
  font-size: var(--h4-font-size);
  font-family: var(--h4-font-family);
  font-weight: var(--h4-font-weight);
  line-height: var(--h4-line-height);
`;

export const body1RStyle = css`
  font-size: var(--body-1r-font-size);
  font-family: var(--body-1r-font-family);
  font-weight: var(--body-1r-font-weight);
  line-height: var(--body-1r-line-height);
`;

export const body1MStyle = css`
  font-size: var(--body-1m-font-size);
  font-family: var(--body-1m-font-family);
  font-weight: var(--body-1m-font-weight);
  line-height: var(--body-1m-line-height);
`;

export const body2MStyle = css`
  font-size: var(--body-2m-font-size);
  font-family: var(--body-2m-font-family);
  font-weight: var(--body-2m-font-weight);
  line-height: var(--body-2m-line-height);
`;

export const body2RStyle = css`
  font-size: var(--body-2r-font-size);
  font-family: var(--body-2r-font-family);
  font-weight: var(--body-2r-font-weight);
  line-height: var(--body-2r-line-height);
`;

export const body2SBStyle = css`
  font-size: var(--body-2sb-font-size);
  font-family: var(--body-2sb-font-family);
  font-weight: var(--body-2sb-font-weight);
  line-height: var(--body-2sb-line-height);
`;

export const body3SBStyle = css`
  font-size: var(--body-3sb-font-size);
  font-family: var(--body-3sb-font-family);
  font-weight: var(--body-3sb-font-weight);
  line-height: var(--body-3sb-line-height);
`;

export const body3RStyle = css`
  font-size: var(--body-3r-font-size);
  font-family: var(--body-3r-font-family);
  font-weight: var(--body-3r-font-weight);
  line-height: var(--body-3r-line-height);
`;

export const generateFontStyle = (
  fontStyle:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'body-1m'
    | 'body-1r'
    | 'body-1sb'
    | 'body-2sb'
    | 'body-2m'
    | 'body-2r'
    | 'body-3r'
    | 'body-3sb'
) => ({
  fontSize: `var(--${fontStyle}-font-size)`,
  fontFamily: `var(--${fontStyle}-font-family)`,
  fontWeight: `var(--${fontStyle}-font-weight)`,
  lineHeight: `var(--${fontStyle}-line-height)`,
});
