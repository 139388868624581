import React, { memo } from 'react';

import { Spinner, Wrapper } from './styled';
import { ILoader } from './types';

export const Loader: React.FC<ILoader> = memo(
  ({
    fixed = false,
    absolute = false,
    center = false,
    bgBlur = false,
    size = 40,
    zIndex = 'auto',
  }) => (
    <Wrapper
      zIndex={zIndex}
      fixed={fixed}
      absolute={absolute}
      center={center}
      bgBlur={bgBlur}>
      <Spinner size={size} />
    </Wrapper>
  )
);
