import styled from 'styled-components';

import { generateFontStyle } from '@ams-package/theme';

export const HeaderItem = styled.div<{ isActive: boolean; disabled: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  ${generateFontStyle('body-1m')}
  color: ${({ isActive }) =>
    isActive ? 'var(--hover-tabs-header-color)' : 'var(--tabs-header-color)'};
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  border-bottom: 1px solid
    ${({ isActive }) =>
      isActive ? 'var(--hover-tabs-header-color)' : 'transparent'};
  padding-bottom: 8px;
  margin-bottom: -1px;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
    fill: ${({ isActive }) =>
      isActive ? 'var(--hover-tabs-header-color)' : 'var(--tabs-header-color)'};
  }

  &:hover {
    ${({ disabled }) =>
      !disabled
        ? `
    color: var(--hover-tabs-header-color);

    svg {
      fill: var(--hover-tabs-header-color);
    }`
        : `
      cursor: not-allowed;
    `}
  }
`;

export const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 0 0 0;
  flex: auto;
  gap: 16px;
`;

export const HeadersWrapper = styled.div`
  display: flex;
  gap: var(--tabs-headers-wrapper-gap);
  border-bottom: 1px solid var(--border-color);
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: auto;
  gap: 16px;
`;
