import React from 'react';
import { styled } from 'styled-components';

const ErrorTextWrapper = styled.div`
  color: var(--danger-text-color);
  text-align: right;
`;

const ContainerWithErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ErrorWrapper: React.FC<{ content?: string }> = ({
  content,
  children,
}) => (
  <ContainerWithErrorWrapper>
    {children}
    <ErrorTextWrapper>{content}</ErrorTextWrapper>
  </ContainerWithErrorWrapper>
);
