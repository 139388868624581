import { put } from 'redux-saga/effects';
import { call, takeEvery } from 'typed-redux-saga';

import { loadAndSaveDictionariesByName } from '@ams-package/dictionaries';
import { handleServerError } from '@ams-package/utils';
import { router, UnauthorizedPathNames } from '@ams-package/router';

import { appActions } from './reducer';
import {
  apiGetAppConfig,
  apiGetPayoutLimits,
  apiGetUser,
  apiGetUserBalance,
} from './api';
import { IUser } from './types';

export function* watchApp() {
  yield takeEvery(appActions.startInit, handleStartInit);
  yield takeEvery(appActions.getUser, handleGetUser);
}

function* handleStartInit() {
  try {
    const appConfig = yield* call(apiGetAppConfig);

    yield put(appActions.setConfig(appConfig));

    const user = yield call(handleGetUser);

    if (user) {
      const pathName = window.location.pathname;
      const needToRedirect = Object.values(UnauthorizedPathNames).includes(
        pathName
      );

      if (needToRedirect) {
        yield router.navigate('/');
      }

      yield* call(handleGetLimits);
      yield* loadAndSaveDictionariesByName([
        'deviceType',
        // 'devicePlatform',
        'country',
      ]);
    }
  } catch (error) {
    yield call(handleServerError, { error });
  } finally {
    yield put(appActions.finishInit());

    // TODO: stub for testing
    // yield* delay(1000);
    // yield put(paymentEditorActions.edit(20128));
  }
}

export function* handleGetUser(): Generator<any, any, IUser | null> {
  try {
    const user = yield* call(apiGetUser);

    yield put(appActions.setUser(user));

    return user;
  } catch (error) {
    yield call(handleServerError, { error });

    return null;
  }
}

export function* handleUpdateBalanceUser(): Generator<any, any, IUser | null> {
  try {
    const balance = yield* call(apiGetUserBalance);

    yield put(appActions.updateUserBalance(balance));

    return balance;
  } catch (error) {
    yield call(handleServerError, { error });

    return null;
  }
}

export function* handleGetLimits(): Generator<any, any, IUser | null> {
  try {
    const limits = yield* call(apiGetPayoutLimits);

    yield put(appActions.setLimits(limits));

    return limits;
  } catch (error) {
    yield call(handleServerError, { error });

    return null;
  }
}
