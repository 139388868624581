import styled from 'styled-components';

import { ISkeleton } from './types';

export const StyledDiv = styled.div.attrs({
  className: 'skeleton',
})<ISkeleton>`
  font-family: sans-serif;
  color: transparent;
  border-radius: ${({ radius }) => (radius ? `${radius}px` : '12px')};
  background-color: ${({ color }) => color ?? 'var(--skeleton-color)'};
  width: ${({ width }) =>
    typeof width === 'number' ? `${width}px` : width || 'auto'};
  height: ${({ height }) =>
    typeof height === 'number' ? `${height}px` : height || 'auto'};
  ${({ variant }) => variant === 'circle' && 'border-radius: 50%'};

  animation: pulse 1.5s ease-in-out 0.5s infinite;

  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
       opacity: 1;
    }
`;
