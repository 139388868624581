import { authorizedWretch } from '@ams-package/utils';

import {
  AmsDictionaryType,
  DictionaryTypes,
  LoadDictionaryMethods,
  LoadDictionaryOptionsType,
  LoadObjectDictionaryType,
} from './types';
import {
  dictionaryIdMapper,
  dictionaryNameMapper,
  getSortedDictionary,
  mapKeyValueDictionary,
  mapPayoutStatusDictionary,
} from './mappers';
import {
  IBaseDictionary,
  IPaymentMethodsModel,
  IPayoutPaymentMethodConfigurationModel,
  ITextModel,
} from './models';

function* loadDictionary<T, K = T>(
  url: string,
  {
    keyForId,
    keyForName,
    order,
    customMapper,
  }: LoadDictionaryOptionsType<T, K> = {}
) {
  let dictionary: T[] = yield authorizedWretch(url).get().json();

  if (customMapper) dictionary = customMapper(dictionary as unknown as K[]);

  if (keyForId) dictionary = dictionaryIdMapper<T>(dictionary, keyForId);

  if (keyForName) dictionary = dictionaryNameMapper<T>(dictionary, keyForName);

  if (order) dictionary = getSortedDictionary<T>(dictionary, order);

  return dictionary;
}

function* loadObjectDictionary<T, K = T>(
  url: string,
  { customMapper }: LoadObjectDictionaryType<T, K> = {}
) {
  const dictionary: T = yield authorizedWretch(url).get().json();

  if (customMapper) {
    return customMapper(dictionary as unknown as T);
  }

  return dictionary;
}

export const loadDictionaryMethods: LoadDictionaryMethods = {
  paymentMethods: () =>
    loadDictionary<IPaymentMethodsModel>('/api/payment-methods', {
      customMapper: (dictionary) =>
        dictionary.map((item) => ({
          ...item,
          code: item.name,
          name: item.title,
        })),
    }),
  payoutPaymentMethods: () =>
    loadDictionary<IPayoutPaymentMethodConfigurationModel>(
      '/api/dictionaries/payout-payment-methods',
      {
        keyForId: 'name',
        keyForName: 'title',
      }
    ),
  texts: () =>
    loadObjectDictionary<ITextModel[], Record<string, string>>('/api/texts', {
      customMapper: (dictionary) =>
        dictionary.reduce(
          (acc, item) => ({
            ...acc,
            [item.key]: item.value,
          }),
          {}
        ),
    }),
  devicePlatform: () =>
    loadDictionary<IBaseDictionary, AmsDictionaryType>(
      `/api/dictionaries?type=${DictionaryTypes.DevicePlatform}`,
      {
        customMapper: mapKeyValueDictionary(DictionaryTypes.DevicePlatform),
      }
    ),
  country: () =>
    loadDictionary<IBaseDictionary, AmsDictionaryType>(
      `/api/dictionaries?type=${DictionaryTypes.Country}`,
      {
        customMapper: mapKeyValueDictionary(DictionaryTypes.Country),
      }
    ),
  payoutType: () =>
    loadDictionary<IBaseDictionary, AmsDictionaryType>(
      `/api/dictionaries?type=${DictionaryTypes.PayoutType}`,
      {
        customMapper: mapKeyValueDictionary(DictionaryTypes.PayoutType),
      }
    ),
  deviceType: () =>
    loadDictionary<IBaseDictionary, AmsDictionaryType>(
      `/api/dictionaries?type=${DictionaryTypes.DeviceType}`,
      {
        customMapper: mapKeyValueDictionary(DictionaryTypes.DeviceType),
      }
    ),
  payoutStatus: () =>
    loadDictionary<IBaseDictionary, AmsDictionaryType>(
      `/api/dictionaries?type=${DictionaryTypes.PayoutStatus}`,
      {
        // TODO: return custom mapper after Backend will finish new dictionary
        // customMapper: mapKeyValueDictionary(DictionaryTypes.PayoutStatus),
        customMapper: mapPayoutStatusDictionary(DictionaryTypes.PayoutStatus),
      }
    ),
};
