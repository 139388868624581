import { colors } from '@ams-package/theme';

import { IDatePickerTheme } from './interfaces';

export const datePickerThemeNamespace = '@xcritical\\date-picker';

const sizes = {
  DEFAULT_FONT_SIZE: 16,
  DEFAULT_BORDER_RADIUS: 5,
  DAY_WIDTH: '38px',
  DAY_HEIGHT: '38px',
  DAY_MARGIN: '8px',
};

export const defaultDatePickerTheme: IDatePickerTheme = {
  // DatePicker
  datePickerWrapper: {
    width: '100%',
  },
  datePicker: {
    border: 'none',
    fontSize: '12px',
    borderRadius: '8px',
    background: colors.SECONDARY_COLOR,
    boxShadow: '2px 16px 19px 0px rgba(0, 0, 0, 0.09)',
  },

  // Header
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 0,
  },
  headerContainer: {
    textAlign: 'center',
    backgroundColor: 'white',
    borderBottom: '1px solid transparent',
    paddingTop: '8px',
    position: 'relative',
    background: colors.SECONDARY_COLOR,
  },

  navigationArrows: {
    marginBottom: 16,
  },

  // Month
  month: {
    margin: 0,
  },
  currentMonth: {
    color: colors.LINK_COLOR,
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 600,
  },
  monthContainer: {
    backgroundColor: 'white',
    padding: '12px',
    background: colors.SECONDARY_COLOR,
    borderRadius: '8px',
  },

  week: {
    display: 'flex',
  },

  // Day
  day: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    color: colors.LIGHT_COLOR,
    fontFamily: 'Inter',
    width: sizes.DAY_WIDTH,
    height: sizes.DAY_HEIGHT,
    lineHeight: sizes.DAY_HEIGHT,
    textAlign: 'center',
    margin: 0,
    padding: sizes.DAY_MARGIN,
    borderRadius: '4px',
    fontSize: '16px',
    hover: {
      color: `${colors.PRIMARY_COLOR} !important`,
      background: 'transparent',
      opacity: 1,
    },
    today: {
      fontWeight: 'normal',
      background: 'transparent !important',
      color: `${colors.LIGHT_COLOR} !important`,
    },
    selected: {
      background: colors.PRIMARY_COLOR,
      color: colors.DARK_TEXT_COLOR,
      borderRadius: sizes.DAY_WIDTH,
    },
    disabled: {
      cursor: 'not-allowed',
      boxShadow: 'none !important',
    },
    inRange: {
      background: 'rgb(170 255 105 / 20%) !important',
      color: colors.LIGHT_COLOR,
      borderRadius: 0,
    },
    rangeStart: {
      borderRadius: '30px 0 0 30px !important',
    },
    rangeEnd: {
      borderRadius: '0 30px 30px 0 !important',
    },
    dayButton: {
      position: 'absolute',
      width: sizes.DAY_WIDTH,
      height: sizes.DAY_HEIGHT,
      background: `${colors.PRIMARY_COLOR} !important`,
      color: `${colors.DARK_TEXT_COLOR} !important`,
      borderRadius: `${sizes.DAY_WIDTH} !important`,
      opacity: '1 !important',
    },
  },
  dayOutsideMonth: {
    visibility: 'hidden',
  },

  // Days of week
  daysOfWeekContainer: {
    marginBottom: 0,
    whiteSpace: 'nowrap',
    display: 'flex',
  },
  dayOfWeek: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: colors.HOVER_SECONDARY_COLOR,
    fontFamily: 'Inter',
    width: sizes.DAY_WIDTH,
    height: 16,
    lineHeight: sizes.DAY_HEIGHT,
    textAlign: 'center',
    fontSize: 12,
    margin: 0,
    fontWeight: 400,
    padding: `0 ${sizes.DAY_MARGIN}`,
    textTransform: 'uppercase',
  },

  // Triangle
  triangle: {
    display: 'none',
  },

  // Popper
  popper: {
    zIndex: 1,
  },

  // Selects
  headerSelects: {
    button: {
      paddingBottom: '0px',
      paddingLeft: '0px',
      paddingRight: '0px',
      paddingTop: '0px',
      border: 'none',
      cursor: 'pointer',
      background: '#fff',
      borderRadius: '0',
      active: {
        background: '#fff',
      },
    },
    paddingBottom: '0px',
    paddingLeft: '0px',
    paddingRight: '0px',
    paddingTop: '0px',
    valueContainer: {
      display: 'flex',
      justifyContent: 'center',
      padding: '4px 8px',
    },
    control: {
      paddingBottom: '0px',
    },
    singleValue: {
      paddingBottom: '0px',
      fontWeight: 'normal',
      fontSize: '15px',
      color: '#5a5c5c',
    },
  },
  headerSelectsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
