import React, { useCallback } from 'react';
import { Add } from 'iconsax-react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@xcritical/button';

import { useDictionary } from '@crm-framework/dictionaries';

import { IPayoutPaymentMethodConfigurationModel } from '@ams-package/dictionaries';
import { defaultImages } from '@ams-package/icons';

import { paymentEditorActions } from '../../store';
import { currentPaymentMethodSelector } from '../../selectors';

import {
  PaymentIcon,
  PaymentsButtons,
  PaymentsLabel,
  PaymentsWrapper,
} from './styled';

export const PaymentList: React.FC = () => {
  const availablePaymentMethods = useDictionary(
    'payoutPaymentMethods'
  ) as IPayoutPaymentMethodConfigurationModel[];

  const currentMethod = useSelector(currentPaymentMethodSelector);
  const dispatch = useDispatch();

  const onSelect = useCallback(
    (method: IPayoutPaymentMethodConfigurationModel) => {
      dispatch(paymentEditorActions.selectPaymentMethod(method));
    },
    []
  );

  return (
    <PaymentsWrapper>
      <PaymentsLabel>Select the method you want to add</PaymentsLabel>
      <PaymentsButtons isSelected={currentMethod !== undefined}>
        {availablePaymentMethods.map((item) => (
          <Button
            key={item.id}
            baseAppearance="light"
            appearance="payment"
            selected={
              currentMethod === undefined
                ? true
                : currentMethod.name === item.name
            }
            postfix={<Add size="12" />}
            onClick={() => onSelect(item)}>
            <PaymentIcon src={defaultImages[item.id]} />
          </Button>
        ))}
      </PaymentsButtons>
    </PaymentsWrapper>
  );
};
