import {
  getDictionaries,
  loadAndSaveDictionariesByName as loadAndSaveDictionary,
} from '@crm-framework/dictionaries';

import { IDictionaries } from './models';
import { DictionariesNameType, IStoreWithDictionary } from './types';
import { loadDictionaryMethods } from './api';

/**
 * Load, save to state and return dictionaries by name generator.
 * This method returns the full entity model
 *
 * Use only in generator function!
 * To get the correct type use yield*.
 *
 * @generator
 * @param names
 * @param props
 * @param hardReloadDict
 */
export function* loadAndSaveDictionariesByName(
  names: DictionariesNameType[],
  props?: {
    [key in DictionariesNameType]?: any;
  },
  hardReloadDict?: (keyof IDictionaries)[]
) {
  return yield* loadAndSaveDictionary<IDictionaries>(
    loadDictionaryMethods,
    names,
    props,
    hardReloadDict
  );
}

export const getDictionarySelect =
  (name: string) => (state: IStoreWithDictionary<IDictionaries>) =>
    getDictionaries(state)[name] ?? [];
