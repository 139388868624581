import { createSelector } from 'reselect';

import {
  filtersSelector,
  filterValuesSelector,
} from '@ams-package/filters/selectors';
import { initialState } from '@ams-package/filters';
import { DEFAULT_PAGE_SIZE } from '@ams-package/grid';

import { STORE_NAME } from './store';
import { IDashboardGridControllers, IDashboardState } from './types';

export const dashboardBaseSelector = (state): IDashboardState =>
  state[STORE_NAME] || initialState;

export const dashboardIsReadySelector = (state) =>
  dashboardBaseSelector(state).isReady;

export const dashboardIsLoadingSelector = (state) =>
  dashboardBaseSelector(state).isLoading;

export const dashboardItemsSelector = (state) =>
  dashboardBaseSelector(state).items;

export const dashboardTotalCountSelector = (state) =>
  dashboardBaseSelector(state).totalCount;

export const dashboardPageSelector = (state) =>
  dashboardBaseSelector(state).page;

export const dashboardPageSizeSelector = (state) =>
  dashboardBaseSelector(state).pageSize;

export const dashboardSortingSelector = (state) =>
  dashboardBaseSelector(state).sorting;

export const dashboardGridControllersSelector = createSelector(
  dashboardSortingSelector,
  dashboardPageSelector,
  dashboardPageSizeSelector,
  (sorting, page, pageSize): IDashboardGridControllers => ({
    sorting,
    page,
    pageSize,
  })
);

export const dashboardIsClearButtonDisabledSelector = createSelector(
  filtersSelector,
  filterValuesSelector,
  dashboardGridControllersSelector,
  (
    filters,
    filterValues,
    dashboardGridControllers: IDashboardGridControllers
  ) => {
    const isDashboardGridControllerPageSelected =
      (dashboardGridControllers.page && dashboardGridControllers.page !== 1) ||
      (dashboardGridControllers.pageSize &&
        dashboardGridControllers.pageSize !== DEFAULT_PAGE_SIZE) ||
      dashboardGridControllers.sorting;

    return filterValues
      ? filters.every((filter) =>
          filter.isClearable
            ? !filterValues[filter.name]
            : filter.defaultVal === filterValues[filter.name]
        ) && !isDashboardGridControllerPageSelected
      : !isDashboardGridControllerPageSelected;
  }
);
