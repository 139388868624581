import React from 'react';

export const AvailableOffersIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6918 1.70239C12.7108 2.04829 13.4078 3.16463 13.1814 4.23929L12.7683 6.8833C12.7525 6.99903 12.7865 7.06284 12.8166 7.09691C12.8514 7.13641 12.9084 7.16667 12.9833 7.16667H16.3167C17.1107 7.16667 17.8105 7.4887 18.2365 8.06675C18.6639 8.64676 18.7579 9.40716 18.4914 10.1606L16.5003 16.2169C16.3288 16.8754 15.8975 17.4201 15.3836 17.7961C14.8641 18.1762 14.2165 18.4167 13.5667 18.4167H10.4C10.0896 18.4167 9.71938 18.3663 9.37412 18.2676C9.0568 18.177 8.68244 18.0237 8.40714 17.765L5.85071 15.7859L6.61593 14.7975L9.23058 16.8217L9.25859 16.8497C9.31413 16.9053 9.46415 16.9933 9.71752 17.0657C9.95559 17.1337 10.2103 17.1667 10.4 17.1667H13.5667C13.9168 17.1667 14.3108 17.0321 14.6455 16.7873C14.9797 16.5427 15.2109 16.221 15.2936 15.8901L15.2992 15.868L17.3087 9.7556L17.3114 9.74813C17.458 9.33751 17.3846 9.01787 17.2302 8.80825C17.0728 8.59464 16.7726 8.41667 16.3167 8.41667H12.9833C12.1139 8.41667 11.3926 7.67384 11.5313 6.70328L11.5324 6.69518L11.9522 4.00885L11.9565 3.98942C12.0488 3.57441 11.754 3.04072 11.2857 2.8846L11.2747 2.88094L11.2639 2.87688C11.0947 2.81345 10.8724 2.8149 10.6448 2.89314C10.413 2.97283 10.2458 3.10808 10.17 3.22169L10.1687 3.22365L6.75204 8.30698L5.7146 7.60969L9.12995 2.52832C9.1302 2.52795 9.13045 2.52758 9.13069 2.52721C9.38834 2.14139 9.80404 1.86037 10.2385 1.71103C10.6743 1.56121 11.1975 1.52049 11.6918 1.70239Z"
      stroke="transparent"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.80316 6.35509C2.72089 6.43103 2.6084 6.6121 2.6084 7.12501V15.2917C2.6084 15.8046 2.72089 15.9857 2.80316 16.0616C2.8977 16.1489 3.11463 16.25 3.65006 16.25H4.4834C5.01883 16.25 5.23576 16.1489 5.33031 16.0616C5.41257 15.9857 5.52506 15.8046 5.52506 15.2917V7.12501C5.52506 6.6121 5.41257 6.43103 5.33031 6.35509C5.23576 6.26782 5.01883 6.16667 4.4834 6.16667H3.65006C3.11463 6.16667 2.8977 6.26782 2.80316 6.35509ZM1.95531 5.43659C2.40243 5.02386 3.01883 4.91667 3.65006 4.91667H4.4834C5.11463 4.91667 5.73104 5.02386 6.17816 5.43659C6.63756 5.86065 6.77506 6.47125 6.77506 7.12501V15.2917C6.77506 15.9454 6.63756 16.556 6.17816 16.9801C5.73104 17.3928 5.11463 17.5 4.4834 17.5H3.65006C3.01883 17.5 2.40243 17.3928 1.95531 16.9801C1.4959 16.556 1.3584 15.9454 1.3584 15.2917V7.12501C1.3584 6.47125 1.4959 5.86065 1.95531 5.43659Z"
      stroke="transparent"
    />
  </svg>
);
