import styled from 'styled-components';

import background from './appDownload.png';
import appStore from './appStore.png';
import googlePlay from './googlePlay.png';
import logo from './affshore.png';

export const ContentWrapper = styled.div`
  width: 80%;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  overflow: hidden;
  text-align: center;
  font-size: var(--text-font-size);
`;

export const HeaderWrapper = styled.h1`
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
  color: var(--hover-light-color);
  z-index: 11;
  font-size: var(--header-font-size);
`;

export const ButtonsWrapper = styled.div`
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
`;

export const ButtonWrapper = styled.div`
  width: var(--app-button-width);
  height: var(--app-button-height);
`;

export const AppStoreStyledButton = styled(ButtonWrapper)`
  content: url(${appStore});
`;

export const GooglePlayStyledButton = styled(ButtonWrapper)`
  content: url(${googlePlay});
`;
export const PageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:before {
    position: absolute;
    content: '';
    width: 100vw;
    height: 100vh;
    z-index: -1;
    background-repeat: round;
    background-image: url(${background});
    transform: scale(1, -1);
  }
`;

export const Logo = styled.img`
  margin-top: 40px;
  width: 200px;
  content: url(${logo});
`;
