import React from 'react';

import XCCheckbox from '@xcritical/checkbox';

import { CheckboxFormFieldProps, ICheckboxProps } from './types';
import { CheckIcon } from './Icons';

export const Checkbox = React.memo<ICheckboxProps>(({ checked, ...rest }) => (
  <XCCheckbox
    type="checkbox"
    checkIcon={<CheckIcon />}
    appearance={checked ? 'checked' : 'default'}
    baseAppearance="default"
    checked={checked}
    {...rest}
  />
));

export const CheckboxFormField: React.FC<CheckboxFormFieldProps> = ({
  value,
  ...rest
}) => <Checkbox checked={value} {...rest} />;
