import { useSelector } from 'react-redux';

import { getModalByName, IStoreWithModal } from '@xcritical/modal';

export const useModal = (name: string) => {
  const { isOpen, ...payload } = useSelector((state: IStoreWithModal) =>
    getModalByName(state, name)
  );

  return {
    isOpen,
    payload,
  };
};
