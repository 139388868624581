import { useCallback, useEffect } from 'react';

export const useKeyPress = (targetKey: string, callback: () => void) => {
  const onKeyPressHandler = useCallback(
    ({ key }: KeyboardEvent) => {
      if (key === targetKey) {
        callback();
      }
    },
    [callback]
  );

  useEffect(() => {
    window.addEventListener('keydown', onKeyPressHandler);

    return () => {
      window.removeEventListener('keydown', onKeyPressHandler);
    };
  }, [onKeyPressHandler]);
};
