import React, { memo } from 'react';

import { Wrapper } from './styled';

export interface IBaseCellProps {
  onClick?: () => void;
}

export const BaseCell: React.FC<IBaseCellProps> = memo(
  ({ onClick, children }) => <Wrapper onClick={onClick}>{children}</Wrapper>
);
