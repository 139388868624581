import React from 'react';

import { Label } from './Label';
import { ILabelProps } from './types';
import { LabelWrapper } from './styled';

export const LabelCell: React.FC<ILabelProps> = ({ children, ...props }) => (
  <LabelWrapper>
    <Label {...props}>{children}</Label>
  </LabelWrapper>
);
