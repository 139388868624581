import styled from 'styled-components';

import { classNameWithPrefix } from '@crm-framework/utils';

const blockName = 'payment-method-editor-select';

export const MenuListWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'menu-list-wrapper'),
})<{ columns: number }>`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  gap: 8px;
  flex-wrap: wrap;
  margin: 16px 0 0;
`;

export const Divider = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'divider'),
})`
  border-top: 1px solid var(--hover-border-color);
  margin: 16px 0;
`;

export const ButtonWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'button-wrapper'),
})`
  display: flex;
  width: 100%;
`;

export const ButtonContentWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'button-content-wrapper'),
})<{ width: number }>`
  width: ${({ width }) => width};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SingleValueContent = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'single-value-content'),
})`
  display: flex;
  font-size: 16px;
  font-weight: 400;
  color: var(--secondary-text-color);
`;

export const MethodNumber = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'method-number'),
})`
  margin-left: auto;
  color: var(--hover-secondary-color);
  font-size: 12px;
  font-weight: 400;
`;

export const SingleValueMethodNumber = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'single-value-method-number'),
})`
  margin-left: 12px;
  color: var(--hover-secondary-color);
`;

export const PaymentIcon = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'payment-icon'),
})<{ src: string }>`
  width: 60px;
  height: 16px;
  background: url(${({ src }) => src}) no-repeat left;
  background-size: contain;
`;
