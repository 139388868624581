import { createSelector } from 'reselect';

import { STORE_NAME } from './store';
import { FilterValuesType, IFiltersState } from './types';

export const filtersBaseSelector = (state): IFiltersState => state[STORE_NAME];

export const filtersSelector = (state) => filtersBaseSelector(state).filters;

export const pureFiltersValueSelector = (state) =>
  filtersBaseSelector(state).filterValues;

export const filterValuesSelector = createSelector(
  filtersSelector,
  pureFiltersValueSelector,
  (filters, filterValue) =>
    filters.reduce<FilterValuesType>(
      (acc, { name, defaultVal }) => ({
        ...acc,
        [name]: acc[name] ?? defaultVal,
      }),
      filterValue ?? {}
    )
);
