import React, { useCallback } from 'react';
import { components, OptionProps } from 'react-select';

import { IOptionItem } from '@xcritical/select';

import { Checkbox, RadioIcon } from '@ams-package/checkbox';
import { ICheckboxProps } from '@ams-package/checkbox/types';

import { RadioIconWrapper } from '../styled';

type RadioOptionType = OptionProps<IOptionItem> & {
  radioButtonProps?: ICheckboxProps;
};

export const RadioOption: React.FC<RadioOptionType> = ({
  radioButtonProps = {},
  ...optionProps
}) => {
  const {
    isSelected,
    setValue,
    data: { label },
  } = optionProps;

  const onChangeHandler = useCallback(() => {
    setValue(optionProps.data, 'select-option');
  }, [optionProps, setValue]);

  return (
    <components.Option {...(optionProps as OptionProps)}>
      <Checkbox
        type="radio"
        appearance="filter-radio-checkbox"
        checked={isSelected}
        label={label}
        checkIcon={
          <RadioIconWrapper>
            <RadioIcon />
          </RadioIconWrapper>
        }
        onChange={onChangeHandler}
        {...radioButtonProps}
      />
    </components.Option>
  );
};
