import React from 'react';
import { useMount, useUnmount } from 'react-use';

import { PathNames } from '@ams-package/router';

import {
  PageWrapper,
  HeaderWrapper,
  ContentWrapper,
  ButtonsWrapper,
  AppStoreStyledButton,
  GooglePlayStyledButton,
  Logo,
} from './styled';
import { getLinkByMobileOperatingSystem } from './generateLinkForApp';

export const MobileStub = () => {
  const element = document.body;
  useMount(() => {
    element.classList.add('noScroll');
  });

  useUnmount(() => {
    element.classList.remove('noScroll');
  });

  return (
    <PageWrapper>
      <Logo />
      <ContentWrapper>
        <HeaderWrapper>
          <div>Download </div>
          <div>the application</div>
        </HeaderWrapper>
        For more convenient use, use the desktop version or download our
        application
        <ButtonsWrapper>
          <a
            href="https://apps.apple.com/us/app/id6451200083"
            target="_blank"
            rel="noreferrer">
            <AppStoreStyledButton />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.affiliatemobilapp"
            target="_blank"
            rel="noreferrer">
            <GooglePlayStyledButton />
          </a>
        </ButtonsWrapper>
        {window.location.pathname === PathNames.restorePassword && (
          <a
            href={getLinkByMobileOperatingSystem(
              navigator.userAgent,
              window.location
            )}
            rel="noreferrer">
            If you already have an application press here
          </a>
        )}
      </ContentWrapper>
    </PageWrapper>
  );
};
