import React from 'react';
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import { Router } from '@remix-run/router';

import { protectiveOptions } from './const';
import { IRouterConfig } from './types';

// eslint-disable-next-line import/no-mutable-exports
export let router: Router = {} as Router;

export type GetRouterParamsType = {
  AuthLayout: React.FC;
  ProtectedLayout: React.FC;
  routerConfig: IRouterConfig[];
};

export const getRouter = ({
  ProtectedLayout,
  AuthLayout,
  routerConfig,
}: GetRouterParamsType) => {
  router = createBrowserRouter(
    createRoutesFromElements(
      <>
        {protectiveOptions.map((protectedRoutes) => (
          <Route
            path={protectedRoutes ? '/' : ''}
            element={protectedRoutes ? <ProtectedLayout /> : <AuthLayout />}>
            {routerConfig.map((page) => {
              if (page.protected === protectedRoutes) {
                return (
                  <Route
                    key={page.name}
                    path={page.path}
                    element={<page.component />}
                  />
                );
              }

              return null;
            })}
            {!protectedRoutes && (
              <Route path="*" element={<h1>Page Not Found</h1>} />
            )}
          </Route>
        ))}
      </>
    )
  );

  return router;
};
