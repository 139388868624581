import styled from 'styled-components';

import { classNameWithPrefix } from '@crm-framework/utils';

const blockName = 'filter-button';

export const FilterButtonWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'wrapper'),
})<{
  selected: boolean;
}>`
  display: grid;
  grid-template-columns: minmax(135px, 205px) auto;
  border-radius: 8px;

  & button {
    ${({ selected }) =>
      selected
        ? {
            background: 'var(--hover-secondary-color)',
            color: 'var(--secondary-text-color)',
            borderColor: 'var(--hover-secondary-color)',
          }
        : ''}
  }
`;
export const DateFilterButtonWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'date-wrapper'),
})`
  min-width: 205px;
`;
