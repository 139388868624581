import React, { memo, useCallback } from 'react';

import { HeaderItem } from './styles';
import { HeaderItemType } from './types';

export interface ITabHeaderProps {
  isActive: boolean;
  tabIndex: number;
  disabled?: boolean;
  HeaderItem?: React.FC<HeaderItemType>;
  onClick: (value: number) => void;
}

export const TabHeader: React.FC<ITabHeaderProps> = memo(
  ({
    children,
    onClick,
    tabIndex,
    isActive,
    disabled = false,
    HeaderItem: HeaderItemComponent,
  }) => {
    const onClickHandler = useCallback(() => {
      if (!disabled) onClick(tabIndex);
    }, [tabIndex, onClick, disabled]);

    const TabHeaderComponent = HeaderItemComponent || HeaderItem;

    return (
      <TabHeaderComponent
        onClick={onClickHandler}
        className="tab-header"
        disabled={disabled}
        isActive={isActive}>
        {children}
      </TabHeaderComponent>
    );
  }
);
