import styled from 'styled-components';

import { classNameWithPrefix } from '@crm-framework/utils';

import { IDatePickerWithThemeProps } from '../interfaces';
import { getThemeStylesByProperty } from '../styled-components/utils';

export const DatePickerSelectsWrapper = styled.div.attrs({
  className: classNameWithPrefix('datepicker', 'selects-wrapper'),
})<IDatePickerWithThemeProps>`
  ${({ theme }: IDatePickerWithThemeProps) =>
    getThemeStylesByProperty(theme, ['headerSelectsWrapper'])};
`;
