import React, { memo } from 'react';
import { useWindowSize, useMount } from 'react-use';
import { ThemeProvider } from 'styled-components';
import { hot } from 'react-hot-loader/root';
import { useDispatch, useSelector } from 'react-redux';

import theme, { GlobalStyle } from '@ams-package/theme';
import { Loader } from '@ams-package/loader';

import { getAppState } from './selectors';
import { appActions } from './reducer';
import { MobileStub } from './mobile-stub';

export const App: React.FC = hot(
  memo(({ children }) => {
    const dispatch = useDispatch();
    const { isReady } = useSelector(getAppState);
    const { width } = useWindowSize();
    useMount(() => dispatch(appActions.startInit()));

    if (!isReady) {
      return <Loader fixed />;
    }

    return (
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        {width >= 1200 ? children : <MobileStub />}
      </ThemeProvider>
    );
  })
);
