import { styled, css } from 'styled-components';

import { classNameWithPrefix } from '@crm-framework/utils';

import { generateFontStyle } from '@ams-package/theme';

const blockName = 'payment-method';

export const PaymentMethodWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'wrapper'),
})`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 46px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  height: 100%;
`;

export const ConfirmPaymentMethodWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'confirm-wrapper'),
})`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const DeleteWarning = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'delete-warning'),
})`
  ${generateFontStyle('body-2r')}
  color: var(--secondary-light-grey);
  margin-top: 16px;
`;

export const ButtonsWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'buttons-wrapper'),
})`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const PaymentsWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'payments-wrapper'),
})`
  margin-bottom: 24px;
`;

export const PaymentsLabel = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'payments-label'),
})`
  margin-bottom: 16px;
`;

export const PaymentsButtons = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'payments-buttons'),
})<{ isSelected: boolean }>`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  ${({ isSelected }) =>
    !isSelected &&
    css`
      &:has(> *:hover) > *:not(:hover) {
        background: 'var(--unselected-payment-method-card-color)';
        border-color: 'var(--unselected-payment-method-card-color)';
        opacity: 0.7;
        transition: all 0.3s ease-out;
      }
    `}
`;

export const PaymentIcon = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'payment-icon'),
})<{ src: string }>`
  width: 60px;
  height: 24px;
  background: url(${({ src }) => src}) no-repeat left;
  background-size: contain;
`;

export const StatusMessageWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'status-message-wrapper'),
})`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
