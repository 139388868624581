import React, { MouseEventHandler } from 'react';

import { colors } from '@ams-package/theme';

export const ChevronUp = ({
  className,
  stroke = '#EDF2F4',
}: {
  className?: string;
  stroke?: string;
}) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none">
    <path
      d="M3.33398 12.5L8.8222 7.14133C9.47035 6.50847 10.531 6.50847 11.1791 7.14133L16.6673 12.5"
      stroke={stroke}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ChevronDown = ({
  className,
  stroke = '#EDF2F4',
}: {
  className?: string;
  stroke?: string;
}) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none">
    <path
      d="M3.33398 7.5L8.8222 12.8587C9.47035 13.4915 10.531 13.4915 11.1791 12.8587L16.6673 7.5"
      stroke={stroke}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PaginationChevronUp = ({
  className,
  stroke = '#A7A7A7',
}: {
  className?: string;
  stroke?: string;
}) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 13"
    fill="none"
    className={className}>
    <path
      d="M2 8L5.29293 4.78478C5.68182 4.40507 6.31818 4.40507 6.70707 4.78478L10 8"
      stroke={stroke}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PaginationChevronDown = ({
  className,
  stroke = '#A7A7A7',
}: {
  className?: string;
  stroke?: string;
}) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 13"
    fill="none"
    className={className}>
    <path
      d="M2 5L5.29293 8.21522C5.68182 8.59493 6.31818 8.59493 6.70707 8.21522L10 5"
      stroke={stroke}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SelectedIcon = ({
  className,
  fill = colors.PRIMARY_COLOR,
}: {
  className?: string;
  fill?: string;
}) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 1.11628C4.19823 1.11628 1.11628 4.19823 1.11628 8C1.11628 11.8018 4.19823 14.8837 8 14.8837C11.8018 14.8837 14.8837 11.8018 14.8837 8C14.8837 4.19823 11.8018 1.11628 8 1.11628ZM0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8ZM11.7435 5.37278C11.9615 5.59074 11.9615 5.94414 11.7435 6.16211L7.27838 10.6272C7.06042 10.8452 6.70702 10.8452 6.48906 10.6272L4.2565 8.39466C4.03853 8.1767 4.03853 7.8233 4.2565 7.60534C4.47447 7.38737 4.82786 7.38737 5.04583 7.60534L6.88372 9.44323L10.9542 5.37278C11.1721 5.15481 11.5255 5.15481 11.7435 5.37278Z"
      fill={fill}
    />
  </svg>
);

export const ClearIcon = ({
  className,
  onClick,
  stroke = colors.LIGHT_COLOR,
}: {
  className?: string;
  stroke?: string;
  onClick: MouseEventHandler<SVGSVGElement>;
}) => (
  <svg
    className={className}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    onClick={onClick}
    fill="none">
    <path
      d="M2.5 9.5L9.5 2.5M9.5 9.5L2.5 2.5"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
