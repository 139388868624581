import React, { ReactNode } from 'react';

import { Blanket, Menu, FilterDropdownWrapper } from './styled';

export type FilterDropdownProps = {
  isOpen: boolean;
  target: ReactNode;
  onClose: () => void;
};

export const FilterDropdown: React.FC<FilterDropdownProps> = ({
  children,
  isOpen,
  target,
  onClose,
}) => (
  <FilterDropdownWrapper>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </FilterDropdownWrapper>
);
