import { useNavigate } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router';

import { router } from '@ams-package/router';

export const useQuery = () => {
  const navigate = useNavigate();

  const onChangeValue = useCallback(
    (queryName: string, newValue: string | null) => {
      const newSearchParams = new URLSearchParams(window.location.search);

      if (newValue) {
        newSearchParams.set(queryName, encodeURIComponent(newValue));
      } else {
        newSearchParams.delete(queryName);
      }

      navigate(`?${newSearchParams.toString()}`);
    },
    [navigate]
  );

  const clearQuery = useCallback(() => navigate(''), [navigate]);

  const queryObject = useMemo(() => getQueryObject(), []);

  return {
    query: queryObject,
    onChangeValue,
    clearQuery,
  };
};

export const getQueryObject = () => {
  const searchParams = new URLSearchParams(window.location.search);

  return Object.fromEntries(
    Array.from(searchParams.entries()).map(([key, value]) => [
      key,
      decodeURIComponent(value),
    ])
  );
};

export const useQueryParam = (name: string) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  return decodeURIComponent(searchParams.get(name) || '');
};

export const changeQueryObjects = (values: object) => {
  const newSearchParams = new URLSearchParams(window.location.search);
  Object.entries(values).forEach(([queryName, newValue]) => {
    if (newValue) {
      newSearchParams.set(queryName, encodeURIComponent(newValue));
    } else {
      newSearchParams.delete(queryName);
    }
  });

  return router.navigate(`?${newSearchParams.toString()}`);
};
