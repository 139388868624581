import React from 'react';

export const Dashboard: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none">
    <path
      d="M9.3 21H14.7C19.2 21 21 19.2 21 14.7V9.3C21 4.8 19.2 3 14.7 3H9.3C4.8 3 3 4.8 3 9.3V14.7C3 19.2 4.8 21 9.3 21Z"
      fill="transparent"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.15 17.85C16.14 17.85 16.95 17.04 16.95 16.05V7.95C16.95 6.96 16.14 6.15 15.15 6.15C14.16 6.15 13.35 6.96 13.35 7.95V16.05C13.35 17.04 14.151 17.85 15.15 17.85Z"
      fill="transparent"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.85 17.85C9.84 17.85 10.65 17.04 10.65 16.05V12.9C10.65 11.91 9.84 11.1 8.85 11.1C7.86 11.1 7.05 11.91 7.05 12.9V16.05C7.05 17.04 7.851 17.85 8.85 17.85Z"
      fill="transparent"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
