import styled, { css } from 'styled-components';

import { generateFontStyle } from '@ams-package/theme';

import { ILabelProps, RequiredPosition } from './types';

const leftStar = css`
  &:before {
    content: '*';
    display: block;
    position: absolute;
    top: 0;
    left: -10px;
  }
`;

const rightStar = css`
  &:after {
    content: '*';
    display: block;
    position: absolute;
    top: 0;
    right: -10px;
  }
`;

const getStar = (position: RequiredPosition) =>
  position === RequiredPosition.right ? rightStar : leftStar;

export const Label = styled.label<ILabelProps>`
  ${generateFontStyle('body-2m')}
  color: var(--label-form-color);
  line-height: ${({ lineHeight = '25px' }) => lineHeight};
  display: inline-block;
  position: relative;
  user-select: none;
  cursor: ${({ htmlFor }) => (htmlFor ? 'pointer' : 'initial')};
  ${({ isRequired, requiredPosition = RequiredPosition.right }) =>
    isRequired ? getStar(requiredPosition) : null}
`;
