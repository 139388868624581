import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 42px;
  display: flex;
  font-weight: 400;
  align-items: center;
  cursor: pointer;
`;
