import React from 'react';

export const LogoutIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.84746 3.84346C6.01016 2.6832 7.73932 2.25 9.93053 2.25H10.0537C12.0405 2.25 13.6451 2.60559 14.7959 3.53739C15.9677 4.48622 16.5275 5.91452 16.6841 7.72859C16.7197 8.14127 16.414 8.50468 16.0013 8.54029C15.5886 8.5759 15.2252 8.27023 15.1896 7.85755C15.0525 6.26825 14.5891 5.30002 13.852 4.70315C13.0938 4.08927 11.9037 3.75 10.0537 3.75H9.93053C7.887 3.75 6.65089 4.16291 5.90701 4.90523C5.16352 5.64716 4.75 6.87966 4.75 8.91807V15.0819C4.75 17.1203 5.16352 18.3528 5.90701 19.0948C6.65089 19.8371 7.887 20.25 9.93053 20.25H10.0537C11.8897 20.25 13.0753 19.9154 13.8335 19.3101C14.5697 18.7224 15.035 17.7712 15.1806 16.2128C15.2191 15.8004 15.5847 15.4973 15.9971 15.5358C16.4095 15.5743 16.7126 15.9399 16.6741 16.3523C16.5071 18.1405 15.9398 19.5481 14.7694 20.4824C13.621 21.3992 12.0261 21.75 10.0537 21.75H9.93053C7.73932 21.75 6.01016 21.3168 4.84746 20.1565C3.68438 18.9959 3.25 17.2694 3.25 15.0819V8.91807C3.25 6.73064 3.68438 5.0041 4.84746 3.84346ZM18.2954 8.29376C18.588 8.00056 19.0629 8.00006 19.3561 8.29264L22.5298 11.4597C22.6708 11.6004 22.75 11.7914 22.75 11.9905C22.75 12.1897 22.6708 12.3807 22.5298 12.5214L19.3561 15.6885C19.0629 15.981 18.588 15.9805 18.2954 15.6873C18.0028 15.3941 18.0033 14.9193 18.2965 14.6267L20.1866 12.7405H11.2189C10.8047 12.7405 10.4689 12.4048 10.4689 11.9905C10.4689 11.5763 10.8047 11.2405 11.2189 11.2405H20.1866L18.2965 9.35442C18.0033 9.06183 18.0028 8.58696 18.2954 8.29376Z"
      stroke="transparent"
    />
  </svg>
);
