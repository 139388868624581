import { set } from 'date-fns';

import { IThemeNamespace } from '@xcritical/theme';

import { IDatePickerTheme, IMonthOptions } from './interfaces';
import { getDatePickerMergedTheme } from './styled-components/utils';
import { datePickerThemeNamespace } from './theme';
import { DatePickerRangeType } from './DatePickerRange';

export const getDatePickerTheme = (
  theme?: IThemeNamespace<IDatePickerTheme>
): any => ({
  [datePickerThemeNamespace]: getDatePickerMergedTheme(theme),
});

export const getMonthsSelectValues = (monthOptions?: IMonthOptions) => {
  const { firstMonthIndex = 0, lastMonthIndex = 12 } = monthOptions ?? {};
  const formatter = new Intl.DateTimeFormat('en-US', { month: 'long' });
  const date = new Date();
  const options: any = [];

  for (let i = firstMonthIndex; i < lastMonthIndex; i++) {
    const monthName = formatter.format(set(date, { month: i, date: 1 }));

    options.push({
      value: i,
      id: monthName,
      label: monthName,
    });
  }

  return options;
};

export const getDatePickerLabel = (dateRange?: DatePickerRangeType) => {
  const [startDate, endDate] = dateRange ?? [];

  return startDate ?? endDate
    ? `${startDate?.toLocaleDateString() ?? ''} - ${
        endDate?.toLocaleDateString() ?? ''
      }`
    : '';
};
