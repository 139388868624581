import React from 'react';

export const FTDIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none">
    <path
      d="M13.3765 8.71667C12.8614 9.08395 12.2309 9.3 11.55 9.3C9.8103 9.3 8.4 7.8897 8.4 6.15C8.4 4.4103 9.8103 3 11.55 3C12.6777 3 13.667 3.59258 14.2235 4.48333M6.6 19.2784H8.94926C9.25556 19.2784 9.55999 19.3149 9.85693 19.3878L12.3392 19.991C12.8778 20.1222 13.4389 20.135 13.9831 20.0293L16.7277 19.4953C17.4527 19.3541 18.1196 19.0069 18.6423 18.4985L20.5841 16.6095C21.1386 16.071 21.1386 15.1972 20.5841 14.6578C20.0848 14.1721 19.2943 14.1174 18.7294 14.5293L16.4663 16.1804C16.1422 16.4173 15.7479 16.5448 15.3423 16.5448H13.1569L14.548 16.5448C15.332 16.5448 15.9671 15.927 15.9671 15.1643V14.8882C15.9671 14.2549 15.524 13.7028 14.8927 13.5497L12.7457 13.0276C12.3963 12.9428 12.0385 12.9 11.6788 12.9C10.8105 12.9 9.2387 13.6189 9.2387 13.6189L6.6 14.7224M19.2 7.05C19.2 8.7897 17.7897 10.2 16.05 10.2C14.3103 10.2 12.9 8.7897 12.9 7.05C12.9 5.3103 14.3103 3.9 16.05 3.9C17.7897 3.9 19.2 5.3103 19.2 7.05ZM3 14.34L3 19.56C3 20.064 3 20.3161 3.09809 20.5086C3.18438 20.6779 3.32206 20.8156 3.49141 20.9019C3.68393 21 3.93595 21 4.44 21H5.16C5.66405 21 5.91607 21 6.10859 20.9019C6.27794 20.8156 6.41562 20.6779 6.50191 20.5086C6.6 20.3161 6.6 20.064 6.6 19.56V14.34C6.6 13.836 6.6 13.5839 6.50191 13.3914C6.41562 13.2221 6.27794 13.0844 6.10859 12.9981C5.91607 12.9 5.66405 12.9 5.16 12.9L4.44 12.9C3.93595 12.9 3.68393 12.9 3.49141 12.9981C3.32206 13.0844 3.18438 13.2221 3.09809 13.3914C3 13.5839 3 13.836 3 14.34Z"
      stroke="#AAFF69"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
