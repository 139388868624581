import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useCopyToClipboard } from 'react-use';

import { IButtonProps } from '@xcritical/button';
import { notify } from '@xcritical/notification';

import { CopyIcon, CheckedCopyIcon } from '@ams-package/icons';
import { CopySuccessContent } from '@ams-package/utils';

import { IconButton } from './IconButton';

interface ICopyIconButtonProps extends IButtonProps {
  size?: number;
  text: string;
  message?: string;
  color?: string;
  onClick?: (value: string) => void;
}

export const CopyIconButton = ({
  appearance,
  onClick,
  size = 17,
  text,
  color,
  message,
  selected = false,
}: ICopyIconButtonProps) => {
  const [copiedText, copyToClipboard] = useCopyToClipboard();
  const [isClicked, setIsClicked] = useState<boolean>(false);

  useEffect(() => {
    setIsClicked(selected);
  }, [selected]);

  useEffect(() => {
    if (copiedText.value) {
      notify.success(<CopySuccessContent text={message} />);
    }
  }, [copiedText]);

  const onHandleClick = useCallback(
    (e?: SyntheticEvent) => {
      e?.stopPropagation();
      e?.preventDefault();
      copyToClipboard(text);
      onClick?.(text);
      setIsClicked(true);
    },
    [copyToClipboard, onClick, text]
  );

  return (
    <IconButton
      onClick={onHandleClick}
      appearance={appearance || (isClicked ? 'default' : 'secondary')}
      size={size}
      color={color}
      Icon={isClicked ? CheckedCopyIcon : CopyIcon}
    />
  );
};
