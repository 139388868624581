import React from 'react';

export const AllOffersIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1793 2.01337C12.1791 2.0132 12.1795 2.01354 12.1793 2.01337L15.9638 5.78122C17.7557 7.5827 17.7574 10.4942 15.9627 12.2889L12.3043 15.9473L12.3031 15.9484C10.5017 17.7403 7.59017 17.742 5.79544 15.9473L2.02044 12.1723C1.10462 11.2564 0.613548 9.99428 0.671328 8.69423L0.671431 8.69201L0.871498 4.52394C0.970943 2.54498 2.55277 0.962649 4.54163 0.872652C4.54196 0.872637 4.54229 0.872622 4.54262 0.872608L8.70477 0.672827C8.70523 0.672803 8.70568 0.672779 8.70614 0.672756C10.0013 0.60549 11.2645 1.09882 12.1793 2.01337ZM11.2954 2.89725C10.6273 2.22912 9.70742 1.87215 8.76998 1.92112L8.76734 1.92126L4.60068 2.12126L4.59881 2.12134C3.25502 2.18181 2.18748 3.24895 2.11996 4.58603C2.11995 4.58624 2.11997 4.58581 2.11996 4.58603L1.9201 8.74973C1.92008 8.75008 1.92006 8.75043 1.92005 8.75078C1.87814 9.70035 2.23705 10.6211 2.90432 11.2884L6.67932 15.0634C7.9844 16.3685 10.1058 16.3703 11.4211 15.0628C11.4209 15.0629 11.4212 15.0626 11.4211 15.0628L15.0788 11.405C16.3837 10.1001 16.3857 7.97901 15.0786 6.66375C15.0783 6.66341 15.0779 6.66307 15.0776 6.66273L11.2964 2.89823L11.2954 2.89725Z"
      stroke="transparent"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.90413 5.43864C6.09872 5.43864 5.4458 6.09156 5.4458 6.89698C5.4458 7.70239 6.09872 8.35531 6.90413 8.35531C7.70955 8.35531 8.36247 7.70239 8.36247 6.89698C8.36247 6.09156 7.70955 5.43864 6.90413 5.43864ZM4.1958 6.89698C4.1958 5.40121 5.40836 4.18864 6.90413 4.18864C8.39991 4.18864 9.61247 5.40121 9.61247 6.89698C9.61247 8.39275 8.39991 9.60531 6.90413 9.60531C5.40836 9.60531 4.1958 8.39275 4.1958 6.89698Z"
      stroke="transparent"
    />
  </svg>
);
