import { FilterType, FilterTypes } from '@ams-package/filters';

import { DashboardSettingsType } from './types';

export const getFilters = (settings: DashboardSettingsType[]): FilterType[] =>
  settings
    .filter((setting) => setting.inFilter)
    .map(
      ({
        placeholder,
        name,
        filterType,
        displayName,
        dictionaryName,
        options,
        isSearchable,
        isClearable = true,
        defaultVal,
        prefix,
        isHidden,
      }) => {
        switch (filterType) {
          case FilterTypes.Date:
            return {
              type: FilterTypes.Date,
              name,
              placeholder: placeholder ?? displayName,
              isClearable,
              defaultVal,
              isHidden,
              prefix,
            };
          case FilterTypes.SingleSelect:
          default:
            return {
              type: filterType as any,
              name,
              dictionaryName,
              options,
              placeholder: placeholder ?? displayName,
              isSearchable,
              isClearable,
              isHidden,
              defaultVal,
              prefix,
            };
        }
      }
    );
