import React, { useCallback } from 'react';
// eslint-disable-next-line import/order
import PureDatePicker, {
  ReactDatePickerProps as PureReactDatePickerProps,
} from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import { ThemeProvider } from 'styled-components';
import { Calendar } from 'iconsax-react';

import Input from '@xcritical/input';

import { getDatePickerTheme } from './utils';
import {
  DatePickerWrapper,
  DatePickerDay,
  DatePickerHeader,
} from './components';

export type ReactDatePickerProps = PureReactDatePickerProps & {
  value?: Date;
  withSelects?: boolean;
  placeholder?: string;
};

export const DatePicker: React.FC<ReactDatePickerProps> = ({
  value,
  onChange,
  maxDate,
  placeholder,
  withSelects = true,
  ...rest
}) => {
  const datePickerTheme = getDatePickerTheme({});

  const renderCustomHeader = useCallback(
    (props) => (
      <DatePickerHeader
        minWidth="200px"
        withSelects={!!withSelects}
        {...props}
      />
    ),
    [withSelects]
  );

  const CustomInput = useCallback(
    (props) => <Input {...props} postfix={<Calendar />} onChange={() => {}} />,
    []
  );

  return (
    <ThemeProvider theme={datePickerTheme}>
      <DatePickerWrapper>
        <PureDatePicker
          selected={value ?? maxDate}
          maxDate={maxDate}
          showYearDropdown
          showMonthDropdown
          disabledKeyboardNavigation
          onChange={onChange}
          useWeekdaysShort
          placeholderText={placeholder ?? 'Date'}
          customInput={<CustomInput />}
          renderCustomHeader={renderCustomHeader}
          renderDayContents={DatePickerDay}
          dateFormat="dd.MM.yyyy"
          {...(rest as any)}
        />
      </DatePickerWrapper>
    </ThemeProvider>
  );
};
