import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@xcritical/button';

import { paymentEditorActions } from '../../store';
import { currentPaymentMethodSelector } from '../../selectors';

import { PaymentList } from './PaymentList';
import { PaymentEditor } from './PaymentEditor';
import { PaymentMethodWrapper } from './styled';
export { DeletePaymentMethod } from './DeletePaymentMethod';
export { ClosePaymentMethod } from './ClosePaymentMethod';
export { StatusMessageWrapper } from './styled';

export const PaymentMethod: React.FC<{ selectedId?: number }> = ({
  selectedId,
}) => {
  const dispatch = useDispatch();
  const currentMethod = useSelector(currentPaymentMethodSelector);

  const onSave = useCallback(() => {
    dispatch(paymentEditorActions.save());
  }, []);

  return (
    <PaymentMethodWrapper>
      <div>
        {!selectedId && <PaymentList />}
        {currentMethod && <PaymentEditor method={currentMethod} />}
      </div>
      {currentMethod && (
        <Button shouldFitContent onClick={onSave}>
          Save
        </Button>
      )}
    </PaymentMethodWrapper>
  );
};

export { PaymentMethodWrapper };
