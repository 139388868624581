import { css } from 'styled-components';

const BASE_WHITE = '#EDF2F4';
const BASE_BLACK = '#202022';
const BLACK = '#101012';
const DARK_GREY = '#3A3A3C';
const LIGHT_GREY = '#86898B';
const LIGHT_GREY_HOVER = '#848484';
const SECONDARY_LIGHT_GREY = '#A2A2A4';
const ORANGE = '#FF6600';

export const PRIMARY_COLOR = '#AAFF69';
export const HOVER_PRIMARY_COLOR = '#92FF40';
export const DISABLED_PRIMARY_COLOR = '#597943';

export const INFO_COLOR = '#8338EC';
export const HOVER_INFO_COLOR = '#9B54FF';
export const DISABLED_INFO_COLOR = '#481E84';
export const DISABLED_INFO_TEXT = LIGHT_GREY;

export const LIGHT_COLOR = BASE_WHITE;
export const HOVER_LIGHT_COLOR = '#FFF';
export const DISABLED_LIGHT_COLOR = LIGHT_GREY;
export const DISABLED_LIGHT_TEXT = DARK_GREY;

export const PAYMENT_METHOD_CARD_COLOR = '#E2E7E9';

export const SECONDARY_COLOR = DARK_GREY;
export const SECONDARY_TEXT_COLOR = BASE_WHITE;
export const HOVER_SECONDARY_COLOR = '#5C5C5E';
export const DISABLED_SECONDARY_COLOR = '#3E3E3F';
export const DISABLED_SECONDARY_TEXT_COLOR = '#6B6B6D';

export const GRID_BACKGROUND = '#2D2D2D';
export const PAGINATION_TEXT_COLOR = SECONDARY_LIGHT_GREY;
export const ACTIVE_COLOR = '#AAFF69';

export const LINK_COLOR = BASE_WHITE;
export const HOVER_LINK_COLOR = '#FFF';
export const DISABLED_LINK_COLOR = LIGHT_GREY;

export const NAVIGATION_LINK_COLOR = BASE_WHITE;
export const HOVER_NAVIGATION_LINK_COLOR = '#AAFF69';

export const NAVIGATION_ICON_COLOR = BASE_WHITE;
export const HOVER_NAVIGATION_ICON_COLOR = '#AAFF69';

export const TABS_HEADER_COLOR = BASE_WHITE;
export const HOVER_TABS_HEADER_COLOR = '#AAFF69';

export const MAIN_MENU_COLOR = BASE_WHITE;
export const MAIN_MENU_HOVER_COLOR = PRIMARY_COLOR;
export const MAIN_MENU_ICON_COLOR = BASE_WHITE;
export const MAIN_MENU_HOVER_ICON_COLOR = PRIMARY_COLOR;
export const LOGOUT_TEXT_COLOR = SECONDARY_LIGHT_GREY;
export const LOGOUT_HOVER_TEXT_COLOR = PRIMARY_COLOR;

export const DEFAULT_TEXT_COLOR = BASE_WHITE;
export const DARK_TEXT_COLOR = BASE_BLACK;
export const BACKGROUND_COLOR = BASE_BLACK;
export const MODAL_BACKGROUND_COLOR = DARK_GREY;
export const SIDEBAR_BACKGROUND_COLOR = BLACK;
export const OFFERS_ICON_BACKGROUND = ORANGE;
export const BORDER_COLOR = DARK_GREY;
export const SECONDARY_BORDER_COLOR = '#5C5C5E';
export const HOVER_BORDER_COLOR = SECONDARY_LIGHT_GREY;
export const SECONDARY_ICON_COLOR = SECONDARY_LIGHT_GREY;
export const DANGER_COLOR = '#91282F';
export const DANGER_TEXT_COLOR = '#D33F49';
export const CORRECT_BORDER_COLOR = '#288026';
export const CORRECT_TEXT_COLOR = '#42D33F';
export const BLANKET_COLOR = 'rgba(0, 0, 0, 0.60);';

export const POPOVER_TEXT_COLOR = SECONDARY_LIGHT_GREY;
export const DESCRIPTION_TEXT_COLOR = SECONDARY_LIGHT_GREY;
export const LABEL_FORM_COLOR = '#E4E4E4';

export const TABS_BACKGROUND = '#27282b';
export const TABS_COLOR = '#DFE6ED';

export const STATISTIC_LABEL_COLOR = '#E2E7E9';

export const SECONDARY_SCROLLBAR_TRACK = '4A4A4A';

export const globalColors = css`
  --base-white: ${BASE_WHITE};
  --base-black: ${BASE_BLACK};
  --black: ${BLACK};
  --dark-grey: ${DARK_GREY};
  --light-grey: ${LIGHT_GREY};
  --light-grey-hover: ${LIGHT_GREY_HOVER};
  --secondary-light-grey: ${SECONDARY_LIGHT_GREY};
  --orange: ${ORANGE};

  --primary-color: ${PRIMARY_COLOR};
  --hover-primary-color: ${HOVER_PRIMARY_COLOR};
  --disabled-primary-color: ${DISABLED_PRIMARY_COLOR};

  --secondary-scrollbar-track: ${SECONDARY_SCROLLBAR_TRACK};

  --tabs-background: ${TABS_BACKGROUND};
  --tabs-color: ${TABS_COLOR};

  --statistic-label-color: ${STATISTIC_LABEL_COLOR};

  --info-color: ${INFO_COLOR};
  --hover-info-color: ${HOVER_INFO_COLOR};
  --disabled-info-color: ${DISABLED_INFO_COLOR};
  --disabled-info-text-color: var(--light-grey);

  --secondary-icon-color: var(--secondary-light-grey);
  --light-color: var(--base-white);
  --hover-light-color: ${HOVER_LIGHT_COLOR};
  --disabled-light-color: var(--light-grey);
  --disabled-light-text-color: var(--dark-grey);

  --unselected-payment-method-card-color: ${PAYMENT_METHOD_CARD_COLOR};

  --secondary-color: var(--dark-grey);
  --secondary-text-color: var(--base-white);
  --hover-secondary-color: ${HOVER_SECONDARY_COLOR};
  --disabled-secondary-color: ${DISABLED_SECONDARY_COLOR};
  --disabled-secondary-text-color: ${DISABLED_SECONDARY_TEXT_COLOR};

  --grid-background: ${GRID_BACKGROUND};
  --pagination-text-color: ${PAGINATION_TEXT_COLOR};
  --active-color: ${ACTIVE_COLOR};

  --link-color: var(--base-white);
  --hover-link-color: ${HOVER_LINK_COLOR};
  --disabled-link-color: var(--light-grey);

  --navigation-link-color: var(--base-white);
  --hover-navigation-link-color: ${HOVER_NAVIGATION_LINK_COLOR};

  --navigation-icon-color: var(--base-white);
  --hover-navigation-icon-color: ${HOVER_NAVIGATION_ICON_COLOR};

  --tabs-header-color: var(--base-white);
  --hover-tabs-header-color: ${HOVER_TABS_HEADER_COLOR};

  --main-menu-color: var(--base-white);
  --main-menu-hover-color: var(--primary-color);
  --main-menu-icon-color: var(--base-white);
  --main-menu-hover-icon-color: var(--primary-color);
  --logout-text-color: ${LOGOUT_TEXT_COLOR};
  --logout-hover-text-color: var(--primary-color);

  --default-text-color: var(--base-white);
  --dark-text-color: var(--base-black);
  --background-color: var(--base-black);
  --modal-background-color: var(--dark-grey);
  --sidebar-background-color: var(--black);
  --offers-icon-background: var(--orange);
  --border-color: var(--dark-grey);
  --secondary-border-color: ${SECONDARY_BORDER_COLOR};
  --hover-border-color: ${HOVER_BORDER_COLOR};
  --danger-color: ${DANGER_COLOR};
  --danger-text-color: ${DANGER_TEXT_COLOR};
  --correct-border-color: ${CORRECT_BORDER_COLOR};
  --correct-text-color: ${CORRECT_TEXT_COLOR};
  --blanket-color: ${BLANKET_COLOR};

  --popover-text-color: ${POPOVER_TEXT_COLOR};
  --description-text-color: ${DESCRIPTION_TEXT_COLOR};
  --label-form-color: ${LABEL_FORM_COLOR};

  --skeleton-color: var(--dark-grey);
`;
