import { css } from 'styled-components';

export const h1CssVariable = css`
  --h1-font-size: 32px;
  --h1-font-family: Satoshi;
  --h1-font-weight: 700;
  --h1-line-height: 38px;

  @media (min-width: 1920px) {
    --h1-font-size: 36px;
  }
  @media (max-width: 1200px) {
    --h1-font-size: 24px;
  }
`;

export const h2CssVariable = css`
  --h2-font-size: 24px;
  --h2-font-family: Satoshi;
  --h2-font-weight: 700;
  --h2-line-height: 38px;

  @media (max-width: 1200px) {
    --h2-font-size: 20px;
    --h2-line-height: 38px;
  }
`;

export const h3CssVariable = css`
  --h3-font-size: 18px;
  --h3-font-family: Satoshi;
  --h3-font-weight: 700;
  --h3-line-height: 1.2;

  @media (max-width: 1200px) {
    --h3-font-size: 16px;
  }
`;

export const h4CssVariable = css`
  --h4-font-size: 16px;
  --h4-font-family: Satoshi;
  --h4-font-weight: 700;
  --h4-line-height: 1.2;

  @media (max-width: 1200px) {
    --h4-font-size: 14px;
  }
  @media (min-width: 1920px) {
    --h4-font-size: 16px;
  }
`;

export const body1MCssVariable = css`
  --body-1m-font-size: 16px;
  --body-1m-font-family: Inter;
  --body-1m-font-weight: 500;
  --body-1m-line-height: 18px;

  @media (max-width: 1200px) {
    --body-1m-font-size: 14px;
  }

  @media (min-width: 1920px) {
    --body-1m-font-size: 16px;
  }
`;

export const body1RCssVariable = css`
  --body-1r-font-size: 16px;
  --body-1r-font-family: Inter;
  --body-1r-font-weight: 400;
  --body-1r-line-height: 1.2;
`;

export const body2SBCssVariable = css`
  --body-2sb-font-size: 12px;
  --body-2sb-font-family: Inter;
  --body-2sb-font-weight: 600;
  --body-2sb-line-height: 1.3;

  @media (max-width: 1200px) {
    --body-2sb-font-size: 14px;
  }
  @media (min-width: 1920px) {
    --body-2sb-font-size: 16px;
  }
`;

export const body1SBCssVariable = css`
  --body-1sb-font-size: 12px;
  --body-1sb-font-family: Inter;
  --body-1sb-font-weight: 600;
  --body-1sb-line-height: 1.3;
`;

export const body2MCssVariable = css`
  --body-2m-font-size: 14px;
  --body-2m-font-family: Inter;
  --body-2m-font-weight: 500;
  --body-2m-line-height: 1.3;

  @media (max-width: 1200px) {
    --body-2m-font-size: 12px;
  }
  @media (min-width: 1920px) {
    --body-2m-font-size: 16px;
  }
`;

export const body2RCssVariable = css`
  --body-2r-font-size: 14px;
  --body-2r-font-family: Inter;
  --body-2r-font-weight: 400;
  --body-2r-line-height: 1.3;

  @media (max-width: 1200px) {
    --body-2r-font-size: 14px;
  }
  @media (min-width: 1920px) {
    --body-2r-font-size: 16px;
  }
`;

export const body3SBCssVariable = css`
  --body-3sb-font-size: 12px;
  --body-3sb-line-height: 16px;
  --body-3sb-font-family: Inter;
  --body-3sb-font-weight: 600;

  @media (max-width: 1200px) {
    --body-3sb-font-size: 10px;
  }
`;

export const body3RCssVariable = css`
  --body-3r-font-size: 12px;
  --body-3r-font-family: Inter;
  --body-3r-font-weight: 400;
  --body-3r-line-height: 16px;

  @media (max-width: 1200px) {
    --body-3r-font-size: 10px;
    --body-3r-line-height: 12px;
  }
  @media (min-width: 1920px) {
    --body-3r-font-size: 12px;
    --body-3r-line-height: 16px;
  }
`;
