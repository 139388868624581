import React from 'react';

import { TextTruncate } from '@crm-framework/utils';

import { Icon } from '@ams-package/icons';
import { Tooltip } from '@ams-package/utils';

import { CenteredPrefixPostfixButton } from '../Button';
import { CopyIconButton } from '../CopyButton';

import { AccountInfoIconWrapper } from './styled';

export const SocialMediaButton: React.FC<{
  appearance: string;
  value: string;
  name: string;
  icon?: string | React.ReactNode;
  popupAppearance?: string;
  onClick?: () => void;
  target?: string;
  href?: string;
}> = ({
  appearance,
  name,
  popupAppearance = 'tooltip',
  icon,
  onClick = () => {},
  target,
  href,
  value,
}) => (
  <Tooltip value={value} appearance={popupAppearance!}>
    <CenteredPrefixPostfixButton
      appearance={appearance}
      shouldFitContent
      onClick={onClick}
      target={target}
      href={href}
      postfix={
        <CopyIconButton
          text={value}
          appearance="link"
          message={`${name} copied successfully`}
        />
      }
      prefix={
        !!icon && (
          <AccountInfoIconWrapper>
            {typeof icon === 'string' ? <Icon name={icon} /> : icon}
          </AccountInfoIconWrapper>
        )
      }>
      <TextTruncate>{value}</TextTruncate>
    </CenteredPrefixPostfixButton>
  </Tooltip>
);
