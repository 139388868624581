import React from 'react';

import { IButtonProps } from '@xcritical/button';

import { CenteredPrefixPostfixButton } from './Button';

interface IIconButtonProps extends IButtonProps {
  Icon: React.ElementType;
  size?: number;
  color?: string;
}

export const IconButton = ({
  appearance,
  onClick,
  size = 17,
  Icon,
  color,
  ...props
}: IIconButtonProps) => (
  <CenteredPrefixPostfixButton
    baseAppearance={appearance}
    appearance="iconButton"
    onClick={onClick}
    {...props}>
    <Icon size={size} stroke={color} />
  </CenteredPrefixPostfixButton>
);
