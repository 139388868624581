import React from 'react';
import { components, SingleValueProps } from 'react-select';

import { IOptionItem } from '@xcritical/select';

import { SingleValueMethodNumber, SingleValueContent } from './styled';

export const SingleValue = (props: SingleValueProps<IOptionItem>) => {
  const {
    data: { postfix, label },
  } = props;

  return (
    <components.SingleValue {...(props as SingleValueProps)}>
      <SingleValueContent>
        {label}
        {postfix && (
          <SingleValueMethodNumber>{postfix}</SingleValueMethodNumber>
        )}
      </SingleValueContent>
    </components.SingleValue>
  );
};
