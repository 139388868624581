import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@ams-package/button';

import {
  ClearButtonWrapper,
  FilterControlsWrapper,
  FiltersWrapper,
} from './styled';
import {
  FilterType,
  FilterValuesType,
  OnChangeFilterValuePayloadType,
} from './types';
import { SwitchFilter } from './SwitchFilter';
import { filtersActions } from './store';
import { filterValuesSelector } from './selectors';

export type FiltersProps = {
  filters: FilterType[];
  filterValues?: FilterValuesType;
  onChange?: (payload: OnChangeFilterValuePayloadType) => void;
  onClear?: () => void;
  disableClearButton?: boolean;
};

export const Filters: React.FC<FiltersProps> = ({
  filters,
  filterValues,
  onChange,
  onClear,
  disableClearButton,
}) => {
  const dispatch = useDispatch();

  const $filterValues = useSelector(filterValuesSelector) || filterValues;

  useEffect(() => {
    if (filters.length) {
      dispatch(filtersActions.setFilters(filters));
    }
  }, [filters]);

  useEffect(() => {
    if (filterValues) {
      dispatch(filtersActions.setFilterValues(filterValues));
    }
  }, [filterValues]);

  const onClearHandler = useCallback(() => {
    dispatch(filtersActions.clearFilters());

    onClear?.();
  }, [onClear]);

  const onChangeFilterValueHandler = useCallback(
    (name: string) => (value: any) => {
      dispatch(filtersActions.changeFilter({ name, value }));
      onChange?.({
        name,
        value,
      });
    },
    []
  );

  return (
    <FiltersWrapper filterCounts={filters.length}>
      <FilterControlsWrapper filterCounts={filters.length}>
        {filters.map(
          (filter) =>
            !filter.isHidden && (
              <SwitchFilter
                {...filter}
                value={$filterValues[filter.name]}
                onChange={onChangeFilterValueHandler(filter.name)}
                key={filter.name}
              />
            )
        )}
      </FilterControlsWrapper>
      <ClearButtonWrapper filterCounts={filters.length}>
        <Button
          onClick={onClearHandler}
          baseAppearance="link"
          disabled={disableClearButton}
          appearance="clearButton">
          Clear all
        </Button>
      </ClearButtonWrapper>
    </FiltersWrapper>
  );
};
