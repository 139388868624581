import { createSelector } from '@reduxjs/toolkit';

import { formSelector } from '@xcritical/forms';

import { STORE_NAME, initialState, STORE_FORM_NAME } from './store';
import { IPaymentEditorState } from './types';

export const paymentEditorSelector = (state): IPaymentEditorState =>
  state[STORE_NAME] || initialState;

export const isOpenPaymentEditorSelector = (state): boolean =>
  paymentEditorSelector(state).isOpen;

const isFormChanged = (state): boolean =>
  formSelector(state, STORE_FORM_NAME).isChanged;

export const selectedPaymentEditorSelector = (state) =>
  paymentEditorSelector(state).selectedId;

export const currentPaymentMethodSelector = (state) =>
  paymentEditorSelector(state).currentPaymentMethod;

export const formNameSelector = (state) =>
  paymentEditorSelector(state).formName;

export const drawerSelector = createSelector(
  paymentEditorSelector,
  isFormChanged,
  ({ isOpen, selectedId, savedState, mode }, isChanged) => ({
    isChanged,
    isOpen,
    selectedId,
    savedState,
    mode,
  })
);
