import React, { ReactElement, useMemo } from 'react';
import { SelectComponentsConfig, GroupBase } from 'react-select';

import { IOptionItem } from '@xcritical/select';

import { SelectFormFieldType } from '../select';

import { FilterSelect } from './FilterSelect';
import { CheckboxOption } from './components/CheckboxOption';

type FilterSelectProps = SelectFormFieldType;

export const MultiFilterSelect = ({
  value = [],
  ...rest
}: FilterSelectProps): ReactElement => {
  const components = useMemo(
    () =>
      ({
        Option: CheckboxOption,
      } as SelectComponentsConfig<IOptionItem, false, GroupBase<IOptionItem>>),
    []
  );
  const isSearchable = value ? !(value as IOptionItem[]).length : true;

  return (
    <FilterSelect
      isMulti
      value={value as IOptionItem[]}
      isSearchable={isSearchable}
      isClearable={false}
      components={components as any}
      {...(rest as any)}
    />
  );
};
