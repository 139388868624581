import React from 'react';

export const Calendar: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.33203 0.833252C4.60817 0.833252 4.83203 1.05711 4.83203 1.33325V3.33325C4.83203 3.60939 4.60817 3.83325 4.33203 3.83325C4.05589 3.83325 3.83203 3.60939 3.83203 3.33325V1.33325C3.83203 1.05711 4.05589 0.833252 4.33203 0.833252Z"
      fill="#828282"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.66797 0.833252C9.94411 0.833252 10.168 1.05711 10.168 1.33325V3.33325C10.168 3.60939 9.94411 3.83325 9.66797 3.83325C9.39183 3.83325 9.16797 3.60939 9.16797 3.33325V1.33325C9.16797 1.05711 9.39183 0.833252 9.66797 0.833252Z"
      fill="#828282"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.832031 6.05981C0.832031 5.78367 1.05589 5.55981 1.33203 5.55981H12.6654C12.9415 5.55981 13.1654 5.78367 13.1654 6.05981C13.1654 6.33596 12.9415 6.55981 12.6654 6.55981H1.33203C1.05589 6.55981 0.832031 6.33596 0.832031 6.05981Z"
      fill="#828282"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.15806 3.59014C1.73091 4.05015 1.5 4.74323 1.5 5.66659V11.3333C1.5 12.2566 1.73091 12.9497 2.15806 13.4097C2.57899 13.863 3.26197 14.1666 4.33333 14.1666H9.66667C10.738 14.1666 11.421 13.863 11.8419 13.4097C12.2691 12.9497 12.5 12.2566 12.5 11.3333V5.66659C12.5 4.74323 12.2691 4.05015 11.8419 3.59014C11.421 3.13684 10.738 2.83325 9.66667 2.83325H4.33333C3.26197 2.83325 2.57899 3.13684 2.15806 3.59014ZM1.42527 2.90969C2.08768 2.19633 3.07137 1.83325 4.33333 1.83325H9.66667C10.9286 1.83325 11.9123 2.19633 12.5747 2.90969C13.2309 3.61635 13.5 4.58994 13.5 5.66659V11.3333C13.5 12.4099 13.2309 13.3835 12.5747 14.0901C11.9123 14.8035 10.9286 15.1666 9.66667 15.1666H4.33333C3.07137 15.1666 2.08768 14.8035 1.42527 14.0901C0.769089 13.3835 0.5 12.4099 0.5 11.3333V5.66659C0.5 4.58994 0.769089 3.61635 1.42527 2.90969Z"
      fill="#828282"
    />
  </svg>
);
