import React from 'react';

import { BaseModal, BaseModalType } from '../base-modal';

type WithoutHeaderModalType = Omit<BaseModalType, 'title'>;

export const WithoutHeaderModal: React.FC<WithoutHeaderModalType> = ({
  children,
  ...params
}) => (
  <BaseModal appearance="withoutHeader" title="" {...params}>
    {children}
  </BaseModal>
);
