import styled from 'styled-components';

import { classNameWithPrefix } from '@crm-framework/utils';

const blockName = 'side-modal';

export const Wrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'wrapper'),
})`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: var(--default-border-radius);
  overflow: hidden;
`;

export const TitleWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'title-wrapper'),
})`
  width: 100%;
  font-size: 18px;
  font-weight: 700;
`;
