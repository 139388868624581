import React, { useCallback } from 'react';

import { SelectProps } from '@xcritical/select';

import { Select } from '@ams-package/select';

import { ISelectOption } from '../interfaces';

export const DatePickerSelect: React.FC<SelectProps> = React.memo(
  ({ value, options, onChange, theme, ...rest }) => {
    const onDateChange = useCallback(
      (selectedOption: ISelectOption, actionMeta) => {
        onChange?.(selectedOption.value, actionMeta);
      },
      [onChange]
    );

    return (
      <Select
        theme={theme}
        appearance="date-picker"
        value={value as any}
        autoFocus
        withSelectedIcon={false}
        options={options as any}
        onChange={onDateChange}
        shouldFitContainer={false}
        isHideSelectedOptions={false}
        components={{
          IndicatorSeparator: () => null,
        }}
        {...(rest as any)}
      />
    );
  }
);
