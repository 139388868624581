import { IModalTheme } from '@xcritical/modal';

export const modalTheme: IModalTheme = {
  appearance: {
    default: {
      blanket: {
        background: '#000',
        color: '#fff',
        opacity: 0.6,
      },
      content: {
        background: 'var(--modal-background-color)',
        borderRadius: 20,
        boxShadow: 'none',
      },
      headerWrapper: {
        padding: '24px 24px 0 24px',
      },
      header: {},
      iconClose: {
        color: 'var(--default-text-color)',
        width: 24,
        height: 24,
      },
      body: {
        padding: '24px !important',
      },
    },
    statusModal: {
      content: {
        width: '100%',
        maxWidth: '484px',
        minWidth: '300px',
      },
      body: {
        padding: '0 !important',
      },
    },
    withoutHeader: {
      headerWrapper: {
        display: 'none',
      },
    },
    fixed: {
      content: {
        position: 'fixed',
      },
    },
  },
};
