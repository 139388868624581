import React from 'react';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { addYears } from 'date-fns';

import { getMonthsSelectValues } from '../utils';

import { ArrowLeft, ArrowRight } from './Icons';
import { DatePickerSelect } from './DatePickerSelect';
import {
  findMonthSelectOptionByDate,
  findYearSelectOptionByDate,
  getYearsList,
} from './utils';
import { DatePickerSelectsWrapper } from './styled';

type DatePickerHeaderProps = ReactDatePickerCustomHeaderProps & {
  withSelects?: boolean;
};

export const DatePickerHeader: React.FC<DatePickerHeaderProps> = ({
  monthDate,
  date = new Date(),
  decreaseMonth,
  changeYear,
  changeMonth,
  increaseMonth,
  withSelects,
}) => {
  const maxYearValue = addYears(new Date(), 1);
  const yearSelectValues = getYearsList(1950, maxYearValue);
  const monthSelectValues = getMonthsSelectValues();

  const findYearSelectOptionByDateHandler =
    findYearSelectOptionByDate(yearSelectValues);
  const findMonthSelectOptionByDateHandler =
    findMonthSelectOptionByDate(monthSelectValues);

  return (
    <div className="react-datepicker__navigation-arrows">
      <button
        aria-label="Previous Month"
        type="button"
        className="react-datepicker__navigation react-datepicker__navigation--previous"
        onClick={decreaseMonth}>
        <ArrowLeft />
      </button>

      {withSelects ? (
        <DatePickerSelectsWrapper>
          <div>
            <DatePickerSelect
              value={findMonthSelectOptionByDateHandler(date)}
              onChange={changeMonth}
              autoFocus={false}
              options={monthSelectValues}
            />
          </div>
          <div>
            <DatePickerSelect
              value={findYearSelectOptionByDateHandler(date)}
              onChange={changeYear}
              autoFocus={false}
              options={yearSelectValues}
            />
          </div>
        </DatePickerSelectsWrapper>
      ) : (
        <span className="react-datepicker__current-month">
          {monthDate.toLocaleString('en-US', {
            month: 'long',
            year: 'numeric',
          })}
        </span>
      )}

      <button
        aria-label="Next Month"
        type="button"
        className="react-datepicker__navigation react-datepicker__navigation--next"
        onClick={increaseMonth}>
        <ArrowRight />
      </button>
    </div>
  );
};
