import React from 'react';

export const Statistics: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 19.3024C0 18.9171 0.312359 18.6047 0.697674 18.6047H19.3023C19.6876 18.6047 20 18.9171 20 19.3024C20 19.6877 19.6876 20.0001 19.3023 20.0001H0.697674C0.312359 20.0001 0 19.6877 0 19.3024Z"
      stroke="transparent"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.72172 0.816514C8.14153 0.27542 8.78394 0 9.58108 0H10.4183C11.2154 0 11.8578 0.27542 12.2776 0.816514C12.6726 1.32557 12.7904 1.96882 12.7904 2.55814V19.3023C12.7904 19.6876 12.478 20 12.0927 20H7.90666C7.52134 20 7.20898 19.6876 7.20898 19.3023V2.55814C7.20898 1.96882 7.32676 1.32557 7.72172 0.816514ZM8.82416 1.67186C8.69586 1.83723 8.60433 2.1242 8.60433 2.55814V18.6047H11.395V2.55814C11.395 2.1242 11.3035 1.83723 11.1752 1.67186C11.0718 1.53853 10.877 1.39535 10.4183 1.39535H9.58108C9.1224 1.39535 8.9276 1.53853 8.82416 1.67186Z"
      stroke="transparent"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.37959 6.42939C1.75747 5.88146 2.35713 5.5813 3.11671 5.5813H3.8609C4.62048 5.5813 5.22014 5.88146 5.59802 6.42939C5.94558 6.93335 6.04694 7.56307 6.04694 8.13944V19.3022C6.04694 19.6875 5.73458 19.9999 5.34927 19.9999H1.62834C1.24302 19.9999 0.930664 19.6875 0.930664 19.3022V8.13944C0.930664 7.56307 1.03203 6.93335 1.37959 6.42939ZM2.52825 7.22158C2.41069 7.39204 2.32601 7.69255 2.32601 8.13944V18.6046H4.65159V8.13944C4.65159 7.69255 4.56691 7.39204 4.44935 7.22158C4.36212 7.09509 4.2176 6.97665 3.8609 6.97665H3.11671C2.76001 6.97665 2.61549 7.09509 2.52825 7.22158Z"
      stroke="transparent"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.402 11.0808C14.7799 10.5328 15.3796 10.2327 16.1392 10.2327H16.8834C17.6429 10.2327 18.2426 10.5328 18.6205 11.0808C18.968 11.5847 19.0694 12.2144 19.0694 12.7908V19.3024C19.0694 19.6877 18.757 20.0001 18.3717 20.0001H14.6508C14.2655 20.0001 13.9531 19.6877 13.9531 19.3024V12.7908C13.9531 12.2144 14.0545 11.5847 14.402 11.0808ZM15.5507 11.8729C15.4332 12.0434 15.3485 12.3439 15.3485 12.7908V18.6048H17.6741V12.7908C17.6741 12.3439 17.5894 12.0434 17.4718 11.8729C17.3846 11.7465 17.2401 11.628 16.8834 11.628H16.1392C15.7825 11.628 15.6379 11.7465 15.5507 11.8729Z"
      stroke="transparent"
    />
  </svg>
);
