import 'modern-normalize/modern-normalize.css';
import { createGlobalStyle } from 'styled-components';

import './fonts/fonts.css';
import { globalColors } from './colors';
import { globalSizes } from './sizes';
import {
  body2RStyle,
  globalFontVariables,
  h1Style,
  h2Style,
  h3Style,
  h4Style,
} from './fonts';
import { badgeCssVariable } from './badge';
import { scrollbarStyles } from './scrollbars';

export const GlobalStyle = createGlobalStyle`
  :root {
    ${globalColors}
    ${globalSizes}
    ${globalFontVariables}
    ${badgeCssVariable}
      
    --default-font-family: 'Inter', sans-serif;

      // size:
    --sidebar-avatar-size: 48px;
    --input-postfix-size: 16px;
    --sidebar-account-info-icon-size: 20px;
    --sidebar-logout-link-size: 20px;
    --nav-icon-wrapper-size: 40px;
    --navigation-icon-size: 24px;

    // font-size:
    --input-label-font-size: 14px !important;
    --more-details-button-font-size: 16px;
    --badge-font-size: 12px;
    --sidebar-info-mail-font-size: 16px;
    --sidebar-logout-link-font-size: 16px;

    // gap:
    --sidebar-header-gap: 60px;
    --sidebar-account-info-gap: 20px;
    --sidebar-logout-link-gap: 8px;
    --tabs-headers-wrapper-gap: 40px;

    // padding:
    --button-padding: 12px;
    --badge-padding: 2px 6px;
    --account-info-buttons-padding: 12px;
    --header-section-padding: 24px 40px 32px;
    --layout-content-padding: 0 20px 24px 20px;
    --layout-content-margin: 0 20px;
    --sidebar-padding: 32px 20px;
    --nav-icon-wrapper-padding: 8px;
    --sidebar-account-info-padding: 0 0 20px 0;
    --sidebar-logout-padding: 20px 0 0 0;
    --header-wrapper-padding: 8px 8px 8px 20px;

    @media (min-width: 1700px) {
      //size:
      --sidebar-avatar-size: 56px;
      --input-postfix-size: 20px;
      --sidebar-logout-link-size: 24px;
      --nav-icon-wrapper-size: 48px;
      --navigation-icon-size: 28px;

      // font-size:
      --more-details-button-font-size: 16px;
      --badge-font-size: 14px;
      --sidebar-info-mail-font-size: 18px;
      --sidebar-logout-link-font-size: 20px;

      // gap:
      --sidebar-logout-link-gap: 12px;
      --sidebar-header-gap: 60px;

      // padding:
      --button-padding: 20px;
      --badge-padding: 4px 8px;
      --layout-content-padding: 0 20px 24px 20px;
      --layout-content-margin: 0 20px;
      --nav-icon-wrapper-padding: 10px;
      --header-section-padding: 31px 40px 48px;
      --header-wrapper-padding: 12px 12px 12px 32px;
    }
      
    @media (max-width: 1200px) {
      //size:
      --sidebar-avatar-size: 38px;
      --sidebar-account-info-icon-size: 16px;
      --input-postfix-size: 16px;
      --sidebar-logout-link-size: 16px;
      --nav-icon-wrapper-size: 36px;
      --navigation-icon-size: 20px;

      // font-size:
      --more-details-button-font-size: 14px;
      --badge-font-size: 10px;
      --sidebar-info-mail-font-size: 14px;
      --sidebar-logout-link-font-size: 14px;

      // gap:
      --sidebar-header-gap: 32px;
      --sidebar-account-info-gap: 16px;
      --tabs-headers-wrapper-gap: 32px;

      // padding:
      --badge-padding: 0px 6px;
      --sidebar-padding: 24px 40px;
      --sidebar-account-info-padding: 0 0 16px 0;
      --sidebar-logout-padding: 16px 0 0 0;

      // app:
      --app-button-width: 270px;
      --app-button-height: 80px;
      --header-font-size: 64px;
      --text-font-size: 28px;
    }
    @media (max-width: 600px) {
      // app:
      --app-button-width: 170px;
      --app-button-height: 50px;
      --header-font-size: 32px;
      --text-font-size: 16px;
    }
  }
  * {
    margin: 0;
    padding: 0;
    font-family: var(--default-font-family);
    box-sizing: border-box;
    ${scrollbarStyles}
  }

  body {
    width: 100%;
    min-width: 1200px;
    height: 100vh;
    max-height: 100vh;
    color: var(--default-text-color);
    background-color: var(--background-color);
    ${body2RStyle}
    overflow: hidden;
  }

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    list-style-type: none;
  }

  a {
    color: var(--primary-color);
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: var(--hover-primary-color);
    }
  }

  h1{
    ${h1Style}
  }

  h2{
    ${h2Style}
  }

  h3{
    ${h3Style}
  }

  h4{
    ${h4Style}
  }

  *::placeholder {
    color: var(--hover-secondary-color);
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: var(--default-text-color);
    -webkit-box-shadow: 0 0 0px 1000px var(--background-color) inset;
    transition: var(--background-color);
  }  
`;
