import { ButtonTheme } from '@xcritical/button';

import { generateFontStyle } from './fonts';
import { DEFAULT_BORDER_RADIUS } from './sizes';

export const buttonTheme: ButtonTheme = {
  appearance: {
    // green
    default: {
      background: 'var(--primary-color)',
      borderColor: 'var(--primary-color)',
      borderRadius: DEFAULT_BORDER_RADIUS,
      padding: 'var(--button-padding)',
      color: 'var(--dark-text-color)',
      fontFamily: 'Inter',
      prefixSpacing: 0,
      postfixSpacing: 0,
      display: 'flex',
      justifyContent: 'center',
      boxShadowColor: 'transparent !important',
      selected: {
        background: 'var(--hover-primary-color)',
        borderColor: 'var(--hover-primary-color)',
      },
      hover: {
        background: 'var(--hover-primary-color)',
        borderColor: 'var(--hover-primary-color)',
      },
      active: {
        background: 'var(--hover-primary-color)',
        borderColor: 'var(--hover-primary-color)',
      },
      disabled: {
        background: 'var(--disabled-primary-color)',
        borderColor: 'var(--disabled-primary-color)',
        opacity: 1,
      },
    },
    // purple
    info: {
      background: 'var(--info-color)',
      borderColor: 'var(--info-color)',
      color: 'var(--default-text-color)',
      selected: {
        background: 'var(--hover-info-color)',
        borderColor: 'var(--hover-info-color)',
      },
      hover: {
        background: 'var(--hover-info-color)',
        borderColor: 'var(--hover-info-color)',
      },
      active: {
        background: 'var(--hover-info-color)',
        borderColor: 'var(--hover-info-color)',
      },
      disabled: {
        background: 'var(--disabled-info-color)',
        borderColor: 'var(--disabled-info-color)',
        color: 'var(--disabled-info-text-color)',
        opacity: 1,
      },
    },
    // white
    light: {
      background: 'var(--light-color)',
      borderColor: 'var(--light-color)',
      color: 'var(--dark-text-color)',
      selected: {
        background: 'var(--hover-light-color)',
        borderColor: 'var(--hover-light-color)',
      },
      hover: {
        background: 'var(--hover-light-color)',
        borderColor: 'var(--hover-light-color)',
      },
      active: {
        background: 'var(--hover-light-color)',
        borderColor: 'var(--hover-light-color)',
      },
      disabled: {
        background: 'var(--disabled-light-color)',
        borderColor: 'var(--disabled-light-color)',
        color: 'var(--disabled-light-text-color)',
        opacity: 1,
      },
    },
    // grey
    secondary: {
      background: 'var(--secondary-color)',
      borderColor: 'var(--secondary-color)',
      color: 'var(--secondary-text-color)',
      selected: {
        background: 'var(--hover-secondary-color)',
        borderColor: 'var(--hover-secondary-color)',
      },
      hover: {
        background: 'var(--hover-secondary-color)',
        borderColor: 'var(--hover-secondary-color)',
      },
      active: {
        background: 'var(--hover-secondary-color)',
        borderColor: 'var(--hover-secondary-color)',
      },
      disabled: {
        background: 'var(--disabled-secondary-color)',
        borderColor: 'var(--disabled-secondary-color)',
        color: 'var(--disabled-secondary-text-color)',
        opacity: 1,
      },
    },
    // light grey
    lightSecondary: {
      background: 'var(--hover-secondary-color)',
      borderColor: 'var(--hover-secondary-color)',
      color: 'var(--secondary-text-color)',
      selected: {
        background: 'var(--light-grey-hover)',
        borderColor: 'var(--light-grey-hover)',
        color: 'var(--secondary-text-color)',
      },
      hover: {
        background: 'var(--light-grey-hover)',
        borderColor: 'var(--light-grey-hover)',
        color: 'var(--secondary-text-color)',
      },
      active: {
        background: 'var(--light-grey-hover)',
        borderColor: 'var(--light-grey-hover)',
        color: 'var(--secondary-text-color)',
      },
      disabled: {
        background: 'var(--disabled-secondary-color)',
        borderColor: 'var(--disabled-secondary-color)',
        color: 'var(--disabled-secondary-text-color)',
        opacity: 1,
      },
    },
    moreDetails: {
      borderRadius: 12,
      padding: '14px',
    },
    // transparent grey
    link: {
      color: 'var(--link-color)',
      ...generateFontStyle('body-2m'),
      border: 'none',
      selected: {
        color: 'var(--hover-link-color)',
      },
      hover: {
        color: 'var(--hover-link-color)',
        border: 'none',
        fontWeight: 500,
      },
      active: {
        color: 'var(--hover-link-color)',
        fontWeight: 500,
      },
      disabled: {
        color: 'var(--disabled-link-color)',
        opacity: 1,
        fontWeight: 500,
      },
    },
    clearButton: {
      fontSize: '14px',
      fontWeight: '500 !important',
      lineHeight: 1.2,
      paddingRight: 0,
      color: 'var(--light-grey)',
      textDecorationLine: 'underline',
      textUnderlineOffset: '3px',
    },
    // transparent green
    primaryLink: {
      color: 'var(--primary-color)',
      fontWeight: 500,
      lineHeight: 1.2,
      border: 'none',
      backgroundColor: 'inherit',
      selected: {
        color: 'var(--hover-primary-color)',
        backgroundColor: 'inherit',
      },
      hover: {
        color: 'var(--hover-primary-color)',
        border: 'none',
        backgroundColor: 'inherit',
      },
      active: {
        color: 'var(--hover-primary-color)',
        backgroundColor: 'inherit',
      },
      disabled: {
        color: 'var(--disabled-primary-color)',
        opacity: 1,
        backgroundColor: 'inherit',
      },
    },
    headerBalanceButton: {
      padding: '4px 20px',
      cursor: 'default !important',
    },
    offerCardButton: {
      width: '49%',
      maxWidth: 'var(--offers-card-button-max-width)',
      borderRadius: '12px',
      fontWeight: 500,
      lineHeight: '1.1',
      padding: '12px 14px',
    },
    iconButton: {
      borderRadius: '8px',
      padding: '4px !important',
    },
    buttonWithValue: {
      width: '100%',
      height: '34px',
      padding: '8px',
      borderRadius: 8,
      outline: 'none',
      fontSize: 14,
      hover: {
        color: 'var(--secondary-text-color)',
      },
      active: {
        color: 'var(--secondary-text-color)',
        borderRadius: 8,
      },
      selected: {
        color: 'var(--secondary-text-color)',
      },
    },
    payment: {
      padding: '8px',
      borderRadius: 8,
      opacity: 0.7,
      background: 'var(--unselected-payment-method-card-color)',
      borderColor: 'var(--unselected-payment-method-card-color)',
      selected: {
        background: 'var(--hover-light-color)',
        borderColor: 'var(--hover-light-color)',
        opacity: 1,
      },
      hover: {
        background: 'var(--hover-light-color)',
        borderColor: 'var(--hover-light-color)',
        opacity: 1,
      },
      active: {
        background: 'var(--hover-light-color)',
        borderColor: 'var(--hover-light-color)',
        opacity: 1,
      },
    },

    addPaymentMethod: {
      width: 'fit-content',
    },
  },
};
