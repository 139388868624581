import React from 'react';

import { defaultImages } from './images';
import { IIconProps } from './types';

export const Icon: React.FC<IIconProps> = ({
  name,
  className,
  images = defaultImages,
  ...rest
}) => (
  <>
    {images[name] ? (
      <img
        className={className}
        src={images[name]}
        alt={`${name} icon`}
        {...rest}
      />
    ) : null}
  </>
);
