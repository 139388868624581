import { IPayoutPaymentMethodConfigurationModel } from '@ams-package/dictionaries';

export enum PaymentEditorMode {
  AddOrEdit = 'AddOrEdit',
  Delete = 'Delete',
  ConfirmClose = 'ConfirmClose',
}

export interface IPaymentEditorState {
  isOpen: boolean;
  mode?: PaymentEditorMode;
  selectedId?: number;
  formName?: string;
  statusMessageKey?: string;
  currentPaymentMethod?: IPayoutPaymentMethodConfigurationModel;
  savedState?: PaymentEditorState;
}
export type PaymentEditorState = {
  state: 'success' | 'failed';
  id?: number;
};
export type OnSuccessActionPayload = {
  id?: number;
  formName?: string;
};
