import React from 'react';
import { useSelector } from 'react-redux';

import { drawerSelector } from '../selectors';
import { PaymentEditorMode } from '../types';

import { PaymentMethod, DeletePaymentMethod } from './payment-method';
import { StatusMessagesType, SuccessState } from './SuccessState';
import { FailureState } from './FailureState';

export type PaymentMethodModalContentProps = {
  statusMessages?: StatusMessagesType;
};

export const PaymentMethodModalContent: React.FC<
  PaymentMethodModalContentProps
> = ({ statusMessages }) => {
  const { selectedId, savedState, mode } = useSelector(drawerSelector);

  return (
    <>
      {savedState && savedState.state === 'success' && (
        <SuccessState statusMessages={statusMessages} />
      )}
      {savedState && savedState.state === 'failed' && <FailureState />}
      {!savedState && mode === PaymentEditorMode.AddOrEdit && (
        <PaymentMethod selectedId={selectedId} />
      )}
      {!savedState && mode === PaymentEditorMode.Delete && (
        <DeletePaymentMethod />
      )}
    </>
  );
};
