import { toCurrency as baseToCurrency } from '@crm-framework/utils';

export const toCurrency = (
  amount?: number | string,
  options?: Intl.NumberFormatOptions
): string => {
  if (!amount && amount !== 0) return '';

  return baseToCurrency(Number(amount), options);
};
