import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { TextTruncate } from '@crm-framework/utils';

import { getDictionarySelect } from '@ams-package/dictionaries';

import { ISelectOption, Select } from '../select';

import { IDictionarySelect } from './types';

const toSelectOption = ({ id, name, disabled }: any): ISelectOption => ({
  value: id,
  label: name,
  disabled,
});

const SelectOption: React.FC<ISelectOption> = ({ label }) => (
  <TextTruncate title={label}>{label}</TextTruncate>
);

export const DictionarySelect: React.FC<IDictionarySelect> = ({
  dictionaryName,
  value,
  onChange,
  isMulti,
  optionsSorting,
  options: customOptions,
  optionsMapper: optionsMapperFromProps,
  as: AsComponent = Select,
  ...restProps
}) => {
  const dictionary = useSelector(getDictionarySelect(dictionaryName)) || [];

  const itemToSelectOption = useCallback(
    optionsMapperFromProps || toSelectOption,
    [optionsMapperFromProps]
  );

  const mappedOptions: ISelectOption[] = useMemo(() => {
    const options = customOptions || dictionary.map(itemToSelectOption);

    if (optionsSorting) return options.sort(optionsSorting);

    return options;
  }, [dictionary, optionsSorting, customOptions, itemToSelectOption]);

  const mappedValue = useMemo(
    () =>
      Array.isArray(value)
        ? value.map((_) => mappedOptions.find((item) => item.value === _)!)
        : mappedOptions.find((item) => item.value === value)!,
    [mappedOptions, value]
  );

  const onDictionarySelectChange = (
    newSelectOption: ISelectOption | null
  ): void => {
    if (Array.isArray(newSelectOption)) {
      const values = newSelectOption.map(
        (_newSelectOption) => _newSelectOption.value
      );
      onChange(values);
    } else {
      onChange(newSelectOption?.value == null ? '' : newSelectOption.value);
    }
  };

  return (
    <AsComponent
      {...restProps}
      isMulti={isMulti}
      onChange={onDictionarySelectChange}
      value={mappedValue ?? null}
      options={mappedOptions}
      isOptionDisabled={({ disabled }: ISelectOption) => disabled!}
      formatOptionLabel={SelectOption}
    />
  );
};

export { IDictionarySelect } from './types';
