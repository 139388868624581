import { INotificationTheme } from '@xcritical/notification';

export const notificationTheme: INotificationTheme = {
  toast: {
    display: 'fixed',
    top: '120px',
    padding: '8px 12px',
    minHeight: 0,
    borderRadius: '8px',
  },
  closeButton: {
    display: 'none',
  },
  progressBar: {
    backgroundColor: 'var(--background-color)',
  },
  success: {
    toast: {
      backgroundColor: 'var(--background-color)',
      border: '1px solid var(--primary-color)',
    },
  },
  error: {
    toast: {
      backgroundColor: 'var(--background-color)',
      border: '1px solid var(--danger-text-color)',
    },
  },
};
