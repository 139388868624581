import { generateFontStyle } from './fonts';
import { secondaryScrollbarStyles } from './scrollbars';

export { selectThemeNamespace } from '@xcritical/select';

const defaultSelectTheme = {
  width: 'fit-content',
  background: 'none',
  borderRadius: 12,
  input: {
    color: 'var(--secondary-text-color)',
  },
  selected: {
    background: 'var(--background-color)',
    color: 'var(--secondary-text-color)',
  },
  active: {
    background: 'var(--background-color)',
    color: 'var(--secondary-text-color)',
  },
  noOptionsMessage: {
    background: 'var(--hover-secondary-color)',
    color: 'var(--secondary-text-color)',
    hover: {
      background: 'var(--hover-secondary-color)',
      color: 'var(--secondary-text-color)',
    },
    active: {
      background: 'var(--hover-secondary-color)',
      color: 'var(--secondary-text-color)',
    },
  },
  disabled: {
    background: 'var(--background-color)',
    color: 'var(--secondary-text-color)',
    border: 'none',
    opacity: 0.5,
  },
  placeholder: {
    color: 'var(--hover-secondary-color)',
    padding: '2px 0',
    ...generateFontStyle('body-1r'),
  },
  button: {
    padding: '12px 14px',
    border: `1px solid var(--secondary-color)`,
    cursor: 'pointer',
    borderRadius: 12,
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    background: 'var(--background-color)',
    color: 'var(--secondary-text-color)',
    hover: {
      background: 'var(--background-color)',
      color: 'var(--secondary-text-color)',
      border: `1px solid var(--border-color)`,
    },
    active: {
      border: `1px solid var(--border-color)`,
      background: 'var(--background-color)',
      color: 'var(--secondary-text-color)',
    },
    focus: {
      border: `1px solid var(--border-color)`,
    },
    selected: {
      background: 'var(--background-color)',
      color: 'var(--secondary-text-color)',
    },
    disabled: {
      opacity: '0.5',
      fill: 'var(--default-text-color)',
      path: {
        stroke: 'var(--secondary-text-color)',
      },
      invalid: {
        borderColor: 'var(--danger-color)',
        color: 'var(--danger-color)',
      },
    },
  },
  dropdownIndicator: {
    padding: '0px',
    paddingLeft: 10,
    color: 'var(--secondary-text-color)',
  },
  indicatorSeparator: {
    display: 'none',
  },
  dropdown: {
    color: 'var(--secondary-text-color)',
    background: 'var(--hover-secondary-color)',
    borderRadius: 12,
    selected: {
      background: 'var(--hover-secondary-color)',
      color: 'var(--secondary-text-color)',
    },
    hover: {
      background: 'var(--hover-secondary-color)',
      color: 'var(--secondary-text-color)',
    },
  },
  dropdownList: {
    background: 'var(--hover-secondary-color)',
    borderRadius: 12,
    hover: {
      background: 'var(--hover-secondary-color)',
    },
    active: {
      background: 'var(--hover-secondary-color)',
    },
  },
  option: {
    cursor: 'pointer',
    fontSize: 14,
    padding: '12px 0',
    width: 'calc(100% - 32px)',
    margin: '0 16px',
    '&:not(:last-child)': {
      borderBottom: `1px solid var(--hover-border-color)`,
    },
    lineHeight: '16px',
    color: 'var(--secondary-text-color)',
    background: 'var(--hover-secondary-color)',
    hover: {
      background: 'var(--hover-secondary-color)',
      color: 'var(--secondary-text-color)',
    },
    active: {
      background: 'var(--hover-secondary-color)',
      color: 'var(--secondary-text-color)',
    },
    focus: {
      background: 'var(--hover-secondary-color)',
      color: 'var(--secondary-text-color)',
    },
    selected: {
      background: 'var(--hover-secondary-color)',
      color: 'var(--secondary-text-color)',
      fontWeight: 400,
    },
    span: {
      ':hover': {
        background: `var(--hover-secondary-color)`,
        color: `var(--secondary-text-color)`,
      },
    },
  },
  optionWrapper: {
    display: 'flex',
    width: '100%',
  },
  selectedIconWrapper: {
    marginLeft: 'auto',
  },
  valueContainer: {
    marginRight: 8,
    padding: '0px',
    ...generateFontStyle('body-1r'),
    color: 'var(--secondary-text-color)',
  },
  invalid: {
    borderColor: 'var(--danger-color)',
    color: 'var(--danger-color)',
  },
};

export const selectTheme = {
  width: 'auto',
  appearance: {
    default: defaultSelectTheme,
    'payment-methods': {
      option: {
        margin: 0,
        width: '100%',
        display: 'flex',
        padding: '12px',
        borderRadius: 8,
        border: 'none',
        background: 'var(--hover-light-color)',
        hover: {
          background: 'var(--hover-light-color)',
        },
        focus: {
          background: 'var(--hover-light-color)',
        },
        active: {
          background: 'var(--hover-light-color)',
        },
        selected: {
          background: 'var(--hover-light-color)',
        },
        span: {
          ':hover': {
            background: 'var(--hover-light-color)',
          },
        },
      },
      dropdownList: {
        ...secondaryScrollbarStyles,
        margin: '16px 0',
        padding: '0 10px 0 16px',
        marginRight: 6,
      },
      dropdown: {
        active: {
          background: 'var(--hover-secondary-color)',
        },
      },
    },
    'filter-select': {
      indicatorsContainer: {
        height: '16px',
        svg: {
          width: '12px',
          height: '12px',
        },
      },
      button: {
        display: 'none',
      },
      placeholder: {
        fontSize: 14,
        color: 'var(--secondary-text-color)',
      },
      dropdown: {
        width: 'calc(100% + 20px)',
        ...secondaryScrollbarStyles,
      },
      singleValue: {
        fontSize: 14,
      },
      valueContainer: {
        fontSize: 14,
      },
      option: {
        marginTop: '16px',
        borderBottom: 'none !important',
        padding: '0px',
        display: 'flex',
        alignItems: 'center',

        ':last-child': {
          marginBottom: '16px',
        },
      },
    },
    'date-picker': {
      dropdownIndicator: {
        paddingLeft: 0,
      },
      indicatorsContainer: {
        height: '16px',
        svg: {
          width: '12px',
          height: '12px',
          path: {
            strokeWidth: 3,
          },
        },
      },
      button: {
        height: '18px',
        padding: '0 8px',
        border: `1px solid var(--secondary-color)`,
        borderRadius: 8,
        fontSize: 14,
        background: 'var(--secondary-color)',
        color: 'var(--secondary-text-color)',
        hover: {
          background: 'var(--hover-secondary-color)',
          color: 'var(--secondary-text-color)',
          border: `1px solid var(--hover-secondary-color)`,
        },
        active: {
          border: `1px solid var(--hover-secondary-color)`,
          background: 'var(--hover-secondary-color)',
          color: 'var(--secondary-text-color)',
        },
        focus: {
          border: `1px solid var(--hover-secondary-color)`,
          background: 'var(--hover-secondary-color)',
        },
        selected: {
          background: 'var(--hover-secondary-color)',
          color: 'var(--secondary-text-color)',
        },
        disabled: {
          path: {
            stroke: 'var(--secondary-text-color)',
          },
          invalid: {
            borderColor: 'var(--danger-color)',
            color: 'var(--danger-color)',
          },
        },
      },
      placeholder: {
        fontSize: 14,
        color: 'var(--secondary-text-color)',
      },
      dropdown: {
        width: 'calc(100% + 20px)',
      },
      singleValue: {
        fontSize: 14,
        fontWeight: 600,
      },
      valueContainer: {
        fontSize: 14,
      },
      option: {
        marginTop: '16px',
        borderBottom: 'none !important',
        padding: '0px',
        display: 'flex',
        alignItems: 'center',

        ':last-child': {
          marginBottom: '16px',
        },
      },
    },
  },
};
