import styled from 'styled-components';

export const ValueWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  text-align: left;
  font-weight: 500;
`;

export const Placeholder = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  text-align: left;
`;

export const IconWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  svg {
    width: 12px;
    height: 12px;
  }
`;
