import React from 'react';
import {
  FormatOptionLabelContext,
  FormatOptionLabelMeta,
} from 'react-select/dist/declarations/src/Select';

import { IThemeNamespace } from '@xcritical/theme';
import { IOptionItem, ISelectBaseTheme, IThemeProps } from '@xcritical/select';

import { SelectedIcon } from '../Icons';

import {
  LabelPostfix,
  LabelPrefix,
  OptionWrapper,
  SelectedIconWrapper,
} from './styled';

interface IOptionProps extends IThemeProps {
  classNamePrefix?: string;
  isMulti: boolean;
  prefix?: React.ReactElement;
  postfix?: React.ReactElement;
  value: IOptionItem['value'];
  selectedOption: IOptionItem | IOptionItem[] | null;
  context: FormatOptionLabelContext;
}

const Option: React.FC<IOptionProps> = React.memo<IOptionProps>(
  ({
    classNamePrefix,
    value,
    selectedOption,
    children,
    theme,
    prefix,
    postfix,
    context,
    appearance,
    isMulti,
    baseAppearance,
  }) => {
    const isSelected =
      selectedOption &&
      (isMulti
        ? (selectedOption as IOptionItem[]).find(
            (option) => option.value === value
          )
        : (selectedOption as IOptionItem).value === value);

    return (
      <OptionWrapper
        appearance={appearance}
        baseAppearance={baseAppearance}
        className={classNamePrefix && `${classNamePrefix}__option`}>
        {prefix && (
          <LabelPrefix
            appearance={appearance}
            baseAppearance={baseAppearance}
            className={classNamePrefix && `${classNamePrefix}__option-prefix`}>
            {prefix}
          </LabelPrefix>
        )}

        <span className={classNamePrefix && `${classNamePrefix}__option-span`}>
          {children}
        </span>

        {postfix && (
          <LabelPostfix
            appearance={appearance}
            baseAppearance={baseAppearance}
            className={classNamePrefix && `${classNamePrefix}__option-postfix`}>
            {postfix}
          </LabelPostfix>
        )}

        {isSelected && context === 'menu' && (
          <SelectedIconWrapper
            className={
              classNamePrefix && `${classNamePrefix}__option-selectedIcon`
            }
            theme={theme}
            appearance={appearance}
            baseAppearance={baseAppearance}>
            <SelectedIcon />
          </SelectedIconWrapper>
        )}
      </OptionWrapper>
    );
  }
);

export const getFormatOptionLabel =
  (
    classNamePrefix: string,
    theme: IThemeNamespace<ISelectBaseTheme>,
    appearance: string,
    baseAppearance: string,
    selectedOption: IOptionItem | IOptionItem[] | null,
    isMulti: boolean
  ): any =>
  (
    { label, value, ...rest }: IOptionItem,
    { context }: FormatOptionLabelMeta<IOptionProps>
  ) =>
    (
      <Option
        classNamePrefix={classNamePrefix}
        theme={theme}
        value={value}
        isMulti={isMulti}
        context={context}
        selectedOption={selectedOption}
        appearance={appearance}
        baseAppearance={baseAppearance}
        {...rest}>
        {label}
      </Option>
    );
