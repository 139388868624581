import styled from 'styled-components';

export const IconWrapper = styled.div`
  margin-top: 4px;

  svg {
    height: var(--input-postfix-size);
    width: var(--input-postfix-size);
  }
`;
