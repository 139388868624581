import styled from 'styled-components';

export const ContentWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  word-break: break-word;
  white-space: initial;
  justify-content: space-between;
`;
