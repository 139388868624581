import React, { useState } from 'react';

import { FormField } from '@xcritical/forms';

import { IconWrapper } from '@ams-package/utils';

import { InputFormField } from './Input';
import { IPasswordFormFieldProps } from './types';
import { CrossedEyeIcon, EyeIcon } from './Icons';
import { InputWrapper, EyeIconWrapper } from './styled';

export const PasswordFormField: React.FC<IPasswordFormFieldProps> = ({
  onSetValue,
  id,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const onClick = () => {
    setShowPassword(!showPassword);
  };

  return (
    <InputWrapper>
      <FormField
        {...props}
        type={showPassword ? 'string' : 'password'}
        component={InputFormField}
        baseAppearance="default"
        appearance="withManualPostfix"
        onChange={onSetValue}
        id={id}
        isClearable={false}
      />
      <EyeIconWrapper onClick={onClick}>
        <IconWrapper>
          {showPassword ? <EyeIcon /> : <CrossedEyeIcon />}
        </IconWrapper>
      </EyeIconWrapper>
    </InputWrapper>
  );
};
