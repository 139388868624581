import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAppState, IUser, IAppConfigModel, IPayoutLimits } from './types';

const initialState: Partial<IAppState> = {
  isReady: false,
  config: null,
  user: null,
  limits: null,
};

const STORE_NAME = '@app';

const appStore = createSlice({
  name: STORE_NAME,
  initialState,
  reducers: {
    finishInit: (state) => {
      state.isReady = true;
    },
    setConfig: (state, action: PayloadAction<IAppConfigModel>) => {
      state.config = action.payload;
    },
    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
    setLimits: (state, action: PayloadAction<IPayoutLimits>) => {
      state.limits = action.payload;
    },
    updateUserBalance: (
      state,
      action: PayloadAction<{ amount: number; dateTime: string }>
    ) => {
      if (state.user)
        state.user = {
          ...state.user,
          balance: action.payload.amount,
          upcomingPayoutDate: action.payload.dateTime,
        };
    },
  },
});

const START_INIT_LOGIN = `${STORE_NAME}/startInitLogin`;
const GET_USER = `${STORE_NAME}/getUser`;

export const appActions = {
  ...appStore.actions,
  getUser: createAction(GET_USER),
  startInit: createAction(START_INIT_LOGIN),
};

export const appReducer = appStore.reducer;
