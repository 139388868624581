import styled, { css } from 'styled-components';

import { colors } from '@ams-package/theme';

import { IWrapper, ISpinner } from './types';

const getRelativeStyle = (position: 'fixed' | 'absolute') => css`
  position: ${position};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const height = css`
  height: 100%;
`;

export const Wrapper = styled.div.attrs({
  className: 'loader-wrapper',
})<IWrapper>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ fixed }) => fixed && getRelativeStyle('fixed')};
  ${({ absolute }) => absolute && getRelativeStyle('absolute')};
  ${({ center }) => center && height};
  z-index: ${({ zIndex }) => zIndex};
`;

export const Spinner = styled.div.attrs({
  className: 'loader__spinner',
})<ISpinner>`
  position: relative;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  display: block;
  margin: 15px auto;
  color: #fff;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  &::after,
  &::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    width: ${({ size }) => size / 2}px;
    height: ${({ size }) => size / 2}px;
    top: 50%;
    left: 50%;
    transform: scale(0.5) translate(0, 0);
    background-color: ${colors.LIGHT_COLOR};
    border-radius: 50%;
    animation: animloader 1s infinite ease-in-out;
  }
  &::before {
    background-color: ${colors.PRIMARY_COLOR};
    transform: scale(0.5)
      translate(-${({ size }) => size}px, -${({ size }) => size}px);
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes animloader {
    50% {
      transform: scale(1) translate(-50%, -50%);
    }
  }
`;
