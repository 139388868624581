import React, {
  Context,
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  GroupBase,
  SelectComponentsConfig,
  InputActionMeta,
} from 'react-select';
import { ThemeContext } from 'styled-components';

import XCSelect, { IOptionItem, ISelectBaseTheme } from '@xcritical/select';
import { IThemeNamespace } from '@xcritical/theme';

import { ErrorWrapper } from '@ams-package/utils';

import { DropdownIndicator } from '../DropdownIndicator';
import { getFormatOptionLabel } from '../options';

import { SelectFormFieldType, ISelectOption } from './types';
import { LabelWrapper, SelectWrapper } from './styled';

export const Select = <
  Option extends ISelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  error,
  isMulti,
  theme,
  value,
  label,
  disabled,
  isHideSelectedOptions = false,
  withSelectedIcon = true,
  baseAppearance,
  components,
  shouldFitContainer = true,
  appearance: selectAppearance,
  classNamePrefix,
  ...rest
}: SelectFormFieldType<Option, IsMulti, Group>): ReactElement => {
  const [inputValue, setInputValue] = useState('');
  const themeContext = useContext<IThemeNamespace<ISelectBaseTheme>>(
    ThemeContext as Context<IThemeNamespace<ISelectBaseTheme>>
  );
  const innerTheme = (theme ?? themeContext) || {};

  const onInputChange = useCallback(
    (newInputValue: string, { action }: InputActionMeta) => {
      if (action === 'set-value') {
        setInputValue('');
      }

      if (action === 'input-change') {
        setInputValue(newInputValue);
      }
    },
    []
  );

  const onMenuClose = useCallback(() => {
    setInputValue('');
  }, []);

  const saveInputTextProps = isMulti
    ? { inputValue, onInputChange, onMenuClose }
    : {};

  const formatOptionLabel = useMemo(
    () =>
      getFormatOptionLabel(
        classNamePrefix ?? '',
        innerTheme,
        selectAppearance ?? 'default',
        baseAppearance ?? 'default',
        value as IOptionItem | IOptionItem[],
        !!isMulti
      ),
    [value, innerTheme, selectAppearance, baseAppearance, innerTheme, isMulti]
  );

  const customComponents = useMemo(() => {
    const $components: SelectComponentsConfig<
      Option | undefined,
      IsMulti,
      Group
    > = {
      DropdownIndicator,
    };

    return {
      ...$components,
      ...(components ?? {}),
    } as any;
  }, []);

  return (
    <ErrorWrapper content={error ?? ''}>
      <SelectWrapper>
        {label && <LabelWrapper disabled={!!disabled}>{label}</LabelWrapper>}
        <XCSelect
          value={value}
          isMulti={isMulti}
          disabled={!!disabled}
          components={customComponents}
          classNamePrefix={classNamePrefix}
          closeMenuOnSelect={!isMulti}
          isHideSelectedOptions={!!isHideSelectedOptions}
          shouldFitContainer={!!shouldFitContainer}
          appearance={selectAppearance ?? 'default'}
          baseAppearance={baseAppearance ?? 'default'}
          {...(withSelectedIcon ? { formatOptionLabel } : {})}
          {...(saveInputTextProps as any)}
          {...rest}
        />
      </SelectWrapper>
    </ErrorWrapper>
  );
};
