import React from 'react';

import { IPopover, Popover } from '@xcritical/popover';
import { Position } from '@xcritical/popper';

import { CopyIconButton } from '@ams-package/button';

import { ContentWrapper } from './styled';

export const Tooltip: React.FC<
  Omit<IPopover, 'content' | 'position'> & {
    value: string;
    position?: Position;
    widthCopyButton?: string;
  }
> = ({
  position = 'top center',
  value,
  widthCopyButton = false,
  shouldFitContainer = false,
  appearance = 'tooltip',
  children,
  ...rest
}) => (
  <Popover
    position={position}
    trigger="hover"
    appearance={appearance}
    shouldFitContainer={!!shouldFitContainer}
    content={
      <ContentWrapper>
        {value}
        {widthCopyButton && (
          <CopyIconButton
            shouldFitContent={false}
            text={value}
            appearance="link"
          />
        )}
      </ContentWrapper>
    }
    {...rest}>
    {children}
  </Popover>
);
