import range from 'lodash.range';

import { getThemedState, IThemeNamespace } from '@xcritical/theme';
import { selectThemeNamespace, ISelectBaseTheme } from '@xcritical/select';
import { buttonThemeNamespace, ButtonTheme } from '@xcritical/button';

import { IPaginationTheme, IOption } from './interfaces';
import { paginationThemeNamespace, defaultPaginationTheme } from './theme';

export const getPaginationStyles = (
  theme?: IThemeNamespace<IPaginationTheme>,
  propertyPath?: string[]
): any => {
  const func = getThemedState(paginationThemeNamespace, defaultPaginationTheme);

  return func(theme, propertyPath);
};

export const getPaginationButtonTheme = (
  theme?: IThemeNamespace<IPaginationTheme>
): IThemeNamespace<ButtonTheme> => ({
  [buttonThemeNamespace]: {
    appearance: {
      paginationButton: getPaginationStyles(theme, ['button']),
    },
  },
});

export const getPaginationSelectTheme = (
  theme?: IThemeNamespace<IPaginationTheme>
): IThemeNamespace<ISelectBaseTheme> => ({
  [selectThemeNamespace]: {
    appearance: {
      paginationSelect: getPaginationStyles(theme, ['select']),
    },
  },
});

export const mapOption = (pageSize: number): IOption => ({
  value: pageSize,
  label: pageSize,
});

export const getVisiblePagesArray = (totalPages: number): number[] =>
  range(1, totalPages + 1);
