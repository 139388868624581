import wretch from 'wretch';

import { authorizedWretch } from '@ams-package/utils';

import {
  IAppConfigModel,
  IPayoutLimits,
  IUser,
  IUserBalanceModel,
} from './types';

export async function apiGetAppConfig(): Promise<IAppConfigModel> {
  const response = await wretch('/api/client-config').get().res();

  const config: IAppConfigModel = await response.json();

  return config;
}

export const apiGetUser = async (): Promise<IUser> =>
  authorizedWretch('/api/me').get().json();

export const apiGetUserBalance = async (): Promise<IUserBalanceModel> =>
  authorizedWretch('/api/balance').get().json<IUserBalanceModel>();

export const apiGetPayoutLimits = async (): Promise<IPayoutLimits> =>
  authorizedWretch('/api/payouts/limits').get().json<IPayoutLimits>();
