import { css, styled } from 'styled-components';

import { classNameWithPrefix } from '@crm-framework/utils';

const panelVariables = css`
  --panel-background-color: var(--dark-grey);
  --panel-padding: 24px;
  --panel-border-radius: var(--default-border-radius);
`;

const blockName = 'panel';

export const PanelWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'wrapper'),
})`
  ${panelVariables};
  display: flex;
  width: 100%;
  height: fit-content;
  padding: var(--panel-padding);
  background-color: var(--panel-background-color);
  border-radius: var(--panel-border-radius);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 50px;
`;
