import React, { memo, useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router';

import { useQueryParam } from '@ams-package/utils';

import { ITabPanel, ITabsProps } from './types';
import { Tabs } from './Tabs';

export const TabsWithNavigation: React.FC<ITabsProps> = memo(
  ({ tabPanels, onChange, ...props }) => {
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const navigate = useNavigate();
    const tabName = useQueryParam('tabName');

    const onChangeNavigation = useCallback(
      (newActiveTabIndex: number) => {
        setActiveTabIndex(newActiveTabIndex);

        const tabUrl = tabPanels[newActiveTabIndex]?.url;

        if (tabUrl) navigate(tabUrl);
      },
      [tabPanels]
    );

    useEffect(() => {
      const initialTabIndex = tabPanels.findIndex(({ key }) => key === tabName);
      const index = initialTabIndex >= 0 ? initialTabIndex : 0;

      onChangeNavigation(index);
    }, []);

    const handleChangeTab = useCallback(
      (key: string | number, index: number, tab: ITabPanel) => {
        onChange?.(key, index, tab);
        onChangeNavigation(index);
      },
      [tabPanels]
    );

    return (
      <Tabs
        tabPanels={tabPanels}
        onChange={handleChangeTab}
        defaultActiveIndex={activeTabIndex}
        {...props}
      />
    );
  }
);
