import React from 'react';

import { EmptyStateWrapper, EmptyStateDescriptionWrapper } from './styled';

export const EmptyState: React.FC = () => (
  <EmptyStateWrapper>
    <h2>No data found</h2>
    <EmptyStateDescriptionWrapper>
      Try changing the filters and search again
    </EmptyStateDescriptionWrapper>
  </EmptyStateWrapper>
);
