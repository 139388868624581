import React from 'react';
import { Grid } from 'styled-css-grid';

import Form, { FormField } from '@xcritical/forms';

import { DictionarySelect } from '@ams-package/select';
import { InputFormField } from '@ams-package/input';
import { Label } from '@ams-package/label';
import {
  IPayoutPaymentMethodConfigurationModel,
  IPayoutPaymentMethodFieldConfigurationModel,
} from '@ams-package/dictionaries';

import { STORE_FORM_NAME } from '../../store';

function getFormField(field: IPayoutPaymentMethodFieldConfigurationModel) {
  switch (field.type) {
    case 'dictionary':
      return (
        <FormField
          id={field.name}
          dictionaryName={field.name.toLowerCase()}
          name={field.name}
          shouldFitContainer
          isSearchable
          isClearable
          component={DictionarySelect}
        />
      );
    default:
      return (
        <FormField
          id={field.name}
          name={field.name}
          shouldFitContainer
          isClearable
          component={InputFormField}
        />
      );
  }
}

export const PaymentEditor: React.FC<{
  method: IPayoutPaymentMethodConfigurationModel;
}> = ({ method }) => (
  <Form name={STORE_FORM_NAME}>
    <Grid columns="1fr" gap="8px">
      {method.fields.map((field) => (
        <div key={field.name}>
          <Label isRequired={field.required} htmlFor={field.name}>
            {field.title}
          </Label>
          {getFormField(field)}
        </div>
      ))}
    </Grid>
  </Form>
);
