import React from 'react';

export const CheckIcon = ({
  className,
  stroke = 'var(--primary-color)',
}: {
  className?: string;
  stroke?: string;
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none">
    <path
      d="M2.33203 6.98517L5.29357 9.91666L11.6654 4.08333"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const RadioIcon = ({
  className,
  stroke = 'var(--primary-color)',
}: {
  className?: string;
  stroke?: string;
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none">
    <circle cx="10" cy="10" r="4" fill={stroke} />
    <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke={stroke} />
  </svg>
);
