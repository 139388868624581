import React, { memo, useCallback, useEffect, useState } from 'react';

import PureGrid, { IGridProps as IPureGridProps } from '@xcritical/grid';

import { Pagination, IPagination } from '@ams-package/pagination';
import { Loader } from '@ams-package/loader';

import { GridWrapper, Wrapper } from './styled';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZES } from './consts';
import { EmptyState } from './EmptyState';

export interface IGridProps extends IPureGridProps {
  totalItems?: number;
  withPagination?: boolean;
  isLoading?: boolean;
  initialPage?: IPagination['currentPage'];
  pageSize?: IPagination['pageSize'];
  pageSizeOptions?: IPagination['pageSizeOptions'];
  onChangePage?: IPagination['onChangePage'];
  onChangePageSize?: IPagination['onChangePageSize'];
}

export const Grid: React.VFC<IGridProps> = memo(
  ({
    items = [],
    initialPage = 1,
    columns,
    totalItems,
    onChangePage,
    onChangePageSize,
    pageSize,
    isLoading,
    pageSizeOptions = DEFAULT_PAGE_SIZES,
    withPagination = true,
    ...rest
  }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [gridProps, setGridProps] = useState({});

    const scrollToTop = useCallback(() => {
      setTimeout(() => {
        setGridProps((prevState) => ({ ...prevState, scrollTop: 0 }));
        setGridProps((prevState: any) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { scrollTop, ...newState } = prevState;

          return newState;
        });
      });
    }, [setGridProps]);

    const onChangePageHandler = useCallback(
      (page: number) => {
        setCurrentPage(page);

        scrollToTop();

        if (onChangePage) {
          onChangePage(page);
        }
      },
      [scrollToTop, onChangePage, setCurrentPage]
    );

    const onChangePageSizeHandler = useCallback(
      (page: number, newPageSize: number) => {
        setCurrentPageSize(newPageSize);
        setCurrentPage(page);
        scrollToTop();
        onChangePageSize?.(page, newPageSize);
      },
      [scrollToTop, onChangePageSize, setCurrentPage, setCurrentPageSize]
    );

    useEffect(() => {
      if (pageSize) {
        setCurrentPageSize(pageSize);
      }

      if (initialPage) {
        setCurrentPage(initialPage);
      }
    }, [pageSize, initialPage]);

    return (
      <Wrapper filled={!!items.length}>
        {isLoading ? (
          <Loader absolute />
        ) : (
          <>
            <GridWrapper>
              {!items.length ? (
                <EmptyState />
              ) : (
                <PureGrid
                  isDisableSelect
                  columns={columns}
                  gridProps={gridProps}
                  shouldFitContainer
                  shouldMovingColumns={false}
                  items={items}
                  {...rest}
                />
              )}
            </GridWrapper>
            {withPagination && !!items.length && (
              <Pagination
                currentPage={currentPage}
                pageSize={currentPageSize}
                showTotals
                showSizeChanger
                total={totalItems ?? items.length}
                pageSizeOptions={pageSizeOptions}
                pageSizeProps={{
                  menuPlacement: 'top',
                }}
                onChangePageSize={onChangePageSizeHandler}
                onChangePage={onChangePageHandler}
              />
            )}
          </>
        )}
      </Wrapper>
    );
  }
);
