import {
  IStoreWithAppState,
  IAppState,
  IAppConfigModel,
  IUser,
  IManager,
  IPayoutLimits,
} from './types';

export const getAppState = (state: IStoreWithAppState): IAppState => state.app;

export const getAppConfig = (
  state: IStoreWithAppState
): IAppConfigModel | null => state.app.config;

export const getUser = (state: IStoreWithAppState): IUser | null =>
  state.app.user;

export const getManagerContacts = (
  state: IStoreWithAppState
): IManager | null => state.app.user?.managerContacts ?? null;

export const getAffiliateId = (state: IStoreWithAppState): number | null =>
  state.app.user?.id ?? null;

export const getLimits = (state: IStoreWithAppState): IPayoutLimits | null =>
  state.app.limits;
