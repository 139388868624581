export enum RequiredPosition {
  left = 'left',
  right = 'right',
}

export interface ILabelProps {
  isRequired?: boolean;
  lineHeight?: string;
  htmlFor?: string;
  requiredPosition?: RequiredPosition;
}
