import React from 'react';

export const CrossedEyeIcon = ({
  className,
  stroke = 'var(--base-white)',
}: {
  className?: string;
  stroke?: string;
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="var(--input-postfix-size)"
    height="var(--input-postfix-size)"
    viewBox="0 0 20 20"
    fill="none">
    <path
      d="M8.95245 4.2436C9.29113 4.19353 9.64051 4.16667 10.0003 4.16667C14.2545 4.16667 17.0461 7.9207 17.9839 9.40569C18.0974 9.58542 18.1542 9.67528 18.1859 9.81389C18.2098 9.91799 18.2098 10.0822 18.1859 10.1863C18.1541 10.3249 18.097 10.4154 17.9827 10.5963C17.7328 10.9918 17.3518 11.5476 16.8471 12.1504M5.6036 5.59586C3.80187 6.81808 2.57871 8.51615 2.01759 9.4044C1.90357 9.58489 1.84656 9.67514 1.81478 9.81373C1.79091 9.91783 1.7909 10.082 1.81476 10.1861C1.84652 10.3247 1.90328 10.4146 2.01678 10.5943C2.95462 12.0793 5.74618 15.8333 10.0003 15.8333C11.7157 15.8333 13.1932 15.223 14.4073 14.3972M2.50035 2.5L17.5003 17.5M8.23258 8.23223C7.78017 8.68464 7.50035 9.30964 7.50035 10C7.50035 11.3807 8.61963 12.5 10.0003 12.5C10.6907 12.5 11.3157 12.2202 11.7681 11.7678"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const EyeIcon = ({
  className,
  stroke = 'var(--base-white)',
}: {
  className?: string;
  stroke?: string;
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="var(--input-postfix-size)"
    height="var(--input-postfix-size)"
    viewBox="0 0 20 20"
    fill="none">
    <path
      d="M2.01677 10.5943C1.90328 10.4146 1.84654 10.3248 1.81477 10.1862C1.79091 10.0821 1.79091 9.91791 1.81477 9.81381C1.84654 9.67522 1.90328 9.58537 2.01677 9.40567C2.95461 7.92069 5.74617 4.16666 10.0003 4.16666C14.2545 4.16666 17.0461 7.92069 17.9839 9.40567C18.0974 9.58537 18.1541 9.67522 18.1859 9.81381C18.2098 9.91791 18.2098 10.0821 18.1859 10.1862C18.1541 10.3248 18.0974 10.4146 17.9839 10.5943C17.0461 12.0793 14.2545 15.8333 10.0003 15.8333C5.74617 15.8333 2.95461 12.0793 2.01677 10.5943Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0003 12.5C11.381 12.5 12.5003 11.3807 12.5003 9.99999C12.5003 8.61928 11.381 7.49999 10.0003 7.49999C8.61962 7.49999 7.50034 8.61928 7.50034 9.99999C7.50034 11.3807 8.61962 12.5 10.0003 12.5Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DotIcon = ({
  className,
  stroke = 'var(--popover-text-color)',
}: {
  className?: string;
  stroke?: string;
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none">
    <path
      d="M12.638 10C12.638 10.3125 12.5505 10.6084 12.3964 10.8584C12.1089 11.3417 11.5797 11.6667 10.9714 11.6667C10.363 11.6667 9.83386 11.3417 9.54635 10.8584C9.39219 10.6084 9.30469 10.3125 9.30469 10C9.30469 9.07921 10.0505 8.33337 10.9714 8.33337C11.8922 8.33337 12.638 9.07921 12.638 10Z"
      fill={stroke}
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CheckIcon = ({
  className,
  stroke = 'var(--primary-color)',
}: {
  className?: string;
  stroke?: string;
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none">
    <path
      d="M4.30469 9.97887L8.53546 14.1667L17.638 5.83337"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ClearIcon = ({
  className,
  stroke = 'var(--base-white)',
}: {
  className?: string;
  stroke?: string;
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="var(--input-postfix-size)"
    height="var(--input-postfix-size)"
    viewBox="0 0 20 20"
    fill="none">
    <path
      d="M 142 -60.59 L 140.59 -62 L 135 -56.41 L 129.41 -62 L 128 -60.59 L 133.59 -55 L 128 -49.41 L 129.41 -48 L 135 -53.59 L 140.59 -48 L 142 -49.41 L 136.41 -55 Z"
      fill={stroke}
      transform="translate(-128, 62)"
    />
  </svg>
);
