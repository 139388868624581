import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '@xcritical/button';

import { StatusMessage, StatusModalTypes } from '@ams-package/status-message';
import { PathNames } from '@ams-package/router';

import { paymentEditorActions } from '../store';
import { paymentEditorSelector } from '../selectors';
import { PaymentEditorMode } from '../types';

import { PaymentMethodWrapper } from './payment-method';

const StatusMessageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export type StatusMessagesType = {
  [key: string]: string;
};

export type SuccessStateProps = {
  statusMessages?: StatusMessagesType;
};

const defaultMessages = {
  [PaymentEditorMode.Delete]: () => 'Payment method was deleted successfully',
  [PaymentEditorMode.AddOrEdit]: (selectedId?: number) =>
    selectedId
      ? 'Payment method saved successfully'
      : 'New payment method added successfully',
};

export const SuccessState: React.FC<SuccessStateProps> = ({
  statusMessages,
}) => {
  const dispatch = useDispatch();
  const { mode, statusMessageKey, selectedId } = useSelector(
    paymentEditorSelector
  );

  const handlerGoToBack = () => {
    dispatch(paymentEditorActions.close());
  };

  return (
    <PaymentMethodWrapper>
      <StatusMessageWrapper>
        <StatusMessage
          type={StatusModalTypes.Success}
          title={
            statusMessageKey && statusMessages
              ? statusMessages[statusMessageKey]
              : mode && defaultMessages[mode]?.(selectedId)
          }
        />
      </StatusMessageWrapper>
      <Button shouldFitContent onClick={handlerGoToBack}>
        {window.location.pathname === PathNames.accountSettings
          ? 'Return to Account Settings'
          : 'Return to Payouts'}
      </Button>
    </PaymentMethodWrapper>
  );
};
