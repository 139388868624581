import React from 'react';

export const LinkAndLandingIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.57873 1.57897C2.69079 0.466916 4.35486 0.041748 6.49984 0.041748H11.4998C13.6448 0.041748 15.3089 0.466916 16.4209 1.57897C17.533 2.69103 17.9582 4.3551 17.9582 6.50008V11.5001C17.9582 13.6451 17.533 15.3091 16.4209 16.4212C15.3089 17.5332 13.6448 17.9584 11.4998 17.9584H6.49984C4.35486 17.9584 2.69079 17.5332 1.57873 16.4212C0.466672 15.3091 0.0415039 13.6451 0.0415039 11.5001V6.50008C0.0415039 4.3551 0.466672 2.69103 1.57873 1.57897ZM2.46261 2.46286C1.69967 3.2258 1.2915 4.47839 1.2915 6.50008V11.5001C1.2915 13.5218 1.69967 14.7744 2.46261 15.5373C3.22556 16.3002 4.47815 16.7084 6.49984 16.7084H11.4998C13.5215 16.7084 14.7741 16.3002 15.5371 15.5373C16.3 14.7744 16.7082 13.5218 16.7082 11.5001V6.50008C16.7082 4.47839 16.3 3.2258 15.5371 2.46286C14.7741 1.69991 13.5215 1.29175 11.4998 1.29175H6.49984C4.47815 1.29175 3.22556 1.69991 2.46261 2.46286Z"
      stroke="transparent"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.19971 5.40015C7.19971 5.05497 7.47953 4.77515 7.82471 4.77515H11.358C11.7032 4.77515 11.983 5.05497 11.983 5.40015V8.94181C11.983 9.28699 11.7032 9.56681 11.358 9.56681C11.0129 9.56681 10.733 9.28699 10.733 8.94181V6.02515H7.82471C7.47953 6.02515 7.19971 5.74532 7.19971 5.40015Z"
      stroke="transparent"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8002 4.9582C12.0443 5.20228 12.0443 5.59801 11.8002 5.84209L7.08354 10.5588C6.83947 10.8028 6.44374 10.8028 6.19966 10.5588C5.95558 10.3147 5.95558 9.91895 6.19966 9.67487L10.9163 4.9582C11.1604 4.71413 11.5561 4.71413 11.8002 4.9582Z"
      stroke="transparent"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.40691 12.5604C3.51631 12.233 3.8704 12.0563 4.19778 12.1657C7.31088 13.2061 10.6885 13.2061 13.8016 12.1657C14.129 12.0563 14.483 12.233 14.5925 12.5604C14.7019 12.8878 14.5252 13.2418 14.1978 13.3512C10.8275 14.4775 7.17182 14.4775 3.80158 13.3512C3.4742 13.2418 3.2975 12.8878 3.40691 12.5604Z"
      stroke="transparent"
    />
  </svg>
);
