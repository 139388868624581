import React from 'react';

import { FailureIcon, SuccessIcon } from './Icons';
import {
  ButtonsWrapper,
  DescriptionWrapper,
  MessageWrapper,
  TittleWrapper,
} from './styled';
import { StatusModalTypes } from './consts';

export type StatusMessageType = {
  title: string;
  type: StatusModalTypes;
  description?: string;
  buttons?: React.ReactNode;
};

export const StatusMessage: React.VFC<StatusMessageType> = ({
  type,
  title,
  description,
  buttons,
}) => (
  <>
    <MessageWrapper>
      {type === StatusModalTypes.Success ? <SuccessIcon /> : <FailureIcon />}
      <TittleWrapper>{title}</TittleWrapper>
      {description && <DescriptionWrapper>{description}</DescriptionWrapper>}
    </MessageWrapper>
    {buttons && <ButtonsWrapper>{buttons}</ButtonsWrapper>}
  </>
);
