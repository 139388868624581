import { css } from 'styled-components';

export const DatePicker = (datePickerStyles) => css`
  .react-datepicker {
    ${datePickerStyles}
  }
`;

export const HeaderContainer = (headerContainerStyles) => css`
  .react-datepicker__header {
    ${headerContainerStyles}
  }
`;

export const Wrapper = (wrapperStyles) => css`
  .react-datepicker-wrapper {
    ${wrapperStyles}
  }
`;

export const Triangle = (triangleStyles) => css`
  .react-datepicker__triangle {
    ${triangleStyles}
  }
`;

export const NavigationArrows = (navigationArrowsStyles) => css`
  .react-datepicker__navigation-arrows {
    ${navigationArrowsStyles}
  }
`;
