import { format } from 'date-fns';

export const getDateRangeString = (startDate: Date, endDate: Date) => {
  const startDateString = format(startDate, 'MMMM d');
  const endDateString = format(endDate, 'MMMM d');

  if (startDate.getMonth() === endDate.getMonth()) {
    if (startDate.getDate() === endDate.getDate()) {
      return startDateString;
    }

    return `${startDateString}-${format(endDate, 'd')}`;
  }

  return `${startDateString}-${endDateString}`;
};
