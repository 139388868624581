import React, { useCallback, useMemo } from 'react';

import { makeArrayWithUid } from '@crm-framework/utils';

import { ISkeleton } from './types';
import { StyledDiv } from './styled';

export const Skeleton: React.FC<ISkeleton> = ({
  amount = 1,
  wrapperComponent: Wrapper,
  wrapperProps = {},
  ...props
}) => {
  const uuidArray = useMemo(() => makeArrayWithUid(amount), [amount]);
  const createComponent = useCallback(
    (key: string) =>
      Wrapper ? (
        <Wrapper key={key} {...wrapperProps}>
          <StyledDiv {...props} />
        </Wrapper>
      ) : (
        <StyledDiv key={key} {...props} />
      ),
    [Wrapper, props, wrapperProps]
  );

  return <>{uuidArray.map((id) => createComponent(id))}</>;
};
