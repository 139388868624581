import React, { memo, useCallback, useState } from 'react';

import { GridSort } from '@xcritical/grid';

import { DashboardSortingType } from '@ams-package/dashboard';

import { BaseCell } from '../base-cell';

import { HeaderName } from './styled';
import { SortIcon } from './SortIcon';

interface IBaseCell {
  label: string;
  name: string;
  sortable?: boolean;
  sorting: DashboardSortingType | null;
  onSort: (column: string, direction: GridSort | null) => void;
}

export const HeaderCell: React.VFC<IBaseCell> = memo(
  ({ label, name, sortable = false, sorting, onSort }) => {
    const [prevDirection, setPrevDirection] = useState<GridSort | null>(null);

    const onSortHandler = useCallback(() => {
      if (!sortable) return;

      const nextDirection =
        sorting?.sortOrder === GridSort.ASC ? GridSort.DESC : GridSort.ASC;

      if (prevDirection === GridSort.DESC) {
        onSort(name, null);
        setPrevDirection(null);

        return;
      }

      onSort(
        name,
        sorting?.sortOrder === GridSort.ASC ? GridSort.DESC : GridSort.ASC
      );

      setPrevDirection(nextDirection);
    }, [onSort, name, sorting, prevDirection, sortable]);

    return (
      <BaseCell onClick={onSortHandler}>
        <HeaderName>{label}</HeaderName>
        {sortable && (
          <SortIcon
            sortOrder={sorting?.sortedBy === name ? sorting.sortOrder : null}
          />
        )}
      </BaseCell>
    );
  }
);
