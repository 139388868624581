import styled from 'styled-components';

import { classNameWithPrefix } from '@crm-framework/utils';

const blockName = 'input';

export const InputWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'wrapper'),
})`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-items: baseline;
`;

export const EyeIconWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'eye-icon-wrapper'),
})`
  margin: 12px 0 0 -35px;
  cursor: pointer;
`;

export const PopoverContentWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'popover-content-wrapper'),
})`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const PopoverLineWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'popover-line-wrapper'),
})`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20px;
  gap: 4px;
  flex-basis: 25px;
`;
