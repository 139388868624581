import { IGridTheme } from '@xcritical/grid';

import { DEFAULT_FONT_SIZE } from './sizes';

const defaultBorder = `none`;

export const gridTheme: IGridTheme = {
  evenRowBackground: 'var(--grid-background)',
  movingHeaderCellBackgroungColor: 'var(--grid-background)',
  movingHeaderCellColor: 'var(--secondary-text-color)',
  selectedRowBackgroundColor: 'var(--grid-background)',
  border: defaultBorder,
  headerCellBorder: 'none',
  totalsCellBorder: 'none',
  rowCellBorder: 'none',
  header: {
    border: 'none',
    fontSize: DEFAULT_FONT_SIZE,
    color: 'var(--secondary-text-color)',
    backgroundColor: 'var(--secondary-color)',
    fontWeight: 400,
    height: 42,
    borderRadius: 12,
    padding: '0px 20px',
    overflow: 'hidden',
  },
  row: {
    backgroundColor: 'var(--grid-background)',
    border: '1px solid var(--border-color);',
    fontWeight: 400,
    padding: '0 20px',
    fontSize: DEFAULT_FONT_SIZE,
    color: 'var(--secondary-text-color)',
  },
  totals: {},
};
