import { css } from 'styled-components';

import { body3SBStyle, generateFontStyle } from './fonts';

export const badgeCssVariable = css`
  --badge-info-background: var(--info-color);
  --badge-info-color: var(--base-white);
  --badge-primary-background: var(--primary-color);
  --badge-primary-color: var(--black);
  --badge-danger-background: var(--danger-text-color);
  --badge-danger-color: var(--base-white);

  --badge-padding: 6px 12px;
  @media (min-width: 1920px) {
    --badge-padding: 6px 12px;
  }
  @media (max-width: 1200px) {
    --badge-padding: 2px 8px;
  }

  ${body3SBStyle}
`;

export const badgeTheme = {
  appearance: {
    default: {
      padding: 'var(--badge-padding)',
      ...generateFontStyle('body-3sb'),
      borderRadius: '73px',
    },
    info: {
      color: 'var(--badge-info-color)',
      background: 'var(--badge-info-background)',
    },
    primary: {
      color: 'var(--badge-primary-color)',
      background: 'var(--badge-primary-background)',
    },
    danger: {
      color: 'var(--badge-danger-color)',
      background: 'var(--badge-danger-background)',
    },
  },
};
