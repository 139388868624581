// eslint-disable-next-line no-useless-escape
export const EMAIL_REG_EXP = /^[\w-\.]+@([\w-]+\.)+[\w-]{1,}$/;
export const PHONE_VALIDATION_PATTERN = '^[0-9]*$';
export const PHONE_REG_EXP = /^[0-9]*$/;
export const PASSWORD_REG_EXP =
  // eslint-disable-next-line no-useless-escape
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-\$&\+,:;=\?\*\"\{\}@#|'<>\.*()\[%!\^\]\\\/№])[A-Za-z\d\$&\+,-:;=\?\*\"\{\}@#|'<>\.*()\[%!\^\]\\\/№]{6,}$/;

export type IsRequiredMessageType = { isRequiredMessage?: string };
export type IsValidationMessage = { isValidMessage?: string };
export type IsTooShortMessage = { IsTooShortMessage?: string };
export type IsTooLongMessage = { IsTooLongMessage?: string };

export enum Conditions {
  all = 'all',
  isRequired = 'isRequired',
  isEmail = 'isEmail',
  isPhone = 'isPhone',
  isPassword = 'isPassword',
}

export const MIN_PHONE_LENGTH = 8;
export const MAX_PHONE_LENGTH = 27;

export interface IValidationParams<
  T extends IsRequiredMessageType = IsRequiredMessageType,
  ValueType extends string = string
> {
  value?: ValueType;
  message?: T;
  conditions?: Conditions[];
}

export const validateIsRequired = ({ value, message }: IValidationParams) => {
  if (!value) {
    return message?.isRequiredMessage ?? 'Required Field';
  }

  return undefined;
};

export const validateEmail = ({
  value,
  message,
  conditions = [Conditions.isEmail, Conditions.isRequired],
}: IValidationParams<IsRequiredMessageType & IsValidationMessage>) => {
  if (conditions.includes(Conditions.isRequired)) {
    const error = validateIsRequired({ value, message });

    if (error) return error;
  }

  if (
    value &&
    !EMAIL_REG_EXP.test(value) &&
    conditions.includes(Conditions.isPassword)
  ) {
    return message?.isValidMessage ?? 'Email is not valid';
  }

  return undefined;
};

export const validatePassword = ({
  value,
  message,
  conditions = [Conditions.isRequired, Conditions.isPassword],
}: IValidationParams<IsRequiredMessageType & IsValidationMessage>) => {
  if (conditions.includes(Conditions.isRequired)) {
    const error = validateIsRequired({ value, message });

    if (error) return error;
  }

  if (
    value &&
    !PASSWORD_REG_EXP.test(value) &&
    conditions.includes(Conditions.isPassword)
  ) {
    return (
      message?.isValidMessage ?? 'Password is invalid, please read the hint'
    );
  }

  return undefined;
};

export const validatePhone = ({
  value,
  message,
  conditions = [Conditions.isRequired, Conditions.isPhone],
}: IValidationParams<
  IsRequiredMessageType &
    IsValidationMessage &
    IsTooShortMessage &
    IsTooLongMessage
>) => {
  if (conditions.includes(Conditions.isRequired)) {
    const error = validateIsRequired({ value, message });

    if (error) return error;
  }

  if (conditions.includes(Conditions.isPhone)) {
    if (value && !PHONE_REG_EXP.test(value)) {
      return message?.isValidMessage ?? 'Phone is not valid';
    }

    if (value && value.length < MIN_PHONE_LENGTH) {
      return (
        message?.isValidMessage ??
        `Phone should be more than ${MIN_PHONE_LENGTH} digits`
      );
    }

    if (value && value.length > MAX_PHONE_LENGTH) {
      return (
        message?.isValidMessage ??
        `Phone should be less than ${MAX_PHONE_LENGTH} digits`
      );
    }
  }

  return undefined;
};
