import { CheckboxTheme } from '@xcritical/checkbox';

const radioCheckboxTheme = {
  checkboxLabel: {
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: 'var(--default-text-color)',
  },
  checkboxWrapper: {
    color: 'var(--primary-color)',
    border: `1px solid var(--default-text-color)`,
    borderRadius: '4px',
    margin: '8px',
    ':hover': {
      background: 'transparent !important',
    },
  },
};

export const checkboxTheme: CheckboxTheme = {
  appearance: {
    default: {
      checkboxWrapper: {
        width: '20px',
        height: '20px',
        padding: '3px',
        border: `1px solid var(--default-text-color)`,
        borderRadius: '6px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
      },
      labelWrapper: {
        marginLeft: '5px',
      },
      checkbox: {
        marginLeft: '2px',
        color: 'var(--primary-color)',
      },
    },
    checked: {
      checkboxWrapper: {
        border: `1px solid var(--primary-color)`,
      },
    },
    radioCheckbox: radioCheckboxTheme,
    'filter-checkbox-checked': {
      labelWrapper: {
        marginLeft: '8px',
      },
      checkboxWrapper: {
        border: `1px solid var(--primary-color)`,
      },
    },
    'filter-checkbox': {
      labelWrapper: {
        marginLeft: '8px',
      },
    },
    'radio-checkbox': radioCheckboxTheme,
    'filter-radio-checkbox': {
      ...radioCheckboxTheme,
      checkboxWrapper: {
        ...radioCheckboxTheme.checkboxWrapper,
        margin: '0 8px 0 0',
      },
      labelWrapper: {
        marginLeft: 0,
      },
    },
  },
};
