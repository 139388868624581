import React from 'react';

export const MyOffersIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none">
    <path
      d="M3.45016 11.05C3.74183 11.3417 3.90849 11.925 3.81683 12.3333L3.38349 14.2083C3.35016 14.375 3.12276 16.1336 3.24183 16.4583C3.33349 16.7083 3.47516 16.9 3.66683 17.0417C4.17516 17.4167 4.96683 17.325 5.89183 16.7833L8.33349 15.3333C8.71683 15.1083 9.35016 15.1083 9.73349 15.3333L12.1752 16.7833C12.7252 17.1083 13.2252 17.2667 13.6502 17.2667C13.9418 17.2667 14.1918 17.1917 14.4002 17.0417C14.9168 16.6667 15.0668 15.8833 14.8252 14.8417L14.2502 12.3333C14.1502 11.925 14.3168 11.35 14.6168 11.05L16.6335 9.025C17.2668 8.39167 17.5168 7.71667 17.3335 7.13334C17.1418 6.55 16.5502 6.15 15.6668 6L14.8252 5.85834C14.3002 5.76667 12.8835 5.53334 12.6418 5.39167C12.4002 5.25 12.1752 5.04167 12.0668 4.825L10.6335 1.95001C10.2168 1.12501 9.65016 0.666672 9.03349 0.666672C8.41683 0.666672 7.85016 1.12501 7.43349 1.95001L6.00016 4.825C5.82516 5.16667 5.36683 5.50001 4.99183 5.56667L2.39183 6C1.50849 6.15 0.916826 6.55001 0.725159 7.13334C0.541826 7.71667 0.791826 8.39167 1.42516 9.025L3.45016 11.05Z"
      stroke="transparent"
    />
  </svg>
);
