import React from 'react';

export const ChevronIcon = ({
  className,
  stroke = 'var(--base-white)',
}: {
  className?: string;
  stroke?: string;
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none">
    <path
      d="M12.4993 16.6666L7.14065 11.1784C6.5078 10.5303 6.5078 9.46968 7.14065 8.82153L12.4993 3.33331"
      stroke={stroke}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
