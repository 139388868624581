import { ISelectOption } from '@ams-package/select';
import { DatePickerRangeType } from '@ams-package/datepicker';

export type OnChangeFilterValuePayloadType = {
  name: string;
  value: FilterValue;
};

export type BaseFilterType = {
  name: string;
  placeholder?: string;
  isHidden?: boolean;
  isClearable?: boolean;
  value?: FilterValue;
  prefix?: string;
  defaultVal?: FilterValue;
  onChange?: (value?: FilterValue) => void;
};

export enum FilterTypes {
  MultiSelect = 'MultiSelect',
  SingleSelect = 'SingleSelect',
  Date = 'Date',
}

export type SingleSelectFilterType = Omit<BaseFilterType, 'onChange'> & {
  type: FilterTypes.SingleSelect;
  dictionaryName: string;
  options?: ISelectOption[];
  isSearchable?: boolean;
  value?: ISelectOption;
  onChange?: (value: ISelectOption) => void;
};

export type MultiSelectFilterType = Omit<BaseFilterType, 'onChange'> & {
  type: FilterTypes.MultiSelect;
  dictionaryName: string;
  options?: ISelectOption[];
  isSearchable?: boolean;
  value?: ISelectOption[];
  onChange?: (value: ISelectOption[]) => void;
};

export type DateFilterType = BaseFilterType & {
  type: FilterTypes.Date;
  value?: DatePickerRangeType;
};

export type FilterType =
  | SingleSelectFilterType
  | MultiSelectFilterType
  | DateFilterType;

export type DateFilterValue = [
  Date | null | undefined,
  Date | null | undefined
];

export type FilterValue =
  | string
  | number
  | string[]
  | number[]
  | null
  | DateFilterValue
  | undefined;

export interface IFiltersValueChange {
  name: string;
  value: FilterValue;
}

export type FilterValuesType = Record<
  IFiltersValueChange['name'],
  IFiltersValueChange['value']
>;

export type FilterRequestModelType = {
  property: string;
  condition: string;
  value: string[];
};

export interface IFiltersState {
  filters: FilterType[];
  filterValues?: FilterValuesType;
}
