import styled from 'styled-components';

import { classNameWithPrefix } from '@crm-framework/utils';

const blockName = 'filter-dropdown';

export const FilterDropdownWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'wrapper'),
})`
  position: relative;
`;

export const Menu = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'menu'),
})`
  background-color: transparent;
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsla(218, 50%, 10%, 0.1),
    0 4px 11px hsla(218, 50%, 10%, 0.1);
  position: absolute;
  z-index: 2;
`;

export const Blanket = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'blanket'),
})`
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  position: fixed;
  z-index: 1;
`;
