import styled from 'styled-components';

import { classNameWithPrefix } from '@crm-framework/utils';

const blockName = 'grid';

export const Wrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'wrapper'),
})<{ filled: boolean }>`
  width: 100%;
  height: 530px;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 12px;
  background: ${({ filled }) =>
    filled ? 'var(--grid-background)' : 'inherit'};
`;

export const GridWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'grid-wrapper'),
})`
  display: flex;
  height: calc(100% - 48px);

  & > div {
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
  }
`;

export const EmptyStateWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'empty-state-wrapper'),
})`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const EmptyStateDescriptionWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'empty-state-description-wrapper'),
})`
  color: var(--hover-secondary-color);
`;
