import React from 'react';

export const Promo: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7567 7.54048C16.2207 6.28676 15.3511 5.38462 14.2285 5.32732L16.7567 7.54048ZM16.7567 7.54048L19.6688 14.3414C20.8142 17.0254 18.8485 20.0029 15.9292 20.0029H4.05777C1.04636 20.0029 -0.920575 16.8243 0.438196 14.1267C0.438443 14.1262 0.43869 14.1257 0.438937 14.1252L2.03879 10.9162C2.54686 9.9055 3.3629 9.23032 4.34137 9.12513C5.32136 9.01977 6.26054 9.50799 6.96675 10.3949L6.96957 10.3984L7.17228 10.6564C7.64018 11.2401 8.13451 11.4176 8.52159 11.3855C8.90879 11.3533 9.36553 11.0967 9.72742 10.4447C9.72736 10.4448 9.72748 10.4446 9.72742 10.4447L11.4858 7.2722C12.148 6.0792 13.1059 5.27003 14.2285 5.32732M12.7064 7.94872C13.2629 6.94639 13.8216 6.70392 14.1573 6.72105C14.4929 6.73818 15.0234 7.03601 15.4737 8.08938L18.3855 14.89C18.3855 14.8898 18.3856 14.8902 18.3855 14.89C19.1375 16.653 17.8476 18.6073 15.9292 18.6073H4.05777C2.08298 18.6073 0.793816 16.5212 1.68507 14.7534L1.68647 14.7506L3.28566 11.543C3.28582 11.5426 3.28599 11.5423 3.28616 11.542C3.64326 10.8322 4.10622 10.554 4.49055 10.5127C4.87318 10.4715 5.38013 10.6435 5.87366 11.2624L6.0769 11.5211L6.0807 11.5259C6.76591 12.3824 7.68029 12.8557 8.63708 12.7762C9.59419 12.6967 10.4166 12.0789 10.9478 11.1216L12.7064 7.94872C12.7065 7.94859 12.7063 7.94885 12.7064 7.94872Z"
      stroke="transparent"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.3414 1.39554C4.18529 1.39554 3.24808 2.33275 3.24808 3.48886C3.24808 4.64497 4.18529 5.58218 5.3414 5.58218C6.49751 5.58218 7.43472 4.64497 7.43472 3.48886C7.43472 2.33275 6.49751 1.39554 5.3414 1.39554ZM1.85254 3.48886C1.85254 1.56202 3.41455 0 5.3414 0C7.26824 0 8.83026 1.56202 8.83026 3.48886C8.83026 5.4157 7.26824 6.97772 5.3414 6.97772C3.41455 6.97772 1.85254 5.4157 1.85254 3.48886Z"
      stroke="transparent"
    />
  </svg>
);
