import React from 'react';

import XCButton, { IButtonProps } from '@xcritical/button';

import { ContentWrapper } from './styled';

export const CenteredPrefixPostfixButton: React.FC<IButtonProps> = ({
  children,
  prefix,
  postfix,
  appearance,
  ...props
}) => (
  <XCButton {...props} appearance={appearance}>
    <ContentWrapper
      isUnderlined={
        !prefix && !postfix && appearance?.toLowerCase().includes('link')
      }>
      {prefix}
      {children}
      {postfix}
    </ContentWrapper>
  </XCButton>
);
