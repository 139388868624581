import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  FilterType,
  FilterValuesType,
  IFiltersState,
  IFiltersValueChange,
} from './types';

export const initialState: IFiltersState = {
  filters: [],
};

export const STORE_NAME = 'filters';

const filtersStore = createSlice({
  name: STORE_NAME,
  initialState,
  reducers: {
    setFilters: (state, { payload }: PayloadAction<FilterType[]>) => {
      state.filters = payload;
    },
    changeFilter: (state, { payload }: PayloadAction<IFiltersValueChange>) => {
      if (!state.filterValues) {
        state.filterValues = {};
      }

      state.filterValues[payload.name] = payload.value;
    },
    setFilterValues: (
      state,
      { payload }: PayloadAction<FilterValuesType | undefined>
    ) => {
      state.filterValues = payload;
    },
    clearFilters: (state) => {
      state.filterValues = undefined;
    },
  },
});

export const filtersActions = {
  ...filtersStore.actions,
};

export const filtersReducer = filtersStore.reducer;
