import React from 'react';

import { GridSort, IColumn } from '@xcritical/grid';

import { BaseCell, HeaderCell } from '@ams-package/grid';
import { FilterValuesType } from '@ams-package/filters';

import { parseFilterValueToStringByType, toTitleCase } from './utils';
import { DashboardSettingsType, IDashboardGridControllers } from './types';

export const getDashboardColumns = (
  settings: DashboardSettingsType[],
  sorting: IDashboardGridControllers['sorting'],
  onSort: (column: string, direction: GridSort | null) => void,
  filterValues?: FilterValuesType
): IColumn[] =>
  settings
    .filter((setting) => setting.inGrid)
    .map((setting) => {
      const replaceableColumn = settings.find(
        (filter) => filter.inFilter && filter.replaceGridColumn === setting.name
      );

      const filterValue =
        filterValues && replaceableColumn
          ? filterValues[replaceableColumn.name]
          : null;

      const shouldReplaceColumn = replaceableColumn && filterValue;

      const targetSetting = shouldReplaceColumn ? replaceableColumn : setting;

      const {
        name,
        inSort,
        width,
        visible,
        field,
        center,
        isExpandable,
        fixedPosition,
        sortable,
        sortOrder,
        render,
        replaceDisplayNameToSelectedValue,
      } = targetSetting;

      let { displayName } = targetSetting;

      if (replaceDisplayNameToSelectedValue) {
        const v = parseFilterValueToStringByType(
          targetSetting.filterType!,
          filterValue
        );
        displayName = toTitleCase(v || '');
      }

      return {
        render: render || ((value: string) => <BaseCell>{value}</BaseCell>),
        headerName: (
          <HeaderCell
            label={displayName ?? ''}
            name={field ?? name}
            sortable={!!inSort}
            sorting={sorting}
            onSort={onSort}
          />
        ),
        visible: visible ?? true,
        width: width ?? 150,
        field: field ?? name,
        center,
        isExpandable,
        fixedPosition,
        sortable,
        sortOrder,
      };
    });
