import { InputTheme } from '@xcritical/input';

import { generateFontStyle } from './fonts';
import { DEFAULT_BORDER_RADIUS } from './sizes';

export const inputTheme: InputTheme = {
  appearance: {
    default: {
      background: 'var(--background-color)',
      borderColor: 'var(--border-color)',
      borderRadius: DEFAULT_BORDER_RADIUS,
      color: 'var(--default-text-color)',
      padding: '10px 14px',
      prefixSpacing: 0.5,
      postfixSpacing: 0.5,
      ...generateFontStyle('body-1r'),
      width: '100%',
      clearWrapper: {
        fill: 'var(--default-text-color)',
        marginTop: '5px',
        height: 'var(--input-postfix-size)',
        width: 'var(--input-postfix-size)',
        hover: {
          fill: 'var(--default-text-color)',
        },
        disabled: {
          opacity: '0.5',
          fill: 'var(--default-text-color)',
        },
        invalid: {
          fill: 'var(--default-text-color)',
        },
        filled: {
          fill: 'var(--default-text-color)',
        },
      },
      input: {
        color: 'var(--default-text-color)',
        width: '100%',
      },
      hover: {
        borderColor: 'var(--hover-border-color)',
      },
      focus: {
        borderColor: 'var(--border-color)',
        color: 'var(--default-text-color)',
        borderRadius: DEFAULT_BORDER_RADIUS,
        outline: 'none',
      },
      active: {
        borderColor: 'var(--border-color)',
      },
      disabled: {
        borderColor: 'var(--border-color)',
        opacity: '0.5',
      },
      invalid: {
        borderColor: 'var(--danger-color)',
        color: 'var(--danger-color)',
      },
    },
    withManualPostfix: {
      input: {
        width: '80%',
        flexGrow: 0,
      },
    },
    // style is in components designs but not found on pages designs
    correctInput: {
      borderColor: 'var(--correct-border-color)',
      input: {
        borderColor: 'var(--correct-border-color)',
      },
      active: {
        borderColor: 'var(--correct-border-color)',
      },
      hover: {
        borderColor: 'var(--correct-border-color)',
      },
      focus: {
        borderColor: 'var(--correct-border-color)',
      },
    },
  },
};
