import React from 'react';

import { ISelectOption, SingleFilterSelect } from '@ams-package/select';

import { SingleSelectFilterType } from '../types';
import { FilterController } from '../filter-controller';

export const SingleSelectFilter: React.FC<SingleSelectFilterType> = (
  filter
) => {
  const { placeholder, options, value, isClearable, ...rest } = filter;

  const filterValue = value ? (value as ISelectOption).label : '';

  return (
    <FilterController
      value={filterValue}
      filter={filter}
      isClearable={!!isClearable}>
      <SingleFilterSelect
        value={value ?? null}
        label={placeholder}
        options={options}
        menuIsOpen
        {...rest}
      />
    </FilterController>
  );
};
