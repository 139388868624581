import { GridSort } from '@xcritical/grid';

import { FilterType, FilterTypes } from '@ams-package/filters';
import {
  DateFilterValue,
  FilterValue,
  FilterValuesType,
} from '@ams-package/filters/types';
import { DEFAULT_PAGE_SIZE, MAX_PAGE_SIZE } from '@ams-package/grid';

import { DashboardSortingType, IDashboardGridControllers } from './types';

export const parseDashboardSortingFromString = (
  sortField: string
): DashboardSortingType | null => {
  if (!sortField) return null;

  const filedName = sortField.includes('-') ? sortField.slice(1) : sortField;

  return {
    sortedBy: filedName,
    sortOrder: sortField.includes('-') ? GridSort.DESC : GridSort.ASC,
  };
};

export const parseDashboardGridControllersFromString = (
  query: Record<string, string>
): IDashboardGridControllers => {
  const pageSize = query.pageSize
    ? parseInt(query.pageSize!, 10)
    : DEFAULT_PAGE_SIZE;

  return {
    sorting: parseDashboardSortingFromString(query.sorting),
    page: query.page ? parseInt(query.page!, 10) : 1,
    pageSize: pageSize > MAX_PAGE_SIZE ? MAX_PAGE_SIZE : pageSize,
  };
};

export const parseDateFilterRangeFromString = (
  date?: string
): DateFilterValue => {
  if (!date) {
    return [undefined, undefined];
  }

  const [from, to] = date.split(',');

  return [from ? new Date(from) : undefined, to ? new Date(to) : undefined];
};

export const parseFilterValueToStringByType = (
  filterType: FilterType['type'],
  value: FilterValue
): string | null => {
  if (!value) return null;

  switch (filterType) {
    case FilterTypes.MultiSelect: {
      return (value as (string | number)[]).join(',');
    }
    case FilterTypes.SingleSelect: {
      return String(value);
    }
    case FilterTypes.Date: {
      const [from, to] = value as DateFilterValue;

      return from || to
        ? [from?.toISOString(), to?.toISOString()].join(',')
        : null;
    }
    default:
      return null;
  }
};

export const parseFilterValueFromStringByType = (
  filter: FilterType,
  value: string
): FilterValue => {
  switch (filter.type) {
    case FilterTypes.MultiSelect: {
      const values = value.split(',');

      return values;
    }
    case FilterTypes.SingleSelect: {
      return value;
    }
    case FilterTypes.Date: {
      const [from, to] = parseDateFilterRangeFromString(value);

      return [from, to];
    }

    default:
      return null;
  }
};

export const parseFilterValuesFromQuery = (
  filters: FilterType[],
  filterValues: Record<string, string>
): FilterValuesType =>
  filters.reduce((acc, filter) => {
    const filterValue = filterValues[filter.name];

    if (!filterValue) return { ...acc };

    return {
      ...acc,
      [filter.name]: parseFilterValueFromStringByType(filter, filterValue),
    };
  }, {});

export function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
}

export const mapDictionaryNamesFromFilters = (filters: FilterType[]) =>
  filters.reduce((acc, filter) => {
    if (
      (filter.type === FilterTypes.SingleSelect ||
        filter.type === FilterTypes.MultiSelect) &&
      filter.dictionaryName
    ) {
      return [...acc, filter.dictionaryName];
    }

    return acc;
  }, [] as string[]);
