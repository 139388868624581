import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getManagerContacts } from '@ams-package/app';

import { getTelegramName } from '../utils';

import { ContactInfoButtonsBlock } from './styled';
import { SocialMediaButton } from './SocialMediaButton';

export const SocialMediaButtons: React.FC<{
  appearance: string;
}> = ({ appearance }) => {
  const manager = useSelector(getManagerContacts);
  const tgName = useMemo(() => getTelegramName(manager?.contacts), [manager]);
  const skypeName = useMemo(() => manager?.skype, [manager]);

  if (!tgName && !skypeName) return null;

  return (
    <ContactInfoButtonsBlock numberOfButtons={tgName && skypeName ? 2 : 1}>
      {tgName && (
        <SocialMediaButton
          value={tgName}
          icon="telegram"
          name="Telegram"
          appearance={appearance}
          target="_blank"
          href={`http://www.t.me/${tgName}`}
        />
      )}
      {skypeName && (
        <SocialMediaButton
          value={skypeName}
          icon="skype"
          name="Skype"
          appearance={appearance}
          href={`skype:live:${skypeName}?chat`}
        />
      )}
    </ContactInfoButtonsBlock>
  );
};
