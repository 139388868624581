import React from 'react';

export const PaymentIcon: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.3332 6.29159C18.3332 6.84159 17.8832 7.29158 17.3332 7.29158H2.6665C2.1165 7.29158 1.6665 6.84159 1.6665 6.29159V6.28325C1.6665 4.37492 3.20817 2.83325 5.1165 2.83325H14.8748C16.7832 2.83325 18.3332 4.38325 18.3332 6.29159Z"
      fill="#none"
    />
    <path
      d="M1.6665 9.54169V13.7167C1.6665 15.625 3.20817 17.1667 5.1165 17.1667H14.8748C16.7832 17.1667 18.3332 15.6167 18.3332 13.7084V9.54169C18.3332 8.99169 17.8832 8.54169 17.3332 8.54169H2.6665C2.1165 8.54169 1.6665 8.99169 1.6665 9.54169ZM6.6665 14.375H4.99984C4.65817 14.375 4.37484 14.0917 4.37484 13.75C4.37484 13.4084 4.65817 13.125 4.99984 13.125H6.6665C7.00817 13.125 7.2915 13.4084 7.2915 13.75C7.2915 14.0917 7.00817 14.375 6.6665 14.375ZM12.0832 14.375H8.74984C8.40817 14.375 8.12484 14.0917 8.12484 13.75C8.12484 13.4084 8.40817 13.125 8.74984 13.125H12.0832C12.4248 13.125 12.7082 13.4084 12.7082 13.75C12.7082 14.0917 12.4248 14.375 12.0832 14.375Z"
      fill="#none"
    />
  </svg>
);
