import React from 'react';

import { LIGHT_COLOR } from '@ams-package/theme/colors';

export const ArrowLeft = ({
  className,
  stroke = LIGHT_COLOR,
}: {
  className?: string;
  stroke?: string;
}) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none">
    <path
      d="M9.9987 13.3334L5.71174 8.94284C5.20546 8.42432 5.20546 7.57584 5.71174 7.05732L9.9987 2.66675"
      stroke={stroke}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ArrowRight = ({
  className,
  stroke = LIGHT_COLOR,
}: {
  className?: string;
  stroke?: string;
}) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none">
    <path
      d="M6 13.3334L10.287 8.94284C10.7932 8.42432 10.7932 7.57584 10.287 7.05732L6 2.66675"
      stroke={stroke}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
