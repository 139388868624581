import React from 'react';

export const AccountSettings: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.09042 18.4265C5.69818 18.5454 6.39978 18.6046 7.20945 18.6046H12.7908C13.6005 18.6046 14.3021 18.5454 14.9099 18.4265C14.4274 16.7278 12.5033 15.3209 10.0001 15.3209C7.49697 15.3209 5.57285 16.7278 5.09042 18.4265ZM3.59333 18.89C3.83793 15.9992 6.73764 13.9255 10.0001 13.9255C13.2626 13.9255 16.1624 15.9992 16.407 18.89C16.4349 19.2199 16.227 19.5241 15.9095 19.6179C15.0064 19.8847 13.9676 20 12.7908 20H7.20945C6.03271 20 4.9939 19.8847 4.09083 19.6179C3.77327 19.5241 3.56541 19.2199 3.59333 18.89Z"
      stroke="transparent"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.71597 1.71597C2.95734 0.474606 4.81491 0 7.2093 0H12.7907C15.1851 0 17.0427 0.474606 18.284 1.71597C19.5254 2.95734 20 4.81491 20 7.2093V12.7907C20 14.6052 19.7289 16.1062 19.0605 17.2677C18.3757 18.458 17.3158 19.2198 15.9017 19.6201C15.7012 19.6769 15.486 19.6411 15.3147 19.5226C15.1434 19.4041 15.034 19.2152 15.0164 19.0077C14.8517 17.0613 12.784 15.3209 10 15.3209C7.21599 15.3209 5.14826 17.0613 4.98356 19.0077C4.966 19.2152 4.85662 19.4041 4.68533 19.5226C4.51404 19.6411 4.29875 19.6769 4.09834 19.6201C2.68421 19.2198 1.62431 18.458 0.939466 17.2677C0.271142 16.1062 0 14.6052 0 12.7907V7.2093C0 4.81491 0.474606 2.95734 1.71597 1.71597ZM2.70263 2.70263C1.85098 3.55429 1.39535 4.95253 1.39535 7.2093V12.7907C1.39535 14.4924 1.65444 15.7124 2.14891 16.5718C2.51462 17.2074 3.03111 17.684 3.75354 18.0144C4.44603 15.5896 7.07745 13.9256 10 13.9256C12.9225 13.9256 15.554 15.5896 16.2465 18.0144C16.9689 17.684 17.4854 17.2074 17.8511 16.5718C18.3456 15.7124 18.6047 14.4924 18.6047 12.7907V7.2093C18.6047 4.95253 18.149 3.55429 17.2974 2.70263C16.4457 1.85098 15.0475 1.39535 12.7907 1.39535H7.2093C4.95253 1.39535 3.55429 1.85098 2.70263 2.70263ZM10 6.04651C8.54346 6.04651 7.36744 7.22254 7.36744 8.67909C7.36744 10.1382 8.54598 11.3209 10 11.3209C11.454 11.3209 12.6326 10.1382 12.6326 8.67909C12.6326 7.22254 11.4565 6.04651 10 6.04651ZM5.97209 8.67909C5.97209 6.45191 7.77282 4.65116 10 4.65116C12.2272 4.65116 14.0279 6.45191 14.0279 8.67909C14.0279 10.9037 12.2297 12.7163 10 12.7163C7.7703 12.7163 5.97209 10.9037 5.97209 8.67909Z"
      stroke="transparent"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99959 6.04647C8.54305 6.04647 7.36703 7.2225 7.36703 8.67905C7.36703 10.1381 8.54557 11.3209 9.99959 11.3209C11.4536 11.3209 12.6321 10.1381 12.6321 8.67905C12.6321 7.2225 11.4561 6.04647 9.99959 6.04647ZM5.97168 8.67905C5.97168 6.45187 7.77241 4.65112 9.99959 4.65112C12.2268 4.65112 14.0275 6.45187 14.0275 8.67905C14.0275 10.9037 12.2293 12.7162 9.99959 12.7162C7.76989 12.7162 5.97168 10.9037 5.97168 8.67905Z"
      stroke="transparent"
    />
  </svg>
);
