import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DEFAULT_PAGE_SIZE } from '@ams-package/grid';

import {
  DashboardSortingType,
  IDashboardState,
  IInitDashboardPayload,
  SetDashboardItemsActionType,
} from './types';

export const initialState: IDashboardState = {
  items: [],
  withoutUrlChanging: false,
  isReady: false,
  isLoading: false,
  totalCount: 0,
  page: 1,
  pageSize: 10,
  sorting: null,
};

export const STORE_NAME = '@dashboard';

const dashboardStore = createSlice({
  name: STORE_NAME,
  initialState,
  reducers: {
    init: (state, { payload }: PayloadAction<IInitDashboardPayload>) => {
      state.filters = payload.filters;
      state.withoutUrlChanging = !!payload.withoutUrlChanging;
    },
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setIsReady: (state, { payload }: PayloadAction<boolean>) => {
      state.isReady = payload;
    },
    setItems: (
      state,
      { payload }: PayloadAction<SetDashboardItemsActionType>
    ) => {
      state.items = payload.items;
      state.totalCount = payload.totalCount;
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setPageSize: (state, { payload }: PayloadAction<number>) => {
      state.pageSize = payload;
    },
    changeSorting: (
      state,
      { payload }: PayloadAction<DashboardSortingType | null>
    ) => {
      state.sorting = payload;
    },
    resetDashboardControllers: (state) => {
      state.page = 1;
      state.pageSize = DEFAULT_PAGE_SIZE;
      state.sorting = null;
    },
  },
});

const GET_ITEMS = `${STORE_NAME}/get-items`;

export const dashboardActions = {
  ...dashboardStore.actions,
  getItems: createAction(GET_ITEMS),
};

export const dashboardReducer = dashboardStore.reducer;
