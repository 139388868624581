import React from 'react';

import { StatusMessage, StatusMessageType } from '@ams-package/status-message';

import { BaseModal, BaseModalType } from '../base-modal';
import { useModal } from '../hooks';
import { Buttons, ButtonsType } from '../buttons/Buttons';

export type StatusModalType = Omit<BaseModalType, 'title' | 'children'>;

export const StatusModal: React.FC<StatusModalType> = ({ name, ...params }) => {
  const {
    payload: { message, buttons },
  } = useModal(name);

  return (
    <BaseModal name={name} title="" appearance="statusModal" {...params}>
      <StatusMessage
        {...(message as Omit<StatusMessageType, 'buttons'>)}
        buttons={buttons ? <Buttons {...(buttons as ButtonsType)} /> : null}
      />
    </BaseModal>
  );
};
