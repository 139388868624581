/* eslint-disable consistent-return */
import sortBy from 'lodash/sortBy';

import { ISelectOption } from '@ams-package/select';

import {
  DictionaryMapperKeyNameType,
  DictionaryMapperKeyIdType,
  DictionaryTypes,
  AmsDictionaryType,
} from './types';
import { IBaseDictionary } from './models';

export const dictionaryNameMapper = <T>(
  dictionary: T[],
  keyForName: DictionaryMapperKeyNameType<T>
) =>
  dictionary.map((data) => ({
    ...data,
    name: data[keyForName],
  }));

export const dictionaryIdMapper = <T>(
  dictionary: T[],
  keyForId: DictionaryMapperKeyIdType<T>
) =>
  dictionary.map((data) => ({
    ...data,
    id: data[keyForId],
  }));

interface IGetValueOfKeyById<TFallback, TArray, TKey> {
  id: number | null | undefined;
  arrayForSearch: TArray;
  keyName: TKey;
  fallback?: TFallback;
}

export const getValueOfKeyById = <
  TFallback = undefined,
  TArray extends any[] = any[],
  TKey extends keyof TArray[number] = keyof TArray[number]
>({
  id,
  arrayForSearch,
  keyName,
  fallback,
}: IGetValueOfKeyById<TFallback, TArray, TKey>):
  | TFallback
  | TArray[number][TKey] => {
  if (!id) return fallback;

  const dataById = arrayForSearch.find((data) => id === data.id);

  return dataById ? dataById[keyName] : fallback;
};

export const mapDictionaryToSelectOptions = (dictionary: IBaseDictionary[]) =>
  dictionary.map<ISelectOption>(({ id, name }) => ({
    label: name,
    value: id,
  }));

export const getSortedDictionary = <T>(
  dictionary: T[],
  order: string | ((dictionary: T[]) => T[])
) =>
  typeof order !== 'string'
    ? order(dictionary)
    : sortBy(dictionary, (dictionaryItem: T) => dictionaryItem[order]);

export const mapKeyValueDictionary =
  (dicType: DictionaryTypes) =>
  (dictionary: AmsDictionaryType[]): IBaseDictionary[] => {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of dictionary) {
      if (item.type === dicType) {
        return Object.entries(item.payload).map(([id, name]) => ({
          id,
          name,
        })) as IBaseDictionary[];
      }
    }

    return [];
  };

export const mapPayoutStatusDictionary =
  (dicType: DictionaryTypes) =>
  (dictionary: AmsDictionaryType[]): IBaseDictionary[] => {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of dictionary) {
      if (item.type === dicType) {
        return Object.entries(item.payload).map(([id, name]) => ({
          id,
          name: id === 'Rejected' ? 'Cancelled' : name,
        })) as IBaseDictionary[];
      }
    }

    return [];
  };
