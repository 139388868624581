import { css } from 'styled-components';

export const Month = (monthStyles) => css`
  .react-datepicker__month {
    ${monthStyles}
  }
`;

export const CurrentMonth = (currentMonthStyles) => css`
  .react-datepicker__current-month {
    ${currentMonthStyles}
  }
`;

export const MonthContainer = (monthContainerStyles) => css`
  .react-datepicker__month-container {
    ${monthContainerStyles}
  }
`;
