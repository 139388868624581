import React from 'react';
import { TickCircle, CloseCircle } from 'iconsax-react';
import styled from 'styled-components';

import { IconWrapper } from '@ams-package/utils';

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const SUCCESSFUL_MESSAGE = 'Link copied successfully';
const ERROR_MESSAGE =
  'To work with this offer, first select a landing page and then copy the link';

const CopyNotificationContent: React.FC<{
  text: string;
  children: React.ReactNode;
}> = ({ text, children }) => (
  <ContentWrapper>
    <IconWrapper>{children}</IconWrapper>
    {text}
  </ContentWrapper>
);

export const CopyErrorContent: React.FC<{ text?: string }> = ({ text }) => (
  <CopyNotificationContent text={text ?? ERROR_MESSAGE}>
    <CloseCircle size={16} color="var(--danger-text-color)" />
  </CopyNotificationContent>
);

export const CopySuccessContent: React.FC<{ text?: string }> = ({ text }) => (
  <CopyNotificationContent text={text ?? SUCCESSFUL_MESSAGE}>
    <TickCircle size={16} color="var(--primary-color)" />
  </CopyNotificationContent>
);
