import { IPaymentMethodsModel } from '@ams-package/dictionaries';
import { authorizedWretch } from '@ams-package/utils';

export const addPaymentMethod = async (
  name: string,
  fields: Record<string, string>
): Promise<number> =>
  authorizedWretch(`/api/payment-methods`)
    .post({
      name,
      fields,
    })
    .json();

export const editPaymentMethod = async (
  id: number,
  fields: Record<string, string>
): Promise<Response> =>
  authorizedWretch(`/api/payment-methods/${id}`)
    .put({
      fields,
    })
    .res();

export const apiGetPaymentMethod = async (
  id: number
): Promise<IPaymentMethodsModel> =>
  authorizedWretch(`/api/payment-methods/${id}`)
    .get()
    .json<IPaymentMethodsModel>();

export const apiDeletePaymentMethod = async (id: number): Promise<Response> =>
  authorizedWretch(`/api/payment-methods/${id}`).delete().res();
