import get from 'lodash/get';
import memoize from 'micro-memoize';
import { mergeDeep } from 'utilitify';
import { css, CSSObject } from 'styled-components';

import { IThemeNamespace } from '@xcritical/theme';

import { datePickerThemeNamespace, defaultDatePickerTheme } from '../theme';
import { IDatePickerTheme } from '../interfaces';

import { Day, DaysOfWeekContainer, DayOfWeek, DayOutsideMonth } from './Day';
import { Month, CurrentMonth, MonthContainer } from './Month';
import { Popper } from './Popper';
import {
  DatePicker,
  Wrapper,
  NavigationArrows,
  Triangle,
  HeaderContainer,
} from './DatePicker';
import { Week } from './Week';

export const getDatePickerMergedTheme = memoize(
  (theme: IThemeNamespace<IDatePickerTheme> = {}): CSSObject => {
    const datePickerTheme = get(theme, datePickerThemeNamespace);

    return mergeDeep(defaultDatePickerTheme, datePickerTheme);
  }
);

export const getThemeStylesByProperty = (
  theme: IThemeNamespace<IDatePickerTheme> = {},
  propertyPath: string[]
): CSSObject => {
  const mergedTheme = getDatePickerMergedTheme(theme);

  return get(mergedTheme, propertyPath);
};

export const getDatePickerThemeGeneralStyles = memoize(
  (theme: IThemeNamespace<IDatePickerTheme> = {}): any => {
    const datePickerStyles = getThemeStylesByProperty(theme, ['datePicker']);
    const wrapperStyles = getThemeStylesByProperty(theme, [
      'datePickerWrapper',
    ]);

    const headerContainerStyles = getThemeStylesByProperty(theme, [
      'headerContainer',
    ]);

    const navigationArrowsStyles = getThemeStylesByProperty(theme, [
      'navigationArrows',
    ]);

    const monthStyles = getThemeStylesByProperty(theme, ['month']);
    const currentMonthStyles = getThemeStylesByProperty(theme, [
      'currentMonth',
    ]);
    const monthContainerStyles = getThemeStylesByProperty(theme, [
      'monthContainer',
    ]);

    const dayStyles = getThemeStylesByProperty(theme, ['day']);
    const dayOutsideMonth = getThemeStylesByProperty(theme, [
      'dayOutsideMonth',
    ]);
    const weekStyles = getThemeStylesByProperty(theme, ['week']);

    const dayOfWeekStyles = getThemeStylesByProperty(theme, ['dayOfWeek']);
    const daysOfWeekContainerStyles = getThemeStylesByProperty(theme, [
      'daysOfWeekContainer',
    ]);

    const popperStyles = getThemeStylesByProperty(theme, ['popper']);
    const triangleStyles = getThemeStylesByProperty(theme, ['triangle']);

    return css`
      ${Wrapper(wrapperStyles)}
      ${DatePicker(datePickerStyles)}

    ${HeaderContainer(headerContainerStyles)}
      
    ${NavigationArrows(navigationArrowsStyles)}

    ${Month(monthStyles)}
    ${CurrentMonth(currentMonthStyles)}
    ${MonthContainer(monthContainerStyles)}

    ${Day(dayStyles)}
    ${DayOutsideMonth(dayOutsideMonth)}
    ${Week(weekStyles)}

    ${DaysOfWeekContainer(daysOfWeekContainerStyles)}
    ${DayOfWeek(dayOfWeekStyles)}

    ${Triangle(triangleStyles)}
    ${Popper(popperStyles)}
    `;
  }
);
