import React, { useEffect, useState } from 'react';

import { Popover } from '@xcritical/popover';

import { IPasswordValidationFormFieldProps } from './types';
import { PasswordFormField } from './PasswordInput';
import { DotIcon, CheckIcon } from './Icons';
import { PopoverContentWrapper, PopoverLineWrapper } from './styled';

const labels = {
  oneUppercaseCharacter: 'One uppercase character',
  oneLowercaseCharacter: 'One lowercase character',
  oneNumber: 'One number',
  oneSpecialCharacter: 'One special character',
  charactersMinimum: '6 characters minimum',
};

const listOfRequirements = {
  [labels.oneUppercaseCharacter]: false,
  [labels.oneLowercaseCharacter]: false,
  [labels.oneNumber]: false,
  [labels.oneSpecialCharacter]: false,
  [labels.charactersMinimum]: false,
};

const listOfRegExp = {
  [labels.oneUppercaseCharacter]: /[A-Z]/,
  [labels.oneLowercaseCharacter]: /[a-z]/,
  [labels.oneNumber]: /[0-7]/,
  [labels.charactersMinimum]: /^.{6,}/,
  [labels.oneSpecialCharacter]:
    // eslint-disable-next-line no-useless-escape
    /[-\$&\+,:;=\?\*\"\{\}@#|'<>\.*()\[%!\^\]\\\/№]/,
};

export const PasswordValidationFormField: React.FC<
  IPasswordValidationFormFieldProps
> = ({ position = 'bottom center', ...props }) => {
  const [value, setValue] = useState('');
  const [isOnFocus, setIsOnFocus] = useState(false);
  const [list, setList] = useState(listOfRequirements);

  useEffect(() => {
    if (value) {
      const newList = { ...list };

      Object.keys(listOfRegExp).forEach((label) => {
        newList[label] = listOfRegExp[label].test(value);
      });

      setList(newList);
    }
  }, [value]);

  const onChangeValue = (newValue?: string) => {
    setValue(newValue ?? '');
  };

  return (
    <Popover
      position={position} // 'right bottom' for settings
      visible={!!value.length && isOnFocus}
      trigger="click"
      shouldFitContainer
      content={
        <PopoverContentWrapper>
          {Object.keys(list).map((e) => (
            <PopoverLineWrapper>
              {list[e] ? <CheckIcon /> : <DotIcon />}
              <div>{e}</div>
            </PopoverLineWrapper>
          ))}
        </PopoverContentWrapper>
      }>
      <PasswordFormField
        onSetValue={onChangeValue}
        onFocus={() => setIsOnFocus(true)}
        onBlur={() => setIsOnFocus(false)}
        {...props}
      />
    </Popover>
  );
};
