import { css } from 'styled-components';

export const Day = ({
  selected,
  disabled,
  rangeEnd,
  rangeStart,
  inRange,
  hover,
  today,
  dayButton,
  inSelectingRange,
  selectingRange,
  ...dayStyles
}: any) => css`
  .react-datepicker__day {
    ${dayStyles}

    &:hover {
      opacity: 0.5;
      ${hover}
    }

    &--today {
      font-weight: bold;
      ${today}
    }

    &--selected {
      ${selected}

      &:hover {
        opacity: 0.8;
      }
    }

    &--disabled {
      ${disabled}

      &:hover {
        opacity: 1;
      }
    }

    &--selected {
      ${selected}
    }

    &--keyboard-selected {
      background: transparent !important;
    }

    &--range-end {
      ${rangeEnd}

      div {
        ${dayButton}
      }
    }
    &--range-start {
      ${rangeStart}

      div {
        ${dayButton}
      }
    }
    &--in-range {
      ${inRange}
    }
    &--in-selecting-range {
      ${inSelectingRange ?? inRange}
    }
    &--in-selecting-range.react-datepicker__day--selecting-range-end {
      ${selectingRange ?? rangeEnd}

      div {
        ${dayButton}
      }
    }
    &--in-selecting-range.react-datepicker__day--selecting-range-start {
      ${selectingRange ?? rangeStart}

      div {
        ${dayButton}
      }
    }
  }
`;

export const DayOfWeek = (dayOfWeekStyles) => css`
  .react-datepicker__day-name {
    ${dayOfWeekStyles}
  }
`;

export const DayOutsideMonth = (dayOutsideMonth) => css`
  .react-datepicker__day--outside-month {
    ${dayOutsideMonth}
  }
`;

export const DaysOfWeekContainer = (daysOfWeekContainerStyles) => css`
  .react-datepicker__day-names {
    ${daysOfWeekContainerStyles}
  }
`;
