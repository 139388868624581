import React from 'react';

export const ArrowLeft = ({
  className,
  stroke = 'var(--hover-secondary-color)',
}: {
  className?: string;
  stroke?: string;
}) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none">
    <path
      d="M9.9987 13.8333L5.71174 9.44272C5.20546 8.9242 5.20546 8.07572 5.71174 7.5572L9.9987 3.16663"
      stroke={stroke}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ChevronRight = ({
  className,
  stroke = 'var(--hover-secondary-color)',
}: {
  className?: string;
  stroke?: string;
}) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none">
    <path
      d="M6 13.8333L10.287 9.44272C10.7932 8.9242 10.7932 8.07572 10.287 7.5572L6 3.16663"
      stroke={stroke}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
