import React from 'react';

export const SuccessIcon = ({
  className,
  fill = 'var(--primary-color)',
}: {
  className?: string;
  fill?: string;
}) => (
  <svg
    className={className}
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.9976 4.18605C15.7409 4.18605 4.1836 15.7433 4.1836 30C4.1836 44.2567 15.7409 55.814 29.9976 55.814C44.2542 55.814 55.8115 44.2567 55.8115 30C55.8115 15.7433 44.2542 4.18605 29.9976 4.18605ZM-0.00244141 30C-0.00244141 13.4315 13.429 0 29.9976 0C46.5661 0 59.9976 13.4315 59.9976 30C59.9976 46.5685 46.5661 60 29.9976 60C13.429 60 -0.00244141 46.5685 -0.00244141 30ZM44.0357 20.1479C44.8531 20.9653 44.8531 22.2905 44.0357 23.1079L27.2915 39.8521C26.4741 40.6695 25.1489 40.6695 24.3315 39.8521L15.9594 31.48C15.1421 30.6626 15.1421 29.3374 15.9594 28.52C16.7768 27.7026 18.102 27.7026 18.9194 28.52L25.8115 35.4121L41.0757 20.1479C41.8931 19.3305 43.2183 19.3305 44.0357 20.1479Z"
      fill={fill}
    />
  </svg>
);

export const FailureIcon = ({
  className,
  fill = 'var(--danger-text-color)',
}: {
  className?: string;
  fill?: string;
}) => (
  <svg
    className={className}
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none">
    <g clipPath="url(#clip0_1213_68583)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 3.82979C15.5466 3.82979 3.82979 15.5466 3.82979 30C3.82979 44.4534 15.5466 56.1702 30 56.1702C44.4534 56.1702 56.1702 44.4534 56.1702 30C56.1702 15.5466 44.4534 3.82979 30 3.82979ZM0 30C0 13.4315 13.4315 0 30 0C46.5685 0 60 13.4315 60 30C60 46.5685 46.5685 60 30 60C13.4315 60 0 46.5685 0 30ZM20.2204 20.2204C20.9682 19.4726 22.1807 19.4726 22.9285 20.2204L30 27.2919L37.0715 20.2204C37.8193 19.4726 39.0318 19.4726 39.7796 20.2204C40.5274 20.9682 40.5274 22.1807 39.7796 22.9285L32.7081 30L39.7796 37.0715C40.5274 37.8193 40.5274 39.0318 39.7796 39.7796C39.0318 40.5274 37.8193 40.5274 37.0715 39.7796L30 32.7081L22.9285 39.7796C22.1807 40.5274 20.9682 40.5274 20.2204 39.7796C19.4726 39.0318 19.4726 37.8193 20.2204 37.0715L27.2919 30L20.2204 22.9285C19.4726 22.1807 19.4726 20.9682 20.2204 20.2204Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_1213_68583">
        <rect width="60" height="60" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
