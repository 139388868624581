import { FilterRequestModelType, FilterValuesType } from './types';

export const parseUniversalFilters = (
  filterNames: string[],
  filterValues: FilterValuesType,
  condition?: Record<string, 'In' | 'Between'>
): FilterRequestModelType[] =>
  filterNames.reduce((acc, filterName) => {
    const filterValue = filterValues[filterName];

    if (!filterValue) return acc;

    if (Array.isArray(filterValue) && !filterValue.length) return acc;

    const value = Array.isArray(filterValue)
      ? filterValue.map((option) => String(option))
      : [String(filterValue)];

    const newFilter = {
      property: filterName,
      condition: condition?.[filterName] ?? 'In',
      value,
    };

    return [...acc, newFilter];
  }, []);
