import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IPayoutPaymentMethodConfigurationModel } from '@ams-package/dictionaries';

import {
  IPaymentEditorState,
  OnSuccessActionPayload,
  PaymentEditorMode,
} from './types';

export const initialState: IPaymentEditorState = {
  isOpen: false,
  selectedId: 0,
};

export const STORE_NAME = '@payment-editor';
export const STORE_FORM_NAME = '@payment-editor';

const store = createSlice({
  name: STORE_NAME,
  initialState,
  reducers: {
    init: () => initialState,
    addOrEdit(
      state,
      action: PayloadAction<
        | {
            selectedId?: number;
            formName?: string;
            statusMessageKey?: string;
          }
        | undefined
      >
    ) {
      state.isOpen = true;
      state.mode = PaymentEditorMode.AddOrEdit;

      if (action.payload?.statusMessageKey)
        state.statusMessageKey = action.payload.statusMessageKey;

      if (action.payload?.formName) state.formName = action.payload.formName;

      if (action.payload?.selectedId)
        state.selectedId = action.payload.selectedId;
    },
    delete(state, action: PayloadAction<number>) {
      state.isOpen = true;
      state.selectedId = action.payload;
      state.mode = PaymentEditorMode.Delete;
    },
    close: () => initialState,
    clearState: (state) => {
      state.savedState = undefined;
    },
    onFailure: (state) => {
      state.savedState = {
        state: 'failed',
      };
    },
    onSuccess: {
      reducer: (state, { payload }: PayloadAction<OnSuccessActionPayload>) => {
        state.savedState = {
          state: 'success',
          id: payload.id,
        };
      },
      prepare: (id?: number, formName?: string) => ({
        payload: { id, formName },
      }),
    },
    selectPaymentMethod(
      state,
      { payload }: PayloadAction<IPayoutPaymentMethodConfigurationModel>
    ) {
      state.currentPaymentMethod = payload;
    },
  },
});

const SAVE = `${STORE_NAME}/save` as const;
const CONFIRM_REMOVING = `${STORE_NAME}/confirm-removing` as const;

export const paymentEditorActions = {
  ...store.actions,
  save: createAction(SAVE),
  confirmRemoving: createAction(CONFIRM_REMOVING),
};

export const paymentEditorReducer = store.reducer;
