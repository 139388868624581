import styled from 'styled-components';

import { classNameWithPrefix } from '@crm-framework/utils';

import { generateFontStyle } from '@ams-package/theme';

const blockName = 'confirm-modal';

export const MessageWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'message-wrapper'),
})`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  padding: 12px 60px 24px;
  min-height: 150px;
`;

export const TittleWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'title-wrapper'),
})`
  color: var(--default-text-color);
  text-align: center;

  ${generateFontStyle('body-1m')}
`;

export const DescriptionWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'description-wrapper'),
})`
  color: var(--hover-border-color);
  ${generateFontStyle('body-1r')}
  margin-top: 12px;
  text-align: center;
`;

export const ButtonsWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'buttons-wrapper'),
})`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  padding: 0 24px 24px;
  margin-top: auto;
`;
