import React from 'react';

export const EmailIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none">
    <path
      d="M8.29005 9.55344L1.94505 5.51559C1.61702 5.29866 1.38731 4.96158 1.30538 4.57694C1.22345 4.1923 1.29585 3.79087 1.507 3.45909C1.71814 3.12731 2.05114 2.89172 2.43429 2.80306C2.81743 2.71439 3.22007 2.77974 3.5555 2.98504L8.2105 5.94719L12.3901 3.02144C12.7161 2.80047 13.1161 2.71663 13.5034 2.78804C13.8908 2.85945 14.2346 3.08037 14.4604 3.40309C14.6863 3.72581 14.7762 4.12442 14.7106 4.51283C14.645 4.90125 14.4293 5.24828 14.1101 5.47899L8.29005 9.55344Z"
      fill="#EA4435"
    />
    <path
      d="M13.75 13.2502H11.75V4.25024C11.75 3.85242 11.908 3.47089 12.1893 3.18958C12.4706 2.90828 12.8522 2.75024 13.25 2.75024C13.6478 2.75024 14.0294 2.90828 14.3107 3.18958C14.592 3.47089 14.75 3.85242 14.75 4.25024L14.75 12.2502C14.75 12.5155 14.6446 12.7698 14.4571 12.9574C14.2696 13.1449 14.0152 13.2502 13.75 13.2502Z"
      fill="#00AC47"
    />
    <path
      d="M14.7283 4.03272C14.7239 4.00272 14.7243 3.97207 14.718 3.94212C14.7084 3.89622 14.6906 3.85382 14.6769 3.80952C14.6636 3.75882 14.6476 3.70887 14.629 3.65987C14.619 3.63612 14.6036 3.61527 14.5922 3.59217C14.5611 3.52552 14.5251 3.46127 14.4845 3.39992C14.4645 3.37087 14.4395 3.34612 14.4174 3.31862C14.3795 3.26864 14.3385 3.22107 14.2947 3.17617C14.2614 3.14382 14.2235 3.11677 14.1874 3.08762C14.1489 3.05468 14.1089 3.02369 14.0674 2.99477C14.0277 2.96887 13.9843 2.94892 13.9424 2.92682C13.8982 2.90377 13.8549 2.87867 13.8089 2.86027C13.7644 2.84237 13.717 2.83097 13.6706 2.81732C13.6241 2.80367 13.5779 2.78732 13.5302 2.77847C13.4711 2.76898 13.4115 2.76297 13.3517 2.76047C13.3138 2.75782 13.2762 2.75117 13.2381 2.75147C13.1673 2.75354 13.0968 2.76064 13.0271 2.77272C12.999 2.77692 12.9706 2.77657 12.9427 2.78237C12.8483 2.8112 12.7542 2.84087 12.6604 2.87137C12.6347 2.88237 12.6121 2.89872 12.5872 2.91112C12.3332 3.0296 12.1191 3.21909 11.9706 3.45667C11.822 3.69426 11.7455 3.96977 11.7502 4.24992V7.13092L14.1102 5.47877C14.342 5.32324 14.5237 5.10387 14.6333 4.84721C14.743 4.59055 14.7761 4.30771 14.7283 4.03272Z"
      fill="#FFBA00"
    />
    <path
      d="M2.75 2.75C3.14782 2.75 3.52936 2.90804 3.81066 3.18934C4.09196 3.47064 4.25 3.85218 4.25 4.25V13.25H2.25C1.98478 13.25 1.73043 13.1446 1.54289 12.9571C1.35536 12.7696 1.25 12.5152 1.25 12.25V4.25C1.25 3.85218 1.40804 3.47064 1.68934 3.18934C1.97064 2.90804 2.35218 2.75 2.75 2.75Z"
      fill="#4285F4"
    />
    <path
      d="M1.27174 4.03283C1.27614 4.00283 1.27579 3.97218 1.28204 3.94223C1.29164 3.89633 1.30949 3.85393 1.32319 3.80963C1.33647 3.75894 1.35246 3.70901 1.37109 3.66003C1.38109 3.63628 1.39649 3.61543 1.40789 3.59233C1.43898 3.52565 1.47498 3.46138 1.51559 3.40003C1.53559 3.37098 1.56059 3.34623 1.58269 3.31873C1.6206 3.26875 1.66158 3.22118 1.70539 3.17628C1.73864 3.14393 1.77654 3.11688 1.81274 3.08773C1.85115 3.05478 1.8912 3.02379 1.93274 2.99488C1.97239 2.96898 2.01579 2.94903 2.05774 2.92693C2.10109 2.90241 2.14565 2.88008 2.19124 2.86003C2.23574 2.84213 2.28314 2.83073 2.32954 2.81708C2.37594 2.80343 2.42219 2.78708 2.46989 2.77823C2.52898 2.76874 2.58859 2.76273 2.64839 2.76023C2.68639 2.75758 2.72394 2.75093 2.76204 2.75123C2.83277 2.75329 2.90327 2.76039 2.97299 2.77248C3.00114 2.77668 3.02949 2.77633 3.05749 2.78213C3.10589 2.79446 3.15362 2.80928 3.20049 2.82653C3.24762 2.83906 3.2941 2.85394 3.33974 2.87113C3.36544 2.88213 3.38799 2.89848 3.41299 2.91088C3.4778 2.94133 3.54033 2.97642 3.60009 3.01588C3.80041 3.15343 3.96423 3.33766 4.07743 3.55268C4.19062 3.7677 4.24978 4.00704 4.24979 4.25003V7.13103L1.88979 5.47898C1.65808 5.3234 1.4764 5.10403 1.36671 4.84738C1.25703 4.59074 1.22404 4.30782 1.27174 4.03283Z"
      fill="#C52528"
    />
  </svg>
);
