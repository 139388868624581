import React from 'react';
import { withTheme } from 'styled-components';

import Select from '@xcritical/select';
import Button from '@xcritical/button';

import { PaginationDropdownIndicator } from '@ams-package/select';

import { IPagination, IOption } from './interfaces';
import {
  ControlsWrapper,
  PageLabel,
  PageSizeLabel,
  PageSizeWrapper,
  PageWrapper,
  PaginationWrapper,
} from './styles';
import { Totals } from './Totals';
import {
  getVisiblePagesArray,
  getPaginationButtonTheme,
  getPaginationSelectTheme,
  mapOption,
} from './utils';
import { ArrowLeft, ChevronRight } from './Icons';

export const Pagination: React.FC<IPagination> = withTheme(
  ({
    currentPage = 1,
    showSizeChanger = true,
    showTotals = false,
    showControls = true,
    disabled = false,
    pageSize = 10,
    pageSizeOptions = [25, 50, 100],
    pageSizeProps,
    total,
    onChangePage,
    onChangePageSize,
    theme,
    className,
    classNamePrefix,
  }) => {
    const toPrevious = (): void => {
      onChangePage(currentPage - 1);
    };

    const toNext = (): void => {
      onChangePage(currentPage + 1);
    };

    const changePageSize = (pageSizeOption: IOption | null): void => {
      const newTotalPages = Math.ceil(total / (pageSizeOption?.value ?? 1));
      const newCurrentPage =
        currentPage > newTotalPages ? newTotalPages : currentPage;

      if (onChangePageSize) {
        onChangePageSize(newCurrentPage, pageSizeOption?.value as number);
      }
    };

    const changePage = (pageOption: IOption | null): void => {
      if (onChangePage && pageOption) {
        onChangePage(pageOption.value);
      }
    };

    const totalPages = Math.ceil(total / pageSize);

    const visiblePagesArray = getVisiblePagesArray(totalPages);

    const mappedPageSizeOption = showSizeChanger ? mapOption(pageSize!) : [];
    const mappedPageSizeOptions = showSizeChanger
      ? pageSizeOptions!.map(mapOption)
      : [];
    const mappedPageOptions = visiblePagesArray.map(mapOption);
    const mappedPageOption = mapOption(currentPage);
    const paginationButtonTheme = getPaginationButtonTheme(theme);
    const paginationSelectTheme = getPaginationSelectTheme(theme);

    return (
      <PaginationWrapper className={className}>
        {showSizeChanger && (
          <PageSizeWrapper>
            <Select
              className={
                classNamePrefix ? `${classNamePrefix}__select` : 'select'
              }
              classNamePrefix={
                classNamePrefix ? `${classNamePrefix}-select` : null
              }
              theme={paginationSelectTheme}
              appearance="paginationSelect"
              hideSelectedOptions={false}
              options={mappedPageSizeOptions!}
              value={mappedPageSizeOption!}
              isDisabled={disabled!}
              components={{ DropdownIndicator: PaginationDropdownIndicator }}
              onChange={changePageSize}
              {...pageSizeProps}
            />
            <PageSizeLabel>Items per page</PageSizeLabel>
          </PageSizeWrapper>
        )}
        {showTotals && (
          <Totals
            className={
              classNamePrefix ? `${classNamePrefix}__totals` : 'totals'
            }
            currentPage={currentPage}
            pageSize={pageSize!}
            total={total}
          />
        )}

        {showSizeChanger && (
          <PageWrapper>
            <Select
              className={
                classNamePrefix ? `${classNamePrefix}__select` : 'select'
              }
              classNamePrefix={
                classNamePrefix ? `${classNamePrefix}-select` : null
              }
              theme={paginationSelectTheme}
              appearance="paginationSelect"
              hideSelectedOptions={false}
              components={{ DropdownIndicator: PaginationDropdownIndicator }}
              options={mappedPageOptions!}
              value={mappedPageOption!}
              isDisabled={disabled!}
              onChange={changePage}
              {...pageSizeProps}
            />
            <PageLabel>of {totalPages} pages</PageLabel>
          </PageWrapper>
        )}

        {showControls && (
          <ControlsWrapper>
            <Button
              className={
                classNamePrefix && `${classNamePrefix}__button-previous`
              }
              classNamePrefix={classNamePrefix && `${classNamePrefix}-button`}
              theme={paginationButtonTheme}
              appearance="paginationButton"
              disabled={disabled || currentPage === 1}
              onClick={toPrevious}>
              {currentPage > 1 ? <ArrowLeft /> : null}
            </Button>
            <Button
              className={className && `${className}__button-next`}
              classNamePrefix={classNamePrefix && `${classNamePrefix}-button`}
              theme={paginationButtonTheme}
              appearance="paginationButton"
              disabled={disabled || currentPage === totalPages}
              onClick={toNext}>
              {currentPage < totalPages ? <ChevronRight /> : null}
            </Button>
          </ControlsWrapper>
        )}
      </PaginationWrapper>
    );
  }
);
