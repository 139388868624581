import { GenericDictionaryWithApiMethodsType } from '@crm-framework/dictionaries';

import { IDictionaries } from './models';

export type DictionariesNameType = keyof IDictionaries;

export type LoadDictionaryMethods =
  GenericDictionaryWithApiMethodsType<DictionariesNameType>;

export type DictionaryMapperKeyNameType<T> = keyof Omit<T, 'name'>;
export type DictionaryMapperKeyIdType<T> = keyof Omit<T, 'id'>;
export type LoadDictionaryOptionsType<T, K = T> = {
  keyForId?: DictionaryMapperKeyIdType<T>;
  keyForName?: DictionaryMapperKeyNameType<T>;
  customMapper?: (dictionary: K[]) => T[];
  order?: string | ((dictionary: T[]) => T[]);
};

export type LoadObjectDictionaryType<T, K = T> = {
  customMapper?: (dictionary: T) => K;
};

export interface IStoreWithDictionary<T extends IDictionaries = IDictionaries> {
  dictionaries: T;
}

export enum DictionaryTypes {
  DeviceType = 'DeviceType',
  DevicePlatform = 'DevicePlatform',
  Country = 'Country',
  PayoutStatus = 'PayoutStatus',
  PayoutType = 'PayoutType',
}

export type AmsDictionaryType = {
  type: DictionaryTypes;
  payload: {
    [key: string]: string;
  };
};
