import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { IModalProps, Modal, xcriticalModalClose } from '@xcritical/modal';

import { useModal } from '../hooks';

export type BaseModalType = Omit<IModalProps, 'isOpen' | 'onModalCancel'> & {
  onModalCancel?: () => void;
};

export const BaseModal: React.FC<BaseModalType> = ({
  name,
  children,
  onModalCancel = null,
  ...params
}) => {
  const dispatch = useDispatch();
  const { isOpen } = useModal(name);

  const handlerCancel = useCallback(() => {
    dispatch(xcriticalModalClose(name));

    onModalCancel?.();
  }, [name]);

  return (
    <Modal
      name={name}
      onModalCancel={handlerCancel}
      onCancel={handlerCancel}
      isOpen={isOpen}
      {...params}>
      {children}
    </Modal>
  );
};
